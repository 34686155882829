export const REGISTER_EMPLOYEE = 'REGISTER_EMPLOYEE';
export const SET_EMPLOYEE = 'SET_EMPLOYEE';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const GET_JOB_PROFILE_RECOMMENDS = 'GET_JOB_PROFILE_RECOMMENDS';
export const GET_CERTIFICATE_OF_PROFESSIONALISM =
  'GET_CERTIFICATE_OF_PROFESSIONALISM';
export const SET_FAVORITE_COURSES = 'GET_FAVORITE_COURSES';
export const SET_TOTAL_FAVORITE_COURSES = 'SET_TOTAL_FAVORITE_COURSES';
export const GET_MOST_DEMAND_COURSES = 'GET_MOST_DEMAND_COURSES';
export const GET_MOST_DEMAND_JOB_PROFILE = 'GET_MOST_DEMAND_JOB_PROFILE';
export const SET_COURSES_RECOMMENDS = 'SET_COURSES_RECOMMENDS';
export const SET_TOTAL_RECOMMENDED_COURSES = 'SET_TOTAL_RECOMMENDED_COURSES';
export const SET_COURSE_DETAIL = 'SET_COURSE_DETAIL';
export const GET_JOB_PROFILE_DETAIL = 'GET_JOB_PROFILE_DETAIL';
export const GET_MATCH_HARD_SKILLS = 'GET_MATCH_HARD_SKILLS';
export const GET_SOFT_SKILLS_REPORT = 'GET_SOFT_SKILLS_REPORT';
export const SET_EMPLOYEE_HARD_SKILLS_REPORT = 'SET_EMPLOYEE_HARD_SKILLS_REPORT';
