// Mutation Types
import * as types from './types';

// Services
import ValidatorService from '@/services/ValidatorService.js';

export default {
  async getHardSkills ({ commit }, payload) {
    try {
      const response = await ValidatorService.getHardSkills(payload);
      commit(types.GET_HARD_SKILLS, response.data);
    } catch (e) {
      commit(types.GET_HARD_SKILLS, []);
    }
  },
  async getValidatedSkills ({ commit }, payload) {
    const response = await ValidatorService.getValidatedSkills(payload);
    commit(types.GET_SKILLS_DB, response.data);
  },
  async getProposedSkills ({ commit }, payload) {
    const response = await ValidatorService.getProposedSkills(payload);
    commit(types.GET_SKILLS_DB, response.data);
  },
  async getSkills ({ commit }, payload) {
    const response = await ValidatorService.getSkills(payload);
    commit(types.GET_SKILLS, response.data);
  },
  async getSkillData ({ commit }, skillId) {
    return await ValidatorService.getSkillsData(skillId);
  },
  async cleanSkills ({ commit }) {
    commit(types.CLEAN_SKILLS);
  },
  async getFields ({ commit }, payload) {
    const response = await ValidatorService.getFields(payload);
    commit(types.GET_FIELDS, response.data);
  },
  async clearWorkingFields ({ commit }) {
    commit(types.CLEAR_WORK_FIELDS);
  },
  async getProfileName ({ commit }, payload) {
    const response = await ValidatorService.getProfileName(payload);
    commit(types.GET_PROFILE_NAME, response.data);
  },
  async clearProfileName ({ commit }) {
    commit(types.CLEAR_PROFILE_NAME);
  },
  // Profiles functions
  async fetchProposedProfiles ({ commit }, payload) {
    try {
      const response = await ValidatorService.fetchProposedProfiles(payload);
      commit(types.SET_UNVALIDATED_PROFILES, response.data.results);
      commit(types.SET_TOTAL_UNVALIDATED_PROFILES, response.data.count);
    } catch (e) {
      commit(types.SET_UNVALIDATED_PROFILES, []);
      commit(types.SET_TOTAL_UNVALIDATED_PROFILES, 0);
    }
  },
  async getValidatedProfiles ({ commit }, payload) {
    try {
      const response = await ValidatorService.getValidatedProfiles(payload);
      commit(types.SET_VALIDATED_PROFILES, response.data.results);
      commit(types.SET_TOTAL_VALIDATED_PROFILES, response.data.count);
    } catch (e) {
      commit(types.SET_VALIDATED_PROFILES, []);
      commit(types.SET_TOTAL_VALIDATED_PROFILES, 0);
    }
  },
  async getDeletedProfiles ({ commit }, payload) {
    const response = await ValidatorService.getDeletedProfiles(payload);
    commit(types.GET_DELETED_POFILES, response.data);
  },
  async getValidatedMostDemandProfiles ({ commit }, payload) {
    try {
      const response = await ValidatorService.getValidatedMostDemandProfiles(payload);
      commit(types.SET_MOST_DEMAND_PROFILES, response.data);
      commit(types.SET_TOTAL_MOST_DEMAND_PROFILES, response.data);
    } catch (e) {
      commit(types.SET_MOST_DEMAND_PROFILES, []);
      commit(types.SET_TOTAL_MOST_DEMAND_PROFILES, 0);
    }
  },
  async validateProfile ({ commit }, payload) {
    const response = await ValidatorService.validateProfile(payload);
    console.log(response.data);
    commit(types.VALIDATE_PROFILE, payload.id);
  },
  async restoreProfile ({ commit }, profileId) {
    await ValidatorService.restoreProfile(profileId);
    commit(types.RESTORE_PROFILE, profileId);
  },
  async deleteProfile ({ commit }, profileId) {
    await ValidatorService.deleteProfile(profileId);
    commit(types.DELETE_PROFILE, profileId);
  },
  async clearProfiles ({ commit }) {
    commit(types.CLEAR_PROFILES);
  },
  async getNewSkills ({ commit }, payload) {
    const response = await ValidatorService.getNewSkills(payload);
    commit(types.GET_NEW_SKILLS, response.data);
  },
  async clearNewSkills ({ commit }) {
    commit(types.CLEAR_NEW_SKILLS);
  },
  async validateNewSkill ({ commit }, payload) {
    await ValidatorService.validateNewSkill(payload);
    commit(types.VALIDATE_NEW_SKILL, payload);
  },
  async removeNewSkill ({ commit }, payload) {
    await ValidatorService.removeNewSkill(payload);
    commit(types.REMOVE_NEW_SKILL, payload);
  },
  // Stop Words functions
  async getStopWords ({ commit }, payload) {
    try {
      const response = await ValidatorService.getStopWords(payload);
      commit(types.GET_STOP_WORDS, response.data);
    } catch (e) {
      commit(types.GET_STOP_WORDS, []);
    }
  },
  async loadStopWords ({ commit }, payload) {
    try {
      const response = await ValidatorService.getStopWords(payload);
      commit(types.SET_STOP_WORDS, response.data);
      commit(types.SET_TOTAL_WORDS, response.data);
    } catch (e) {
      commit(types.SET_STOP_WORDS, []);
      commit(types.SET_TOTAL_WORDS, 0);
    }
  },
  async loadStopWordsByName ({ commit }, payload) {
    try {
      const response = await ValidatorService.getStopWordsByName(payload);
      commit(types.SET_STOP_WORDS, response.data);
      commit(types.SET_TOTAL_WORDS, response.data);
    } catch (e) {
      commit(types.SET_STOP_WORDS, []);
      commit(types.SET_TOTAL_WORDS, 0);
    }
  },
  async loadSkills ({ commit }, payload) {
    try {
      const response = await ValidatorService.fetchSkills(payload);
      commit(types.SET_SKILLS, response.data.results);
      commit(types.SET_TOTAL_SKILLS, response.data.count);
    } catch (e) {
      commit(types.SET_SKILLS, []);
      commit(types.SET_TOTAL_SKILLS, 0);
    }
  },
  async loadValidatedSkills ({ commit }, payload) {
    try {
      const response = await ValidatorService.getValidatedSkills(payload);
      commit(types.SET_VALIDATED_SKILLS, response.data);
      commit(types.SET_TOTAL_VALIDATED_SKILLS, response.data);
    } catch (e) {
      commit(types.SET_VALIDATED_SKILLS, []);
      commit(types.SET_TOTAL_VALIDATED_SKILLS, 0);
    }
  },
  async loadValidatedSkillsByName ({ commit }, payload) {
    try {
      const response = await ValidatorService.getValidatedSkillsByName(payload);
      commit(types.SET_VALIDATED_SKILLS, response.data);
      commit(types.SET_TOTAL_VALIDATED_SKILLS, response.data);
    } catch (e) {
      commit(types.SET_VALIDATED_SKILLS, []);
      commit(types.SET_TOTAL_VALIDATED_SKILLS, 0);
    }
  },
  async UpdateValidatedSkillData ({ commit }, payload) {
    try {
      await ValidatorService.UpdateValidatedSkillData(payload);
    } catch (e) {}
  },
  async loadProfileSkills ({ commit }, payload) {
    try {
      const response = await ValidatorService.getProfileSkills(payload);
      console.log(response);
    } catch (e) {
    }
  },
  async getSectors ({ commit }, payload) {
    const response = await ValidatorService.getSectors(payload);
    commit(types.GET_SECTORS, response.data);
  },
  async getRankingProfiles ({ commit }, payload) {
    const response = await ValidatorService.getRankingProfiles(payload);
    commit(types.GET_RANKING, response.data);
  },
  async registerStopWord ({ commit }, payload) {
    await ValidatorService.createStopWord(payload);
  },
  async removeStopWord ({ commit }, payload) {
    try {
      await ValidatorService.removeStopWord(payload);
      commit(types.REMOVE_STOP_WORD, payload);
    } catch (e) {
      commit(types.REMOVE_STOP_WORD, []);
    }
  },
  async clearStopWord ({ commit }) {
    commit(types.CLEAR_STOP_WORD);
  },
  async deleteProposedProfile ({ commit }, profileId) {
    await ValidatorService.deleteProposedProfile(profileId);
    commit(types.DELETE_UNVALIDATED_ROFILE, profileId);
  },
  setValidatedSkillsFilters ({ commit }, payload) {
    commit(types.SET_VALIDATED_SKILLS_FILTERS, payload);
  },
  async deleteValidatedSkill ({ commit }, payload) {
    return await ValidatorService.deleteValidatedSkill(payload);
  },
  setJobProfileSkills ({ commit }, payload) {
    commit(types.SET_JOB_PROFILES_SKILLS, payload);
  },
  setJobProfileKnowledge ({ commit }, payload) {
    commit(types.SET_JOB_PROFILES_KNOWLEDGE, payload);
  },
  async fetchValidatorProposedProfiles ({ commit }, payload) {
    try {
      const response = await ValidatorService.fetchValidatorProposedProfiles(payload);
      commit(types.SET_PROPOSED_JOB_PROFILES, response.data.results);
      commit(types.SET_TOTAL_PROPOSED_JOB_PROFILES, response.data.count);
    } catch (e) {
      commit(types.SET_PROPOSED_JOB_PROFILES, []);
    }
  },
  async fetchProposedJobProfile ({ commit }, payload) {
    try {
      const response = await ValidatorService.fetchProposedJobProfile(payload);
      commit(types.SET_PROPOSED_JOB_PROFILE, response.data);
    } catch (e) {
      commit(types.SET_PROPOSED_JOB_PROFILE, {});
    }
  },
  async fetchNotValidatedJobProfiles ({ commit }, payload) {
    try {
      const response = await ValidatorService.fetchNotValidatedJobProfiles(payload);
      commit(types.SET_NOT_VALIDATED_JOB_PROFILES, response.data.results);
      commit(types.SET_TOTAL_NOT_VALIDATED_JOB_PROFILES, response.data.count);
    } catch (e) {
      commit(types.SET_NOT_VALIDATED_JOB_PROFILES, []);
    }
  },
  async setTotalJobProfilesSkills ({ commit }, payload) {
    commit(types.SET_TOTAL_JOB_PROFILES_SKILLS, payload);
  },
  async setTotalJobProfilesKnowledge ({ commit }, payload) {
    commit(types.SET_TOTAL_JOB_PROFILES_KNOWLEDGE, payload);
  },
  async validateProposedProfile ({ commit }, payload) {
    await ValidatorService.validateProposedProfile(payload);
  },
  async postJobProfile ({ commit }, payload) {
    await ValidatorService.postJobProfile(payload);
  }
};
