export default {
  individualProducts: (state) => {
    return state.individualProducts;
  },
  educationCenterProducts: (state) => {
    return state.productsEducationCenter;
  },
  getManagerProducts: (state) => {
    return state.productsManager;
  },
  checkoutStatus: (state) => {
    return state.checkoutStatus;
  },
  checkoutUrl: (state) => {
    return state.checkoutUrl;
  }
};
