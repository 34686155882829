import config from '@/config/config.js';
import ApiService from '@/services';

export const AuthService = {
  login (payload) {
    return ApiService.post(`${config.AUTH_ENDPOINT}login/`, {
      email: payload.email,
      password: payload.password,
      username: payload.email
    });
  },
  loginWithGoogle (payload) {
    return ApiService.post(`${config.AUTH_ENDPOINT}login/google/`, {
      access_token: payload.accessToken,
      id_token: payload.idToken
    });
  },
  loginWithLinkedin (payload) {
    return ApiService.post(`${config.AUTH_ENDPOINT}login/linkedin/`, {
      code: payload
    });
  },
  loginWithOutlook (payload) {
    return ApiService.post(`${config.AUTH_ENDPOINT}login/microsoft/`, {
      code: payload
    });
  },
  logout () {
    return ApiService.post(`${config.AUTH_ENDPOINT}logout/`);
  },
  registration (payload) {
    return ApiService.post(`${config.AUTH_ENDPOINT}registration/`, {
      username: payload.email,
      email: payload.email,
      password1: payload.password1,
      password2: payload.password2,
      intent_type_register: payload.typeRegister,
      intent_type_subscription: payload.typeSubscription
    });
  },
  resendVerificationEmail (payload) {
    return ApiService.post(`${config.AUTH_ENDPOINT}registration/resend-email`, {
      email: payload.email
    });
  },
  passwordChange (payload) {
    return ApiService.post(`${config.AUTH_ENDPOINT}password/change/`, {
      old_password: payload.old_password,
      new_password1: payload.password1,
      new_password2: payload.password2
    });
  },
  passwordReset (payload) {
    return ApiService.post(`${config.AUTH_ENDPOINT}password/reset/`, {
      email: payload
    });
  },
  passwordResetConfirm (payload) {
    return ApiService.post(`${config.AUTH_ENDPOINT}password/reset/confirm/`, {
      new_password1: payload.password1,
      new_password2: payload.password2,
      uid: payload.uid,
      token: payload.token
    });
  },
  me () {
    return ApiService.get(`${config.CORE_ENDPOINT}users/me`);
  },
  activationAccount (payload) {
    return ApiService.post(
      `${config.AUTH_ENDPOINT}registration/verify-email-login`,
      {
        key: payload
      }
    );
  },
  activationResetPassword (payload) {
    return ApiService.post(
      `${config.AUTH_ENDPOINT}password-change/activation`,
      {
        key: payload
      }
    );
  },
  fetchUserInfo () {
    return ApiService.get(`${config.AUTH_ENDPOINT}user`);
  },
  putUserInfo (payload) {
    return ApiService.put(
      `${config.AUTH_ENDPOINT}user`,
      {
        username: payload.userName,
        intent_type_subscription: payload.typeSubscription
      }
    );
  }
};

export default AuthService;
