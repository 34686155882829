export default {
  employee: (state) => {
    return state.employee;
  },
  certificates: (state) => {
    return state.certificates;
  },
  getFavoriteCourses: (state) => {
    return state.favoriteCourses;
  },
  getTotalFavoritesCourses: (state) => {
    return state.totalFavoritesCourses;
  },
  jobProfilesRecommends: (state) => {
    return state.jobProfilesRecommends;
  },
  coursesRecommends: (state) => {
    return state.coursesRecommends;
  },
  getTotalRecommendedCourses: (state) => {
    return state.totalRecommendedCourses;
  },
  courseDetail: (state) => {
    return state.courseDetail;
  },
  jobProfileDetail: (state) => {
    return state.jobProfileDetail;
  },
  mostDemandCourses: (state) => {
    return state.mostDemandCourses;
  },
  mostDemandJobProfiles: (state) => {
    return state.mostDemandJobProfiles;
  },
  matchHardSkillsWithJobProfile: (state) => {
    return state.matchHardSkillsWithJobProfile;
  },
  softSkillReport: (state) => {
    return state.softSkillReport;
  },
  getHardSkillReport: (state) => {
    return state.hardSkillReport;
  }
};
