// Mutation Types
import * as types from './types';

// Services
import AuthService from '@/services/AuthService.js';

export default {
  async login ({ commit }, payload) {
    const response = await AuthService.login(payload);
    commit(types.LOGIN, response.data);
    return response;
  },
  async loginWithGoogle ({ commit }, payload) {
    const response = await AuthService.loginWithGoogle(payload);
    commit(types.REGISTER, response.data);
    return response;
  },
  async loginWithLinkedin ({ commit }, payload) {
    try {
      const response = await AuthService.loginWithLinkedin(payload);
      commit(types.REGISTER, response.data);
      return response;
    } catch (e) {
    }
  },
  async loginWithOutlook ({ commit }, payload) {
    try {
      const response = await AuthService.loginWithOutlook(payload);
      commit(types.REGISTER, response.data);
      return response;
    } catch (e) {
    }
  },
  async logout ({ commit }) {
    try {
      await AuthService.logout();
      commit(types.LOGOUT);
    } catch (e) {
    }
  },
  async me ({ commit }) {
    const response = await AuthService.me();
    commit(types.ME, response.data);
  },
  async resendEmail (_, payload) {
    await AuthService.resendVerificationEmail(payload);
  },
  async saveActivationAccount ({ commit }, payload) {
    const response = await AuthService.activationAccount(payload);
    commit(types.ACTIVATION, response.data);
  },
  async fetchUserInfo ({ commit }, payload) {
    const response = await AuthService.fetchUserInfo(payload);
    commit(types.USER_INFO, response.data);
  },
  async updateUserInfo ({ commit }, payload) {
    const response = await AuthService.putUserInfo(payload);
    commit(types.USER_INFO, response.data);
  },
  async saveActivationResetPassword ({ commit }, payload) {
    const response = await AuthService.activationResetPassword(payload);
    commit(types.ACTIVATION, response.data);
  },
  expiredToken ({ commit }) {
    commit(types.LOGOUT, []);
  }
};
