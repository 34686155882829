export default {
  getIndividual: (state) => {
    return state.individual;
  },
  getProfessionalCertificates: (state) => {
    return state.certificates;
  },
  getFavoriteCourses: (state) => {
    return state.favoriteCourses;
  },
  getTotalFavoritesCourses: (state) => {
    return state.totalFavoritesCourses;
  },
  getRecommendedJobProfiles: (state) => {
    return state.recommendedJobProfiles;
  },
  getRecommendedCourses: (state) => {
    return state.recommendedCourses;
  },
  getTotalRecommendedJobProfiles: (state) => {
    return state.totalRecommendedJobProfiles;
  },
  getTotalRecommendedCourses: (state) => {
    return state.totalRecommendedCourses;
  },
  getCourseDetail: (state) => {
    return state.courseDetail;
  },
  getJobProfileDetail: (state) => {
    return state.jobProfileDetail;
  },
  getMostDemandCourses: (state) => {
    return state.mostDemandCourses;
  },
  getMostDemandJobProfiles: (state) => {
    return state.mostDemandJobProfiles;
  },
  getMatchHardSkillsWithJobProfile: (state) => {
    return state.matchHardSkillsWithJobProfile;
  },
  getSoftSkillReport: (state) => {
    return state.softSkillReport;
  },
  getUserSubscribedPlan: (state) => {
    return state.individual.user.subscription.items[0];
  },
  getHardSkills: (state) => {
    return state.hardSkills;
  },
  getSoftSkills: (state) => {
    return state.softSkills;
  },
  getJobProfiles: (state) => {
    return state.jobProfiles;
  },
  getWorkFields: (state) => {
    return state.workFields;
  }
};
