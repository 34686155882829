export const REGISTER_INDIVIDUAL = 'REGISTER_INDIVIDUAL';
export const SET_INDIVIDUAL_BY_ID = 'SET_INDIVIDUAL_BY_ID';
export const UPDATE_INDIVIDUAL = 'UPDATE_INDIVIDUAL';
export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const SET_RECOMMENDED_JOB_PROFILES = 'SET_RECOMMENDED_JOB_PROFILES';

export const SET_TOTAL_RECOMMENDED_JOB_PROFILES =
  'SET_TOTAL_RECOMMENDED_JOB_PROFILES';
export const SET_PROFESSIONAL_CERTIFICATE =
  'SET_PROFESSIONAL_CERTIFICATE';
export const SET_FAVORITE_COURSES = 'SET_FAVORITE_COURSES';
export const SET_TOTAL_FAVORITE_COURSES = 'SET_TOTAL_FAVORITE_COURSES';
export const SET_MOST_DEMAND_COURSES = 'SET_MOST_DEMAND_COURSES';
export const SET_MOST_DEMAND_JOB_PROFILE = 'SET_MOST_DEMAND_JOB_PROFILE';
export const SET_RECOMMENDED_COURSES = 'SET_RECOMMENDED_COURSES';
export const SET_TOTAL_RECOMMENDED_COURSES = 'SET_TOTAL_RECOMMENDED_COURSES';
export const SET_COURSE_DETAIL = 'SET_COURSE_DETAIL';
export const SET_JOB_PROFILE_DETAIL = 'SET_JOB_PROFILE_DETAIL';
export const SET_MATCH_HARD_SKILLS = 'SET_MATCH_HARD_SKILLS';
export const SET_SOFT_SKILLS_REPORT = 'SET_SOFT_SKILLS_REPORT';
export const SET_HARD_SKILLS = 'SET_HARD_SKILLS';
export const SET_SOFT_SKILLS = 'SET_SOFT_SKILLS';
export const SET_JOB_PROFILES = 'SET_JOB_PROFILES';
export const SET_WORK_FIELDS = 'SET_WORK_FIELDS';
