export default {
  coursesEducationCenter: [],
  totalCourseEducationCenter: 0,
  courseById: {},
  educationCenter: {},
  competencesDemandByAreaOfKnowledge: {},
  marketHomePageSkills: [],
  notificationsEducationCenter: [],
  marketJobProfiles: [],
  marketSkills: [],
  dataDashboardEducationCenter: {},
  coursesPerPerson: [],
  managerCenter: {},
  leadsCourses: [],
  totalLeadsCourses: 0,
  leadsFavoritesCourses: [],
  totalFavoritesCourses: 0,
  leadsByJobProfile: [],
  totalLeadsByJobProfile: 0,
  leadsByCurrentJobProfile: [],
  totalLeadsByCurrentJobProfile: 0,
  headquarters: [],
  totalHeadquarters: 0
};
