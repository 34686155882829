export default {
  softSkills: (state) => {
    return state.softSkills;
  },
  hardSkills: (state) => {
    return state.hardSkills;
  },
  workfields: (state) => {
    return state.workfields;
  },
  workfieldsComputed: (state) => {
    return state.workfieldsComputed;
  },
  levelOfStudy: (state) => {
    return state.levelOfStudy;
  },
  getJobProfiles: (state) => {
    return state.jobProfiles;
  },
  yearsExperience: (state) => {
    return state.yearsExperience;
  },
  getTotalSkills: (state) => {
    return state.totalSkills;
  }
};
