import config from '@/config/config.js';
import ApiService from '@/services';
import i18n from '@/i18n';

export const ManagerService = {
  fetchManagerData (id) {
    return ApiService.get(`${config.CORE_ENDPOINT}managers/${id}`);
  },
  fetchCompanyData () {
    return ApiService.get(`${config.CORE_ENDPOINT}companies`);
  },
  fetchAreasKnowledge (payload) {
    return ApiService.query(`${config.CORE_ENDPOINT}company-areas-knowledge`, {
      name: payload.name ?? null,
      limit: payload.limit ?? 10,
      offset: payload.offset ?? 0
    });
  },
  fetchEmployees (payload) {
    return ApiService.query(`${config.CORE_ENDPOINT}employees`,
      {
        status: payload.status ?? null,
        branch_office: payload.branch_office ?? null,
        limit: payload.limit ?? 10,
        offset: payload.offset * 10 ?? 0
      });
  },
  fetchFilterEmployees (payload) {
    return ApiService.query(
      `${config.CORE_ENDPOINT}employees`,
      {
        name: payload.filter.name || null,
        countries: payload.filter?.country?.length ? payload.filter.country.join() : null,
        states: payload.filter?.state?.length ? payload.filter.state.join() : null,
        branch_office: payload.filter?.branch_office?.length ? payload.filter.branch_office.join() : null,
        areas: payload.filter?.area_knowledge?.length ? payload.filter.area_knowledge.join() : null,
        profile_incomplete: payload.filter?.profile_incomplete ?? null,
        job_profile: payload.job_profile ?? null,
        status: payload.filter?.status ?? null,
        limit: payload.limit ?? 10,
        offset: payload.offset ?? 0
      }
    );
  },
  fetchEmployee (employeeId) {
    return ApiService.get(`${config.CORE_ENDPOINT}employees/${employeeId}`);
  },
  fetchFilterJobPositions (payload) {
    return ApiService.query(
      `${config.CORE_ENDPOINT}job-profile-company`, {
        name: payload.filter.name || null,
        countries: payload.filter.country.length ? payload.filter.country.join() : null,
        states: payload.filter.state.length ? payload.filter.state.join() : null,
        branch_office: payload.filter.branch_office.length ? payload.filter.branch_office.join() : null,
        areas: payload.filter.area_knowledge.length ? payload.filter.area_knowledge.join() : null,
        limit: payload.limit ?? 10,
        offset: payload.offset ?? 0
      }
    );
  },
  fetchJobProfile (jobId) {
    return ApiService.get(`${config.CORE_ENDPOINT}job-profile-company/${jobId}`);
  },
  fetchTeams (payload) {
    return ApiService.query('/team/teams', {
      name: payload.name ?? null,
      localities: payload.locality ?? null,
      state: payload.state ?? null,
      countries: payload.countries ?? null,
      limit: payload.limit ?? 10,
      offset: payload.offset ?? 0
    });
  },
  fetchFilterTeams (payload) {
    return ApiService.query('/team/teams', {
      name: payload.filter.name || null,
      countries: payload.filter.country.length ? payload.filter.country.join() : null,
      states: payload.filter.state.length ? payload.filter.state.join() : null,
      branch_office: payload.filter.branch_office.length ? payload.filter.branch_office.join() : null,
      areas: payload.filter.area_knowledge.length ? payload.filter.area_knowledge.join() : null,
      limit: payload.limit,
      offset: payload.offset
    });
  },
  getTeam (teamId) {
    return ApiService.get(`/team/teams/${teamId}`);
  },
  fetchBranchOffices (payload) {
    // return ApiService.get(`${config.CORE_ENDPOINT}branch-offices/?format=json&limit=10&page=${page}&`);
    return ApiService.query(`${config.CORE_ENDPOINT}branch-offices`, {
      company: payload.company ?? null,
      manager_id: payload.managerId ?? null,
      name: payload.name ?? null,
      locality: payload.locality ?? null,
      state: payload.state ?? null,
      country: payload.country ?? null,
      limit: payload.limit ?? -1,
      offset: payload.offset * 10 ?? 0
    });
  },
  fetchJobProfiles (payload) {
    return ApiService.query(`${config.CORE_ENDPOINT}job-profile-company`, {
      name: payload.name ?? null,
      status: payload.status,
      limit: payload.limit ?? 10,
      offset: payload.offset ?? 0
    });
  },
  fetchJobPositionData (jobId) {
    return ApiService.query(`${config.CORE_ENDPOINT}employees`,
      {
        job_profile_id: jobId
      });
  },
  fetchCountries (payload) {
    return ApiService.query(`${config.CORE_ENDPOINT}company-countries`, {
      name: payload.name ?? null,
      limit: payload.offset ?? 10,
      offset: payload.limit ?? 0
    });
  },
  fetchStates (payload) {
    return ApiService.query(`${config.CORE_ENDPOINT}company-states`, {
      name: payload.name ?? null,
      limit: payload.limit ?? 10,
      offset: payload.offset * 10 ?? 0
    });
  },
  fetchLocalities (payload) {
    return ApiService.query(`${config.CORE_ENDPOINT}company-localities`, {
      name: payload.name ?? null,
      limit: payload.limit ?? 10,
      offset: payload.offset * 10 ?? 0
    });
  },
  fetchCourseDetail (payload) {
    return ApiService.query(
      `${config.CORE_ENDPOINT}job-profile-company/${payload.employeeJobProfileId}/${payload.employeeId}/recommended-courses/${payload.courseId}`
    );
  },
  fetchTeamCourseDetail (payload) {
    return ApiService.query(
      `${config.DATA_ENDPOINT}courses-recommendations-team/${payload.teamId}/detail-course/${payload.courseId}`
    );
  },
  registerEmployee (payload) {
    return ApiService.post(`${config.CORE_ENDPOINT}employees`, payload);
  },
  registerEmployeesByCSV (payload) {
    return ApiService.post(`${config.CORE_ENDPOINT}employees-csv`, payload);
  },
  registerJobProfile (payload) {
    return ApiService.post(`${config.CORE_ENDPOINT}job-profile-company`, payload);
  },
  registerAreasKnowledge (payload) {
    return ApiService.post(
      `${config.CORE_ENDPOINT}company-areas-knowledge`,
      payload
    );
  },
  updateAreasKnowledge (payload) {
    return ApiService.put(
        `${config.CORE_ENDPOINT}company-areas-knowledge`,
        payload
    );
  },
  registerBranchOffice (payload) {
    return ApiService.post(`${config.CORE_ENDPOINT}branch-offices`, payload);
  },
  registerManagerCompany (payload) {
    return ApiService.post(`${config.CORE_ENDPOINT}companies`, payload);
  },
  registerManagerCompanyLogo (payload) {
    return ApiService.put(
      `${config.CORE_ENDPOINT}companies/${payload.id}`,
      payload.data
    );
  },
  registerTeam (payload) {
    return ApiService.post('/team/teams', payload);
  },
  updateManagerData (payload) {
    return ApiService.put(`${config.CORE_ENDPOINT}managers/${payload.id}`, payload.data);
  },
  updateCompanyData (payload) {
    return ApiService.put(`${config.CORE_ENDPOINT}companies/${payload.id}`, payload.data);
  },
  updateCompanyLogo (payload) {
    return ApiService.put(`${config.CORE_ENDPOINT}companies/${payload.id}/files`,
      payload.avatar,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  },
  updateEmployee (payload, employeeId) {
    return ApiService.put(
      `${config.CORE_ENDPOINT}employees/${employeeId}`,
      payload
    );
  },
  updateEmployeePhoto (payload) {
    return ApiService.put(
        `${config.CORE_ENDPOINT}employee-profile/${payload.id}/files`,
        payload.avatar,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
    );
  },
  updateJobPosition (jobPosition, jobId) {
    return ApiService.put(
      `${config.CORE_ENDPOINT}job-profile-company/${jobId}`,
      jobPosition
    );
  },
  updateBranchOffice (payload) {
    return ApiService.put(`${config.CORE_ENDPOINT}branch-offices/${payload.id}`, payload);
  },
  updateTeam (payload) {
    return ApiService.put(`/team/teams/${payload.id}`, payload.team);
  },
  deleteEmployee (employeeId) {
    return ApiService.delete(`${config.CORE_ENDPOINT}employees`, employeeId);
  },
  deleteJobProfile (jobId) {
    return ApiService.delete(`${config.CORE_ENDPOINT}job-profile-company/${jobId}`);
  },
  deleteWorkTeam (teamID) {
    return ApiService.delete(`/team/teams/${teamID}`);
  },
  disableEmployee (payload) {
    return ApiService.put(
      `${config.CORE_ENDPOINT}employees/${payload.id}`,
      {
        status: payload.state
      }
    );
  },
  enableEmployee (payload) {
    return ApiService.put(
      `${config.CORE_ENDPOINT}employees/${payload.id}`,
      payload.state
    );
  },
  disableJobPosition (payload) {
    return ApiService.put(
      `${config.CORE_ENDPOINT}job-profile-company/${payload.jobProfile.id}`,
      payload.jobProfile
    );
  },
  getMatchSoftSkills (payload) {
    return ApiService.get(
      `${config.DATA_PROXY_ENDPOINT}matching/soft_skills/?name=${payload}&limit=250&language_id=${i18n.locale}&`
    );
  },
  getMatchSoftSkillsMatchEnable (payload) {
    return ApiService.get(
      `${config.DATA_PROXY_ENDPOINT}matching/soft_skills/?match_enable=true&limit=250&language_id=${i18n.locale}&`
    );
  },
  getMatchHardSkills (payload) {
    return ApiService.get(
      `${config.DATA_PROXY_ENDPOINT}matching/validated_skill?validated_skill_contains=${payload}&language_id=${i18n.locale}&`
    );
  },
  fetchEmployeeReport (employeeId) {
    return ApiService.get(
      `${config.CORE_ENDPOINT}employees/${employeeId}/report`
    );
  },
  getEmployeesByCompany (jobId) {
    return ApiService.get(
      `${config.DATA_ENDPOINT}report-job-profile/${jobId}/employees-by-company`
    );
  },
  getRecommendedEmployeesByCompany (jobId) {
    return ApiService.get(
      `${config.DATA_ENDPOINT}report-job-profile/${jobId}/employees-recommend-by-company`
    );
  },
  fetchJobProfileReport (jobId) {
    return ApiService.get(
      `${config.CORE_ENDPOINT}job-profile-company/${jobId}/report`
    );
  },
  getJobProfileMarketSkills (jobId) {
    return ApiService.get(
        `${config.DATA_ENDPOINT}report-job-profile/${jobId}/skill-market`
    );
  },
  fetchJobProfileCompareReport (payload) {
    return ApiService.get(
      `${config.CORE_ENDPOINT}job-profile-company/${payload.jobId}/report-compare/${payload.employeeId}`
    );
  },
  getReportCompareSkills (payload) {
    return ApiService.get(
        `${config.DATA_ENDPOINT}report-compare/${payload.jobId}/${payload.employeeId}/skill`
    );
  },
  getReportCompareCourses (payload) {
    return ApiService.get(
        `${config.DATA_ENDPOINT}report-compare/${payload.jobId}/${payload.employeeId}/courses-recommended`
    );
  },
  fetchDashboardData (companyId) {
    // return ApiService.get(`${config.DATA_ENDPOINT}dashboard-company`);
    return ApiService.get(`${config.CORE_ENDPOINT}companies/${companyId}/dashboard`);
  },
  fetchWorkFields (payload) {
    return ApiService.query(
      `${config.CORE_ENDPOINT}workfield`,
      {
        name: payload.name ?? null,
        limit: payload.limit ?? -1,
        offset: payload.page ?? 0
      }
    );
  },
  getMarketTrendsJobProfiles (payload) {
    if (payload !== undefined) {
      return ApiService.get(
        `${config.DATA_ENDPOINT}market-trend-job-profiles/?${payload}`
      );
    }
    return ApiService.get(`${config.DATA_ENDPOINT}market-trend-job-profiles`);
  },
  getMarketTrendsSkills () {
    return ApiService.get(`${config.DATA_ENDPOINT}market-trend-skills`);
  },
  getRecommendedTeamCourses (teamId) {
    return ApiService.get(
        `/team/teams/${teamId}/recommended-courses`
    );
  },
  getEmployeesRoles () {
    return ApiService.get(
        `${config.CORE_ENDPOINT}employees-roles`
    );
  },
  getSubscribedUsers () {
    return ApiService.get(
        `${config.CORE_ENDPOINT}users`
    );
  },
  fetchRolPermissions (companyId) {
    return ApiService.get(`${config.CORE_ENDPOINT}companies/${companyId}/role-permissions`);
  },
  updateRolPermissions (payload) {
    return ApiService.put(`${config.CORE_ENDPOINT}companies/${payload.id}/role-permissions`,
      {
        role_permissions: payload.permissions
      }
    );
  },
  fetchDefaultRolPermissions () {
    return ApiService.get(`${config.CORE_ENDPOINT}company-default-role-permissions`);
  },
  restoreDeletedEmployee (employeeId) {
    return ApiService.put(
        `${config.CORE_ENDPOINT}employees/restore/${employeeId}`
    );
  },
  sendAlertCompleteRegisterEmployee (employeeId) {
    return ApiService.post(
        `${config.CORE_ENDPOINT}employees-notification`,
        {
          employee: employeeId
        }
    );
  },
  deleteCompany (companyId) {
    return ApiService.delete(`${config.CORE_ENDPOINT}companies/${companyId}`);
  },
  deleteBranchOffice (branchOfficeId) {
    return ApiService.delete(`${config.CORE_ENDPOINT}branch-offices/${branchOfficeId}`);
  },
  deleteAreaKnowledge (payload) {
    return ApiService.post(`${config.CORE_ENDPOINT}company-areas-knowledge-remove`, payload);
  },
  updateAreaKnowledge (payload) {
    return ApiService.put(`${config.CORE_ENDPOINT}company-areas-knowledge/${payload.companyId}`, payload);
  },
  changeUserRole (payload) {
    return ApiService.put(`${config.CORE_ENDPOINT}companies/${payload.id}/assign-permissions`, {
      role_type: payload.role,
      user: payload.user
    });
  },
  shareEmployeeProposal (payload) {
    return ApiService.put(`${config.CORE_ENDPOINT}employees/${payload.employeeId}/preferred-job-profile-company/${payload.jobId}/`);
  },
  newProposal (payload) {
    return ApiService.post('/team/proposed-team', payload);
  }
};
export default ManagerService;
