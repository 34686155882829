export default {
  softSkills: [],
  hardSkills: [],
  workfieldsComputed: [],
  workfields: [],
  levelOfStudy: [],
  jobProfiles: [],
  yearsExperience: [],
  totalSkills: 0
};
