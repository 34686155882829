export default {
  employee: {},
  certificates: [],
  favoriteCourses: [],
  totalFavoritesCourses: 0,
  jobProfilesRecommends: [],
  coursesRecommends: [],
  totalRecommendedCourses: 0,
  courseDetail: null,
  jobProfileDetail: null,
  mostDemandCourses: [],
  mostDemandJobProfiles: [],
  matchHardSkillsWithJobProfile: {},
  softSkillReport: {},
  hardSkillReport: {}
};
