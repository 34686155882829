// Mutation Types
import * as types from './types';

export default {
  [types.SET_LOADING] (state, value) {
    state.loading = value;
  },
  [types.SET_LANGUAGE] (state, value) {
    state.language = value;
  },
  [types.USER_PREFERENCES_LANGUAGE] (state, payload) {
    state.language = payload;
  }
};
