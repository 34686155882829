export default {
  getCoursesEducationCenter: (state) => {
    return state.coursesEducationCenter;
  },
  getTotalCoursesEducationCenter: (state) => {
    return state.totalCourseEducationCenter;
  },
  courseById: (state) => {
    return state.courseById;
  },
  getEducationCenter: (state) => {
    return state.educationCenter;
  },
  competencesDemandByAreaOfKnowledge: (state) => {
    return state.competencesDemandByAreaOfKnowledge;
  },
  marketJobProfiles: (state) => {
    return state.marketJobProfiles;
  },
  marketSkills: (state) => {
    return state.marketSkills;
  },
  marketHomePageSkills: (state) => {
    return state.marketHomePageSkills;
  },
  notificationsEducationCenter: (state) => {
    return state.notificationsEducationCenter;
  },
  getDataDashboardEducationCenter: (state) => {
    return state.dataDashboardEducationCenter;
  },
  getLeadCourses: (state) => {
    return state.leadsCourses;
  },
  getTotalLeadCourses: (state) => {
    return state.totalLeadsCourses;
  },
  managerCenter: (state) => {
    return state.managerCenter;
  },
  getLeadFavoritesCourses: (state) => {
    return state.leadsFavoritesCourses;
  },
  getTotalFavoritesCourses: (state) => {
    return state.totalFavoritesCourses;
  },
  getLeadsByJobProfile: (state) => {
    return state.leadsByJobProfile;
  },
  getTotalLeadsByJobProfile: (state) => {
    return state.totalLeadsByJobProfile;
  },
  getLeadsByCurrentJobProfile: (state) => {
    return state.leadsByCurrentJobProfile;
  },
  getTotalLeadsByCurrentJobProfile: (state) => {
    return state.totalLeadsByCurrentJobProfile;
  },
  getHeadquarters: (state) => {
    return state.headquarters;
  },
  getTotalHeadquarters: (state) => {
    return state.totalHeadquarters;
  }
};
