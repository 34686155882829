export default {
  isLogged: (state) => {
    return state.isLogged;
  },
  accessToken: (state) => {
    return state.accessToken;
  },
  refreshToken: (state) => {
    return state.refreshToken;
  },
  childId: (state) => {
    return state.childId;
  },
  language: (state) => {
    return state.lanuage;
  },
  userId: (state) => {
    return state.info.id;
  },
  type: (state) => {
    return state.info.user_type ? state.info.user_type : 'public';
  },
  userInfo (state) {
    return state.info;
  },
  activation (state) {
    return state.activation;
  }
};
