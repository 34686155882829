import * as types from './types';
import ManagerService from '@/services/ManagerService';

export default {
  async getAreasKnowledge ({ commit }, payload) {
    try {
      const response = await ManagerService.fetchAreasKnowledge(payload);
      commit(types.SET_AREAS_KNOWLEDGE, response.data.results);
      commit(types.SET_TOTAL_AREAS_KNOWLEDGE, response.data.count);
    } catch (e) {
    }
  },
  async fetchEmployees ({ commit }, payload) {
    try {
      const response = await ManagerService.fetchEmployees(payload);
      commit(types.SET_EMPLOYEES, response.data.results);
      commit(types.SET_TOTAL_EMPLOYEES, response.data.count);
    } catch (e) {
      commit(types.SET_EMPLOYEES, []);
      commit(types.SET_TOTAL_EMPLOYEES, 0);
    }
  },
  async fetchFilterEmployees ({ commit }, payload) {
    try {
      const response = await ManagerService.fetchFilterEmployees(payload);
      commit(types.SET_EMPLOYEES, response.data.results);
      commit(types.SET_TOTAL_EMPLOYEES, response.data.count);
    } catch (e) {
      commit(types.SET_EMPLOYEES, []);
      commit(types.SET_TOTAL_EMPLOYEES, 0);
    }
  },
  async getEmployee ({ commit }, employeeId) {
    try {
      const response = await ManagerService.fetchEmployee(employeeId);
      commit(types.SET_EMPLOYEE, response.data);
    } catch (e) {
      commit(types.SET_EMPLOYEE, {});
    }
  },
  async fetchCourseDetail ({ commit }, payload) {
    try {
      const response = await ManagerService.fetchCourseDetail(payload);
      commit(types.SET_COURSE_DETAIL, response.data);
    } catch (e) {
      commit(types.SET_COURSE_DETAIL, {});
    }
  },
  async fetchTeamCourseDetail ({ commit }, payload) {
    try {
      const response = await ManagerService.fetchTeamCourseDetail(payload);
      commit(types.SET_COURSE_DETAIL, response.data);
    } catch (e) {
      commit(types.SET_COURSE_DETAIL, {});
    }
  },
  async fetchManagerData ({ commit }, id) {
    try {
      const response = await ManagerService.fetchManagerData(id);
      commit(types.SET_MANAGER_DATA, response.data);
      commit(types.SET_USER_ROL_PERMISSIONS, response.data.data.manager_type);
    } catch (e) {
      commit(types.SET_MANAGER_DATA, {});
    }
  },
  async loadCompanyData ({ commit }) {
    try {
      const response = await ManagerService.fetchCompanyData();
      commit(types.SET_COMPANY_DATA, response.data.results);
    } catch (e) {
      commit(types.SET_COMPANY_DATA, {});
    }
  },
  async loadJobPositions ({ commit }, payload) {
    try {
      const response = await ManagerService.fetchJobProfiles(payload);
      commit(types.SET_JOB_POSITIONS, response.data.results);
      commit(types.SET_TOTAL_JOB_PROFILES, response.data.count);
    } catch (e) {
      commit(types.SET_JOB_POSITIONS, []);
      commit(types.SET_TOTAL_JOB_PROFILES, 0);
    }
  },
  async fetchJobPositionData ({ commit }, jobId) {
    return ManagerService.fetchJobPositionData(jobId);
  },
  async fetchFilterJobPositions ({ commit }, payload) {
    try {
      const response = await ManagerService.fetchFilterJobPositions(payload);
      commit(types.SET_JOB_POSITIONS, response.data.results);
      commit(types.SET_TOTAL_JOB_PROFILES, response.data.count);
    } catch (e) {
      commit(types.SET_JOB_POSITIONS, []);
      commit(types.SET_TOTAL_JOB_PROFILES, 0);
    }
  },
  async fetchJobProfile ({ commit }, jobId) {
    try {
      const response = await ManagerService.fetchJobProfile(jobId);
      commit(types.SET_JOB_POSITION, response.data);
    } catch (e) {
      commit(types.SET_JOB_POSITION, []);
    }
  },
  async fetchWorkFields (_, payload) {
    try {
      return await ManagerService.fetchWorkFields(payload);
    } catch (e) {
      console.log(e);
    }
  },
  async fetchTeams ({ commit }, payload) {
    try {
      return await ManagerService.fetchTeams(payload);
    } catch (e) {
    }
  },
  async loadTeam ({ commit }, teamId) {
    try {
      const response = await ManagerService.getTeam(teamId);
      commit(types.SET_TEAM, response.data);
    } catch (e) {
      commit(types.SET_TEAM, []);
    }
  },
  async fetchFilterTeams ({ commit }, payload) {
    return ManagerService.fetchFilterTeams(payload);
  },
  async fetchBranchOffices ({ commit }, page) {
    try {
      const response = await ManagerService.fetchBranchOffices(page);
      commit(types.SET_BRANCH_OFFICE, response.data.results);
      commit(types.SET_TOTAL_BRANCH_OFFICE, response.data.count);
    } catch (e) {
      commit(types.SET_BRANCH_OFFICE, []);
      commit(types.SET_TOTAL_BRANCH_OFFICE, 0);
    }
  },
  async getJobProfiles ({ commit }) {
    try {
      const response = await ManagerService.fetchJobProfiles();
      commit(types.SET_JOB_PROFILES, response.data.results);
    } catch (e) {
      commit(types.SET_JOB_PROFILES, []);
    }
  },
  async getCountries ({ commit }, payload) {
    try {
      const response = await ManagerService.fetchCountries(payload);
      commit(types.SET_COUNTRIES, response.data.data);
    } catch (e) {
      commit(types.SET_COUNTRIES, []);
    }
  },
  async getStates ({ commit }, payload) {
    try {
      const response = await ManagerService.fetchStates(payload);
      commit(types.GET_STATES, response.data);
    } catch (e) {
      commit(types.GET_STATES, []);
    }
  },
  async getLocalities ({ commit }, payload) {
    try {
      const response = await ManagerService.fetchLocalities(payload);
      commit(types.GET_LOCALITIES, response.data);
    } catch (e) {
      commit(types.GET_LOCALITIES, []);
    }
  },
  async registerEmployee ({ commit }, payload) {
    await ManagerService.registerEmployee(payload);
  },
  async registerEmployeeByCSV ({ commit }, payload) {
    return ManagerService.registerEmployeesByCSV(payload);
  },
  async registerJobProfile ({ commit }, payload) {
    await ManagerService.registerJobProfile(payload);
  },
  async registerAreasKnowledge ({ commit }, payload) {
    await ManagerService.registerAreasKnowledge(payload);
  },
  async updateAreasKnowledge ({ commit }, payload) {
    try {
      await ManagerService.updateAreasKnowledge(payload);
    } catch (e) {
    }
  },
  async registerBranchOffice ({ commit }, payload) {
    await ManagerService.registerBranchOffice(payload);
  },
  async registerManagerCompany ({ commit }, payload) {
    try {
      const response = await ManagerService.registerManagerCompany(payload);
      commit(types.SET_COMPANY, response.data);
    } catch (e) {
      commit(types.SET_COMPANY, {});
      throw e;
    }
  },
  async registerManagerCompanyLogo ({ commit }, payload) {
    try {
      await ManagerService.registerManagerCompanyLogo(payload);
    } catch (e) {
    }
  },
  async updateCompanyLogo ({ commit }, payload) {
    return ManagerService.updateCompanyLogo(payload);
  },
  async registerTeam ({ commit }, payload) {
    try {
      const response = await ManagerService.registerTeam(payload);
      commit(types.SET_TEAM, response.data);
    } catch (e) {
      commit(types.SET_TEAM, []);
    }
  },
  async updateEmployee ({ commit }, payload) {
    await ManagerService.updateEmployee(payload.employee, payload.id);
  },
  async updateEmployeePhoto ({ commit }, payload) {
    return ManagerService.updateEmployeePhoto(payload);
  },
  async updateManagerData ({ commit }, payload) {
    await ManagerService.updateManagerData(payload);
  },
  async updateCompanyData ({ commit }, payload) {
    await ManagerService.updateCompanyData(payload);
  },
  async updateJobPosition ({ commit }, payload) {
    await ManagerService.updateJobPosition(payload.job_position, payload.id);
  },
  async updateTeam ({ commit }, payload) {
    try {
      const response = await ManagerService.updateTeam(payload);
      commit(types.SET_TEAM, response.data);
    } catch (e) {
      commit(types.SET_TEAM, []);
    }
  },
  async disableEmployee ({ commit }, payload) {
    await ManagerService.disableEmployee(payload);
  },
  async updateBranchOffice ({ commit }, payload) {
    await ManagerService.updateBranchOffice(payload);
  },
  async disableJobPosition ({ commit }, payload) {
    await ManagerService.disableJobPosition(payload);
  },
  async deleteEmployee ({ commit }, payload) {
    return ManagerService.deleteEmployee(payload);
  },
  async deleteJobProfile ({ commit }, jobId) {
    await ManagerService.deleteJobProfile(jobId);
  },
  async deleteWorkTeam ({ commit }, teamId) {
    try {
      await ManagerService.deleteWorkTeam(teamId);
    } catch (e) {
      console.log(e);
    }
  },
  fetchEmployeeReport (_, employeeId) {
    try {
      return ManagerService.fetchEmployeeReport(employeeId);
    } catch (e) {
    }
  },
  async loadEmployeesByCompany ({ commit }, jobId) {
    try {
      const response = await ManagerService.getEmployeesByCompany(jobId);
      commit(types.SET_JOB_EMPLOYEES, response.data);
      return response;
    } catch (e) {
      console.log(e);
      commit(types.SET_JOB_EMPLOYEES, []);
    }
  },
  async loadRecommendedEmployeesByCompany ({ commit }, jobId) {
    try {
      const response = await ManagerService.getRecommendedEmployeesByCompany(
        jobId
      );
      commit(types.SET_RECOMMENDED_JOB_EMPLOYEES, response.data);
    } catch (e) {
      console.log(e);
      commit(types.SET_RECOMMENDED_JOB_EMPLOYEES, []);
    }
  },
  async fetchJobProfileReport ({ commit }, jobId) {
    try {
      return await ManagerService.fetchJobProfileReport(jobId);
    } catch (e) {
    }
  },
  async loadJobProfileMarketSkills ({ commit }, jobId) {
    try {
      return await ManagerService.getJobProfileMarketSkills(jobId);
    } catch (e) {
      console.log(e);
    }
  },
  async fetchJobProfileCompareReport ({ commit }, jobId) {
    try {
      return await ManagerService.fetchJobProfileCompareReport(jobId);
    } catch (e) {
    }
  },
  async loadReportCompareSkills ({ commit }, payload) {
    try {
      return await ManagerService.getReportCompareSkills(payload);
    } catch (e) {
      console.log(e);
    }
  },
  async loadReportCompareCourses ({ commit }, payload) {
    try {
      return await ManagerService.getReportCompareCourses(payload);
    } catch (e) {
      console.log(e);
    }
  },
  async fetchDashboardData (_, companyId) {
    try {
      return await ManagerService.fetchDashboardData(companyId);
    } catch (e) {
      console.log(e);
    }
  },
  async loadRecommendedTeamCourses ({ commit }, teamId) {
    try {
      return await ManagerService.getRecommendedTeamCourses(teamId);
    } catch (e) {}
  },
  async loadEmployeesRoles () {
    try {
      return await ManagerService.getEmployeesRoles();
    } catch (e) {}
  },
  async loadSubscribedUsers () {
    try {
      return await ManagerService.getSubscribedUsers();
    } catch (e) {}
  },
  async fetchRolPermissions ({ commit }, companyId) {
    try {
      const response = await ManagerService.fetchRolPermissions(companyId);
      commit(types.SET_COMPANY_ROL_PERMISSIONS, response.data.data.role_permissions);
    } catch (e) {
      commit(types.SET_COMPANY_ROL_PERMISSIONS, []);
    }
  },
  async updateRolPermissions ({ commit }, payload) {
    return ManagerService.updateRolPermissions(payload);
  },
  async fetchDefaultRolPermissions ({ commit }) {
    try {
      const response = await ManagerService.fetchDefaultRolPermissions();
      commit(types.GET_DEFAULT_ROL_PERMISSIONS, response.data);
    } catch (e) {
      commit(types.GET_DEFAULT_ROL_PERMISSIONS, []);
    }
  },
  async restoreDeletedEmployee ({ commit }, employeeId) {
    try {
      await ManagerService.restoreDeletedEmployee(employeeId);
    } catch (e) {}
  },
  async sendAlertCompleteRegisterEmployee ({ commit }, payload) {
    await ManagerService.sendAlertCompleteRegisterEmployee(payload);
  },
  async deleteCompany ({ commit }, companyId) {
    return ManagerService.deleteCompany(companyId);
  },
  async deleteBranchOffice ({ commit }, branchOfficeId) {
    return ManagerService.deleteBranchOffice(branchOfficeId);
  },
  async deleteAreaKnowledge ({ commit }, payload) {
    return ManagerService.deleteAreaKnowledge(payload);
  },
  async updateAreaKnowledge ({ commit }, payload) {
    return ManagerService.updateAreaKnowledge(payload);
  },
  async changeUserRole ({ commit }, user) {
    return ManagerService.changeUserRole(user);
  },
  async shareEmployeeProposal (_, payload) {
    return ManagerService.shareEmployeeProposal(payload);
  },
  newProposal (_, payload) {
    return ManagerService.newProposal(payload);
  }
};
