export default {
  areaKnowledge: (state) => {
    return state.areaKnowledge;
  },
  getTotalAreaKnowledge: (state) => {
    return state.totalAreaKnowledge;
  },
  employees: (state) => {
    return state.employees;
  },
  getTotalEmployees: (state) => {
    return state.totalEmployees;
  },
  employee: (state) => {
    return state.employee;
  },
  branchOffice (state) {
    return state.branchOffice;
  },
  getTotalBranchOffices (state) {
    return state.totalBranchOffice;
  },
  jobProfiles (state) {
    return state.jobProfiles;
  },
  countries (state) {
    return state.countries;
  },
  states (state) {
    return state.states;
  },
  localities (state) {
    return state.localities;
  },
  getJobPositions (state) {
    return state.jobPositions;
  },
  getTotalJobProfiles (state) {
    return state.totalJobProfiles;
  },
  getJobPosition (state) {
    return state.jobPosition;
  },
  getJobEmployees (state) {
    return state.jobEmployees;
  },
  getRecommendedJobEmployees (state) {
    return state.recommendedJobEmployees;
  },
  company: (state) => {
    return state.company;
  },
  managerData: (state) => {
    return state.managerData;
  },
  companyData: (state) => {
    return state.companyData;
  },
  getWorkTeam: (state) => {
    return state.team;
  },
  courseDetail: (state) => {
    return state.courseDetail;
  },
  getDefaultRolPermissions: (state) => {
    return state.defaultRolPermissions;
  },
  getCompanyRolePermissions: (state) => {
    return state.companyRolePermissions;
  },
  getUserRolPermissions: (state) => {
    return state.userRolPermissions;
  }
};
