export default {
  hardSkills: [],
  profilesValidated: [],
  totalValidatedProfiles: 0,
  profilesUnvalidated: [],
  totalUnvalidatedProfiles: 0,
  profiles: {
    count: 0,
    results: []
  },
  stopWords: {
    count: 0,
    results: []
  },
  newSkills: {
    count: 0,
    results: []
  },
  sectors: {
    count: 0,
    results: []
  },
  ranking: null,
  listStopWords: [],
  totalWords: 0,
  skills: [],
  totalSkills: 0,
  validatedSkills: [],
  totalValidatedSkills: 0,
  mostDemandProfiles: [],
  totalMostDemandProfiles: 0,
  validatedSkillsFilters: {},
  jobProfileSkills: '',
  jobProfileKnowledge: '',
  totalJobProfilesSkills: 0,
  totalJobProfilesKnowledge: 0,
  fetchProposedProfiles: [],
  totalProposedProfiles: 0,
  proposedJobProfile: {},
  totalProposedJobProfile: 0,
  notValidatedJobProfiles: [],
  totalNotValidatedJobProfiles: 0
};
