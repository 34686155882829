import config from '@/config/config.js';
import ApiService from '@/services';

export const IndividualService = {
  registerIndividual (payload) {
    return ApiService.post(`${config.CORE_ENDPOINT}individual/`, {
      user: payload.user,
      first_name: payload.firstName,
      last_name: payload.lastName,
      phone_number: payload.phoneNumber,
      document: payload.document,
      profile: {
        level_study: payload.profile.levelStudy.id,
        range_year_experience: payload.profile.rangeYearExperience,
        question: payload.profile.question,
        hard_skill: payload.profile.hardSkills,
        soft_skill: payload.profile.softSkills,
        work_field: payload.profile.workfields,
        preferred_work_field: payload.profile.preferredWorkfields || null,
        per_match_base: payload.profile.perMatchBase,
        certificates: payload.profile?.certificates ?? null,
        current_work_field: payload.profile?.current_work_field,
        current_job_profile: payload.profile?.current_job_profile || null
      },
      accept_privacy_policy: payload.acceptPrivacyPolicy,
      address: payload.address
    });
  },
  getIndividualById (individualId) {
    return ApiService.get(`${config.CORE_ENDPOINT}individual`, individualId); // Cambiar a candidate/
  },
  updateIndividual (payload) {
    return ApiService.put(`${config.CORE_ENDPOINT}individual/${payload.id}`, {
      user: payload.user.id,
      first_name: payload.first_name,
      last_name: payload.last_name,
      document: payload.document,
      authorization_share_data: payload.authorization_share_data,
      profile: {
        id: payload.profile.id,
        user: payload.user.id,
        level_study: payload.profile.level_study.id,
        range_year_experience: payload.profile.range_year_experience.id,
        question: payload.profile.question,
        hard_skill: payload.profile.hard_skill,
        soft_skill: payload.profile.soft_skill,
        work_field: payload.profile.work_field,
        preferred_job_profile: payload.profile.preferred_job_profile,
        preferred_work_field: payload.profile.preferred_work_field || null,
        certificates: payload.profile.certificates,
        current_work_field: payload.profile?.current_work_field[0].id || null,
        current_job_profile: payload.profile?.current_job_profile || null
      },
      address: payload.address
    });
  },
  fetchProfessionalCertificate (payload) {
    return ApiService.query(
      `${config.CORE_ENDPOINT}professional-certificate`,
      {
        name: payload.name ?? null,
        limit: payload.limit || 10,
        offset: payload.offset || 0
      }
    );
  },
  fetchFavoriteCourses (payload) {
    return ApiService.query(`${config.CORE_ENDPOINT}individual/${payload.individualId}/favorite-courses`, {
      name: payload.name || null,
      type_modality: payload.type_modality || null,
      min_hour: payload.min_hour || null,
      max_hour: payload.max_hour || null,
      min_price: payload.min_price || null,
      max_price: payload.max_price || null,
      offset: payload.offset || 0,
      limit: payload.limit || 10
    });
  },
  postFavoriteCourses (payload) {
    return ApiService.post(
      `${config.EDUCATION_ENDPOINT}favorite-course`,
      { ...payload }
    );
  },
  deleteFavoriteCourses (payload) {
    return ApiService.delete(
      `${config.EDUCATION_ENDPOINT}favorite-course/${payload.id}`
    );
  },
  fetchMostDemandCourses () {
    return ApiService.query(`${config.EDUCATION_ENDPOINT}ranking-courses`);
  },
  fetchMostDemandJobProfiles () {
    return ApiService.query(`${config.CORE_ENDPOINT}ranking-job-profiles`);
  },
  fetchRecommendedJobProfiles (payload) {
    return ApiService.query(`${config.CORE_ENDPOINT}individual/${payload.individualId}/recommended-job-profiles`, {
      name: payload.name || null,
      work_field: payload.work_field || null,
      offset: payload.offset || 0,
      limit: payload.limit || 10
    });
  },
  fetchCoursesRecommends (payload) {
    return ApiService.query(`${config.CORE_ENDPOINT}individual/${payload.individualId}/recommended-courses`, {
      name: payload.name || null,
      type_modality: payload.type_modality || null,
      min_hour: payload.min_hour || null,
      max_hour: payload.max_hour || null,
      min_price: payload.min_price || null,
      max_price: payload.max_price || null,
      offset: payload.offset || 0,
      limit: payload.limit || 10
    });
  },
  fetchCoursesRecommendsFilterSkill (payload) {
    if (payload.name) {
      return ApiService.query(`${config.CORE_ENDPOINT}individual/${payload.individualId}/recommended-courses`, {
        skill_name: payload.name || null
      });
    } else {
      return ApiService.query(`${config.CORE_ENDPOINT}individual/${payload.individualId}/recommended-courses`);
    }
  },
  fetchCourseDetail (payload) {
    return ApiService.query(
      `${config.CORE_ENDPOINT}individual/${payload.individualId}/recommended-courses/${payload.courseId}`
      // {
      //   is_centre: payload.isCenter,
      //   id_child: payload.courseIdChild
      // }
    );
  },
  fetchJobProfileDetail (payload) {
    return ApiService.get(
      `${config.CORE_ENDPOINT}individual/${payload.individualId}/recommended-job-profiles/${payload.courseId}/${payload.workfieldId}`
    );
  },
  savedAvatar (payload) {
    return ApiService.put(
      `${config.CORE_ENDPOINT}individual/${payload.id}/files`,
      payload.avatar,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  },
  postChosenCourse (payload) {
    return ApiService.post(`${config.CORE_ENDPOINT}individual/${payload.individualId}/recommended-courses/${payload.courseId}/chosen-course/`);
  },
  fetchReportHardSkill (individualId) {
    return ApiService.get(
      `${config.CORE_ENDPOINT}individual/${individualId}/report-hard-skill`
    );
  },
  fetchSkills (payload) {
    return ApiService.query(`${config.CORE_ENDPOINT}skill`,
      {
        type_skill: payload.type_skill,
        name: payload.name ?? ''
      });
  },
  fetchWorkField (workFieldId) {
    return ApiService.query(`${config.CORE_ENDPOINT}workfield`,
      {
        id: workFieldId
      });
  },
  softSkill_test (id) {
    return ApiService.get(
      `${config.CORE_ENDPOINT}individual/${id}/softskill_test`
    );
  },
  registerSoftSkillVideo (payload) {
    return ApiService.put(
      `${config.CORE_ENDPOINT}individual/${payload.id}/files`,
      payload
    );
  },
  postSoftSkillsQuestions (payload) {
    return ApiService.post(`${config.CORE_ENDPOINT}softskill_test`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  deleteIndividual (individualId) {
    return ApiService.delete(`${config.CORE_ENDPOINT}individual`, individualId);
  },
  saveSoftSkillDetection (id) {
    return ApiService.get(`${config.CORE_ENDPOINT}softskill_test/${id}/detected-skills`);
  },
  fetchReportSoftSkill (individualId) {
    // return ApiService.get(`${config.CORE_ENDPOINT}individual/${individualId}/softskill`);
    return ApiService.get(`${config.CORE_ENDPOINT}individual/${individualId}/report-soft-skill`);
  },
  deleteIndividualAccount (payload) {
    return ApiService.delete(`${config.CORE_ENDPOINT}individual/${payload}`);
  },
  fetchJobProfiles (payload) {
    return ApiService.query(`${config.CORE_ENDPOINT}job-profile`,
      {
        name: payload.name,
        work_field: payload.work_field || null
      });
  },
  updatePreferredJobProfile (payload) {
    return ApiService.put(
      `${config.CORE_ENDPOINT}individual/${payload.individualId}/preferred-job-profile/${payload.jobProfileId}/${payload.workfieldId}`
    );
  },
  transferIndividualData (payload) {
    return ApiService.put(`${config.CORE_ENDPOINT}individual/${payload.id}`, payload.user);
  }
};
export default IndividualService;
