import config from '@/config/config.js';
import ApiService from '@/services';

export const ApplicationService = {
  getUserPreferencesLanguage () {
    return ApiService.get(`${config.CORE_ENDPOINT}user-preferences/language`);
  },
  updateUserPreferencesLanguage (lang) {
    return ApiService.post(`${config.CORE_ENDPOINT}user-preferences/language`, {
      language: lang
    });
  },
  subscribeNewsletter (email) {
    return ApiService.post(`${config.CORE_ENDPOINT}newsletter`, {
      email
    });
  },
  sendContactForm (payload) {
    return ApiService.post(
`${config.CORE_ENDPOINT}contact-form-company`,
payload
    );
  },
  fetchMarketTrendReport (payload) {
    if (payload) {
      return ApiService.query(`${config.CORE_ENDPOINT}market-trend-report`,
        {
          year_register: payload.year ?? null,
          country: payload.country ?? null,
          work_field: payload.work_field ?? null,
          job_profile: payload.job_profile ?? null,
          limit: payload.limit ?? 10,
          offset: payload.offset ?? 0
        }
      );
    } else {
      return ApiService.query(`${config.CORE_ENDPOINT}market-trend-report`);
    }
  }
};

export default ApplicationService;
