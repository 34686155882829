import * as types from './types';
import EmployeeService from '@/services/EmployeeService';

export default {
  async registerEmployee ({ commit }, payload) {
    const response = await EmployeeService.completeRegisterEmployee(payload);
    commit(types.REGISTER_EMPLOYEE, response.data);
  },
  async getEmployeeById ({ commit }, payload) {
    try {
      const response = await EmployeeService.fetchEmployeeById(payload);
      commit(types.SET_EMPLOYEE, response.data.data);
    } catch (e) {
    }
  },
  async updateEmployee ({ commit }, payload) {
    const response = await EmployeeService.updateEmployee(payload);
    commit(types.UPDATE_EMPLOYEE, response.data);
  },
  async fetchFavoriteCourses ({ commit }, payload) {
    try {
      const response = await EmployeeService.fetchFavoriteCourses(payload);
      commit(types.SET_FAVORITE_COURSES, response.data.results);
      commit(types.SET_TOTAL_FAVORITE_COURSES, response.data.count);
    } catch (e) {
      commit(types.SET_FAVORITE_COURSES, []);
      commit(types.SET_TOTAL_FAVORITE_COURSES, 0);
    }
  },
  async postFavoriteCourses (_, payload) {
    await EmployeeService.postFavoriteCourses(payload);
  },
  async deleteFavoriteCourse (_, payload) {
    await EmployeeService.deleteFavoriteCourse(payload);
  },
  async getMostDemandCourses ({ commit }) {
    const response = await EmployeeService.getMostDemandCourses();
    commit(types.GET_MOST_DEMAND_COURSES, response.data.results);
  },
  async getMostDemandJobProfiles ({ commit }) {
    const response = await EmployeeService.getMostDemandJobProfiles();
    commit(types.GET_MOST_DEMAND_JOB_PROFILE, response.data.results);
  },
  async fetchJobProfilesRecommends ({ commit }, payload) {
    const response = await EmployeeService.fetchJobProfilesRecommends(payload);
    commit(types.GET_JOB_PROFILE_RECOMMENDS, response.data.data);
  },
  async fetchCoursesRecommends ({ commit }, payload) {
    try {
      const response = await EmployeeService.fetchCoursesRecommends(payload);
      commit(types.SET_COURSES_RECOMMENDS, response.data.results);
      commit(types.SET_TOTAL_RECOMMENDED_COURSES, response.data.count);
    } catch (e) {
      commit(types.SET_COURSES_RECOMMENDS, []);
      commit(types.SET_TOTAL_RECOMMENDED_COURSES, 0);
    }
  },
  async fetchCourseDetail ({ commit }, payload) {
    try {
      const response = await EmployeeService.fetchCourseDetail(payload);
      commit(types.SET_COURSE_DETAIL, response.data);
    } catch (e) {
      commit(types.SET_COURSE_DETAIL, {});
    }
  },
  async fetchHardSkillsReport ({ commit }, payload) {
    try {
      const response = await EmployeeService.fetchHardSkillsReport(payload);
      commit(types.SET_EMPLOYEE_HARD_SKILLS_REPORT, response.data);
    } catch (e) {
      commit(types.SET_EMPLOYEE_HARD_SKILLS_REPORT, {});
    }
  },
  async getJobProfileDetail ({ commit }, payload) {
    const response = await EmployeeService.getJobProfileDetail(payload);
    commit(types.GET_JOB_PROFILE_DETAIL, response.data.data);
  },
  async deleteEmployee ({ commit }, payload) {
    return await EmployeeService.deleteEmployee(payload);
  },
  async savedAvatar (_, payload) {
    await EmployeeService.savedAvatar(payload);
  },
  async postChosenCourse (_, payload) {
    await EmployeeService.postChosenCourse(payload);
  },
  async getSoftSkillReport ({ commit }, payload) {
    try {
      const response = await EmployeeService.getSoftSkillReport(payload);
      commit(types.GET_SOFT_SKILLS_REPORT, response.data);
      return response;
    } catch (e) {
      commit(types.GET_SOFT_SKILLS_REPORT, {});
      throw e;
    }
  },
  async saveFiles ({ commit }, payload) {
    await EmployeeService.saveFiles(payload);
  }
};
