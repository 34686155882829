import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  customVariables: ['@/sass/main.scss'],
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        darkBlue: '#0F293B',
        ghostWhite: '#F2F3F8',
        purple: '#7575C1',
        grayCardTextLight: '#bcbcdc',
        grayText: '#62636A',
        grayTextLight: '#757575',
        grayTextDark: '#737373',
        grayNavbarText: '#B7B7B7',
        primary: '#00D5A4',
        secondary: '#2E8484',
        black: '#252B42',
        notification: '#3A9B02',
        warning: '#FFEA00',
        error: '#E87272',
        errorText: '#EE7E7E',
        redText: '#D50000',
        empleableBlue: '#113147',
        bgGrayLight: '#FAFAFA',
        bgPrimaryLight: '#ECFDF3'
      }
    }
  }
});
