// Mutation Types
import * as types from './types';

export default {
  [types.SET_COURSES_EDUCATION_CENTER] (state, payload) {
    state.coursesEducationCenter = payload.results;
  },
  [types.SET_TOTAL_COURSES_EDUCATION_CENTER] (state, payload) {
    state.totalCourseEducationCenter = payload;
  },
  [types.SET_COURSE_BY_ID] (state, payload) {
    state.courseById = payload;
  },
  [types.SET_EDUCATION_CENTER_BY_ID] (state, payload) {
    state.educationCenter = payload;
  },
  [types.GET_COMPETENCES_DEMAND_BY_AREA_OF_KNOWLEDGE] (state, payload) {
    state.competencesDemandByAreaOfKnowledge = payload;
  },
  [types.UPDATE_EDUCATION_CENTER] (state, payload) {
    state.educationCenter = payload;
  },
  [types.SET_MARKET_SKILLS] (state, payload) {
    state.marketSkills = payload.data;
  },
  [types.GET_MARKET_HOMEPAGE_SKILLS] (state, payload) {
    state.marketHomePageSkills = payload.results;
  },
  [types.GET_NOTIFICATIONS_EDUCATION_CENTER] (state, payload) {
    state.notificationsEducationCenter = payload.data;
  },
  [types.SET_DATA_DASHBOARD_EDUCATION_CENTER] (state, payload) {
    state.dataDashboardEducationCenter = payload;
  },
  [types.SET_LEAD_COURSES] (state, payload) {
    state.leadsCourses = payload;
  },
  [types.SET_TOTAL_LEAD_COURSES] (state, payload) {
    state.totalLeadsCourses = payload;
  },
  [types.SET_MANAGER_CENTER_BY_ID] (state, payload) {
    state.managerCenter = payload;
  },
  [types.UPDATE_MANAGER_CENTER] (state, payload) {
    state.managerCenter = payload;
  },
  [types.SET_LEADS_FAVORITES_COURSES] (state, payload) {
    state.leadsFavoritesCourses = payload;
  },
  [types.SET_TOTAL_FAVORITES_COURSES] (state, payload) {
    state.totalFavoritesCourses = payload;
  },
  [types.SET_LEADS_BY_JOB_PROFILE] (state, payload) {
    state.leadsByJobProfile = payload;
  },
  [types.SET_TOTAL_LEADS_BY_JOB_PROFILE] (state, payload) {
    state.totalLeadsByJobProfile = payload;
  },
  [types.SET_LEADS_BY_CURRENT_JOB_PROFILE] (state, payload) {
    state.leadsByCurrentJobProfile = payload;
  },
  [types.SET_TOTAL_LEADS_BY_CURRENT_JOB_PROFILE] (state, payload) {
    state.totalLeadsByCurrentJobProfile = payload;
  },
  [types.SET_HEADQUARTERS] (state, payload) {
    state.headquarters = payload.results;
  },
  [types.SET_TOTAL_HEADQUARTERS] (state, payload) {
    state.totalHeadquarters = payload.count;
  }
};
