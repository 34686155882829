import * as types from './types';

export default {
  [types.REGISTER_EMPLOYEE] (state, payload) {
    state.employee = payload.data;
  },
  [types.SET_EMPLOYEE] (state, payload) {
    state.employee = payload;
  },
  [types.UPDATE_EMPLOYEE] (state, payload) {
    state.employee = payload.data;
  },
  [types.GET_JOB_PROFILE_RECOMMENDS] (state, payload) {
    state.jobProfilesRecommends = payload;
  },
  [types.SET_COURSES_RECOMMENDS] (state, payload) {
    state.coursesRecommends = payload;
  },
  [types.SET_TOTAL_RECOMMENDED_COURSES] (state, payload) {
    state.totalRecommendedCourses = payload;
  },
  [types.SET_COURSE_DETAIL] (state, payload) {
    state.courseDetail = payload;
  },
  [types.GET_JOB_PROFILE_DETAIL] (state, payload) {
    state.jobProfileDetail = payload;
  },
  [types.GET_MOST_DEMAND_COURSES] (state, payload) {
    state.mostDemandCourses = payload;
  },
  [types.GET_MOST_DEMAND_JOB_PROFILE] (state, payload) {
    state.mostDemandJobProfiles = payload;
  },
  [types.GET_CERTIFICATE_OF_PROFESSIONALISM] (state, payload) {
    state.certificates = payload;
  },
  [types.SET_FAVORITE_COURSES] (state, payload) {
    state.favoriteCourses = payload;
  },
  [types.SET_TOTAL_FAVORITE_COURSES] (state, payload) {
    state.totalFavoritesCourses = payload;
  },
  [types.GET_MATCH_HARD_SKILLS] (state, payload) {
    state.matchHardSkillsWithJobProfile = payload;
  },
  [types.GET_SOFT_SKILLS_REPORT] (state, payload) {
    state.softSkillReport = payload;
  },
  [types.SET_EMPLOYEE_HARD_SKILLS_REPORT] (state, payload) {
    state.hardSkillReport = payload;
  }
};
