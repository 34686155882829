<template>
  <v-app>
    <v-main class="ma-0 pa-0">
      <router-view />
      <cookies-banner
        v-if="!cookiesAccepted"
        :cookies="cookies"
        @acceptCookies="acceptCookies"
        @customCookies="customCookies"
        @confirmCookies="confirmCookies"
      />
    </v-main>
    <v-overlay style="z-index: 300" :value="loading">
      <fulfilling-bouncing-circle-spinner
        style="z-index: 999"
        :animation-duration="4000"
        :size="60"
        color="#00D5A4"
      />
    </v-overlay>
  </v-app>
</template>

<script>
import { FulfillingBouncingCircleSpinner } from 'epic-spinners';
import CookiesBanner from '@/pages/application/CookiesBanner.vue';
import { mapGetters } from 'vuex';
import Cookies from 'js-cookie';

export default {
  name: 'App',
  components: {
    FulfillingBouncingCircleSpinner,
    CookiesBanner
  },
  metaInfo () {
    return {
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.$t('home.description')
        }
      ]
    };
  },
  data () {
    return {
      cookiesAccepted: false,
      cookies: [
        {
          name: 'Google Analytics',
          type: 'google_analitics',
          accept: false
        }
      ]
    };
  },
  mounted () {
    this.cookiesAccepted = Cookies.get('cookies_accepted');
    if (JSON.parse(localStorage.getItem('vuex'))?.language.language) {
      this.$i18n.locale = JSON.parse(localStorage.getItem('vuex')).language.language;
    } else {
      this.$i18n.locale = this.$i18n.fallbackLocale;
    }
  },
  methods: {
    acceptCookies () {
      this.cookies.forEach(() => {
        if (process.env.VUE_APP_URL !== 'https://app-empleable-test.qaleon.work/') {
          document.head.appendChild(document.createElement('script')).src = 'https://www.googletagmanager.com/gtag/js?id=GTM-W87VZFC';
          document.head.appendChild(document.createElement('script')).innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'GTM-W87VZFC');
          `;
        }
      });
      this.confirmCookies();
    },
    customCookies (payload) {
      payload.forEach(cookie => {
        if (cookie.accept) {
          if (!process.env.VUE_APP_URL === 'https://app-empleable-test.qaleon.work/') {
            document.head.appendChild(document.createElement('script')).src = 'https://www.googletagmanager.com/gtag/js?id=GTM-W87VZFC';
            document.head.appendChild(document.createElement('script')).innerHTML = `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'GTM-W87VZFC');
            `;
          }
        }
      });
      this.confirmCookies();
    },
    confirmCookies () {
      Cookies.set('cookies_accepted', 1, { expires: 365 });
      this.cookiesAccepted = Cookies.get('cookies_accepted');
    }
  },
  computed: {
    ...mapGetters({ loading: 'application/loading' }),
    notificationWidth () {
      return this.$vuetify.breakpoint.smAndDown ? '60%' : '20%';
    }
  }
};
</script>

<style>
.container {
  height: 100%;
}
@media (-webkit-device-pixel-ratio: 1.25) {
:root {
zoom: 0.95;
}}
@media (-webkit-device-pixel-ratio: 1.5) {
:root {
zoom: 0.8;
}}
</style>
