import * as types from './types';

export default {
  [types.SET_AREAS_KNOWLEDGE] (state, payload) {
    state.areaKnowledge = payload;
  },
  [types.SET_TOTAL_AREAS_KNOWLEDGE] (state, payload) {
    state.totalAreaKnowledge = payload;
  },
  [types.SET_EMPLOYEES] (state, payload) {
    state.employees = payload;
  },
  [types.SET_EMPLOYEE] (state, payload) {
    state.employee = payload;
  },
  [types.SET_TOTAL_EMPLOYEES] (state, payload) {
    state.totalEmployees = payload;
  },
  [types.SET_BRANCH_OFFICE] (state, payload) {
    state.branchOffice = payload;
  },
  [types.SET_TOTAL_BRANCH_OFFICE] (state, payload) {
    state.totalBranchOffice = payload;
  },
  [types.SET_JOB_PROFILES] (state, payload) {
    state.jobProfiles = payload;
  },
  [types.SET_COURSE_DETAIL] (state, payload) {
    state.courseDetail = payload;
  },
  [types.SET_COUNTRIES] (state, payload) {
    state.countries = payload;
  },
  [types.GET_STATES] (state, payload) {
    state.states = payload.data;
  },
  [types.GET_LOCALITIES] (state, payload) {
    state.localities = payload.data;
  },
  [types.SET_JOB_POSITIONS] (state, payload) {
    state.jobPositions = payload;
  },
  [types.SET_TOTAL_JOB_PROFILES] (state, payload) {
    state.totalJobProfiles = payload;
  },
  [types.SET_JOB_POSITION] (state, payload) {
    state.jobPosition = payload;
  },
  [types.SET_JOB_EMPLOYEES] (state, payload) {
    state.jobEmployees = payload.data;
  },
  [types.SET_RECOMMENDED_JOB_EMPLOYEES] (state, payload) {
    state.recommendedJobEmployees = payload.data;
  },
  [types.SET_COMPANY] (state, payload) {
    state.company = payload;
  },
  [types.GET_MARKET_JOB_PROFILES] (state, payload) {
    state.marketJobProfiles = payload.data;
  },
  [types.GET_MARKET_SKILLS] (state, payload) {
    state.marketSkills = payload.data;
  },
  [types.SET_MANAGER_DATA] (state, payload) {
    state.managerData = payload.data;
  },
  [types.SET_COMPANY_DATA] (state, payload) {
    state.companyData = payload;
  },
  [types.GET_MARKET_SKILLS] (state, payload) {
    state.marketSkills = payload.data;
  },
  [types.GET_MARKET_SKILLS] (state, payload) {
    state.marketSkills = payload.data;
  },
  [types.SET_TEAM] (state, payload) {
    state.team = payload;
  },
  [types.GET_DEFAULT_ROL_PERMISSIONS] (state, payload) {
    state.defaultRolPermissions = payload.data;
  },
  [types.SET_COMPANY_ROL_PERMISSIONS] (state, payload) {
    state.companyRolePermissions = payload;
  },
  [types.SET_USER_ROL_PERMISSIONS] (state, payload) {
    state.userRolPermissions = payload;
  }
};
