export const SET_COURSES_EDUCATION_CENTER = 'SET_COURSES_EDUCATION_CENTER';
export const SET_TOTAL_COURSES_EDUCATION_CENTER =
  'SET_TOTAL_COURSES_EDUCATION_CENTER';
export const SET_COURSE_BY_ID = 'SET_COURSE_BY_ID';
export const SET_EDUCATION_CENTER_BY_ID = 'SET_EDUCATION_CENTER_BY_ID';
export const GET_COMPETENCES_DEMAND_BY_AREA_OF_KNOWLEDGE =
  'GET_COMPETENCES_DEMAND_BY_AREA_OF_KNOWLEDGE';
export const UPDATE_EDUCATION_CENTER = 'UPDATE_EDUCATION_CENTER';
export const SET_MARKET_JOB_PROFILES = 'SET_MARKET_JOB_PROFILES';
export const SET_MARKET_SKILLS = 'SET_MARKET_SKILLS';
export const GET_MARKET_HOMEPAGE_SKILLS = 'GET_MARKET_HOMEPAGE_SKILLS';
export const GET_NOTIFICATIONS_EDUCATION_CENTER =
  'GET_NOTIFICATIONS_EDUCATION_CENTER';
export const SET_DATA_DASHBOARD_EDUCATION_CENTER =
  'SET_DATA_DASHBOARD_EDUCATION_CENTER';
export const SET_LEAD_COURSES = 'SET_LEAD_COURSES';
export const SET_TOTAL_LEAD_COURSES = 'SET_TOTAL_LEAD_COURSES';
export const SET_MANAGER_CENTER_BY_ID = 'SET_MANAGER_CENTER_BY_ID';
export const UPDATE_MANAGER_CENTER = 'UPDATE_MANAGER_CENTER';
export const SET_LEADS_FAVORITES_COURSES = 'SET_LEADS_FAVORITES_COURSES';
export const SET_TOTAL_FAVORITES_COURSES = 'SET_TOTAL_FAVORITES_COURSES';
export const SET_LEADS_BY_JOB_PROFILE = 'SET_LEADS_BY_JOB_PROFILE';
export const SET_TOTAL_LEADS_BY_JOB_PROFILE = 'SET_TOTAL_LEADS_BY_JOB_PROFILE';
export const SET_LEADS_BY_CURRENT_JOB_PROFILE = 'SET_LEADS_BY_CURRENT_JOB_PROFILE';
export const SET_TOTAL_LEADS_BY_CURRENT_JOB_PROFILE = 'SET_TOTAL_LEADS_BY_CURRENT_JOB_PROFILE';
export const SET_HEADQUARTERS = 'SET_HEADQUARTERS';
export const SET_TOTAL_HEADQUARTERS = 'SET_TOTAL_HEADQUARTERS';
