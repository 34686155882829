export const GET_HARD_SKILLS = 'GET_HARD_SKILLS';
export const SET_UNVALIDATED_PROFILES = 'SET_UNVALIDATED_PROFILES';
export const SET_TOTAL_UNVALIDATED_PROFILES = 'SET_TOTAL_UNVALIDATED_PROFILES';
export const SET_VALIDATED_PROFILES = 'SET_VALIDATED_PROFILES';
export const SET_TOTAL_VALIDATED_PROFILES = 'SET_TOTAL_VALIDATED_PROFILES';
export const GET_DELETED_POFILES = 'GET_DELETED_POFILES';
export const RESTORE_PROFILE = 'RESTORE_PROFILE';
export const VALIDATE_PROFILE = 'VALIDATE_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const DELETE_UNVALIDATED_ROFILE = 'DELETE_UNVALIDATED_ROFILE';
export const CLEAR_PROFILES = 'CLEAR_PROFILES';
export const GET_NEW_SKILLS = 'GET_NEW_SKILLS';
export const CLEAR_NEW_SKILLS = 'CLEAR_NEW_SKILLS';
export const VALIDATE_NEW_SKILL = 'VALIDATE_NEW_SKILL';
export const REMOVE_NEW_SKILL = 'REMOVE_NEW_SKILL';
export const GET_STOP_WORDS = 'GET_STOP_WORDS';
export const REMOVE_STOP_WORD = 'REMOVE_STOP_WORD';
export const CLEAR_STOP_WORD = 'CLEAR_STOP_WORD';
export const GET_RANKING = 'GET_RANKING';
export const GET_SKILLS = 'GET_SKILLS';
export const CLEAN_SKILLS = 'CLEAN_SKILLS';
export const GET_FIELDS = 'GET_FIELDS';
export const GET_SKILLS_DB = 'GET_SKILLS_DB';
export const CLEAR_WORK_FIELDS = 'CLEAR_WORK_FIELDS';
export const GET_PROFILE_NAME = 'GET_PROFILE_NAME';
export const GET_SECTORS = 'GET_SECTORS';
export const CLEAR_PROFILE_NAME = 'CLEAR_PROFILE_NAME';
export const SET_STOP_WORDS = 'SET_STOP_WORDS';
export const SET_TOTAL_WORDS = 'SET_TOTAL_WORDS';
export const SET_SKILLS = 'SET_SKILLS';
export const SET_TOTAL_SKILLS = 'SET_TOTAL_SKILLS';
export const SET_VALIDATED_SKILLS = 'SET_VALIDATED_SKILLS';
export const SET_TOTAL_VALIDATED_SKILLS = 'SET_TOTAL_VALIDATED_SKILLS';
export const SET_MOST_DEMAND_PROFILES = 'SET_MOST_DEMAND_PROFILES';
export const SET_TOTAL_MOST_DEMAND_PROFILES = 'SET_TOTAL_MOST_DEMAND_PROFILES';
export const SET_VALIDATED_SKILLS_FILTERS = 'SET_VALIDATED_SKILLS_FILTERS';
/** new validator **/
export const SET_PROPOSED_JOB_PROFILES = 'SET_PROPOSED_JOB_PROFILES';
export const SET_TOTAL_PROPOSED_JOB_PROFILES = 'SET_TOTAL_PROPOSED_JOB_PROFILES';
export const SET_PROPOSED_JOB_PROFILE = 'SET_PROPOSED_JOB_PROFILE';
export const SET_JOB_PROFILES_SKILLS = 'SET_JOB_PROFILES_SKILLS';
export const SET_JOB_PROFILES_KNOWLEDGE = 'SET_JOB_PROFILES_KNOWLEDGE';
export const SET_TOTAL_JOB_PROFILES_SKILLS = 'SET_TOTAL_JOB_PROFILES_SKILLS';
export const SET_TOTAL_JOB_PROFILES_KNOWLEDGE = 'SET_TOTAL_JOB_PROFILES_KNOWLEDGE';
export const SET_NOT_VALIDATED_JOB_PROFILES = 'SET_NOT_VALIDATED_JOB_PROFILES';
export const SET_TOTAL_NOT_VALIDATED_JOB_PROFILES = 'SET_TOTAL_NOT_VALIDATED_JOB_PROFILES';
