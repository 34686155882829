/* Verificar realmente que funciona */

import config from '@/config/config.js';
import ApiService from '@/services';

export const ValidatorService = {
  getHardSkills (payload) {
    return ApiService.query(
      `${config.CORE_ENDPOINT}proposed_skill`,
      {
        validated_skill_contains: payload.search,
        limit: payload?.params?.limit ?? 10,
        offset: 0
      }
    );
  },
  getValidatedMostDemandProfiles (payload) {
    return ApiService.query(
      `${config.DATA_PROXY_ENDPOINT}${config.VALIDATION_ENDPOINT}ranking_profiles`,
      {
        limit: payload.limit,
        offset: payload.offset
      }
    );
  },
  fetchProposedProfiles (payload) {
    return ApiService.query(
      `${config.CORE_ENDPOINT}proposed-profile`,
      {
        workfield: payload.workfield ?? null,
        proposed_profile: payload.proposed_profile ?? null,
        status: payload.status ?? null,
        limit: payload.limit ?? null,
        offset: payload.offset ?? null
      }
    );
  },
  getDeletedProfiles (payload) {
    return ApiService.query(
      `${config.DATA_PROXY_ENDPOINT}${config.VALIDATION_ENDPOINT}proposed_profile/deleted`,
      {
        limit: payload.limit,
        offset: payload.offset,
        recent_first: payload.recent_first
      }
    );
  },
  getValidatedProfiles (payload) {
    return ApiService.query(
      `${config.CORE_ENDPOINT}job-profile`, {
        name: payload.name ?? null,
        work_field: payload.work_field ?? null,
        status: payload.status ?? null,
        limit: payload.limit ?? 10,
        offset: payload.offset ?? 0
      }
    );
  },
  validateProfile (payload) {
    return ApiService.post(
      `${config.CORE_ENDPOINT}job-profile`,
      {
        id: payload.id,
        name: payload.name,
        hard_skills: payload.hardSkills,
        soft_skills: payload.softSkills,
        work_field: [payload.workfield],
        description: payload.description
      }
    );
  },
  editProfile (payload) {
    return ApiService.put(
      `${config.CORE_ENDPOINT}job-profile/${payload.id}`,
      payload
    );
  },
  deleteProfile (profileId) {
    return ApiService.delete(
      `${config.CORE_ENDPOINT}job-profile/${profileId}`
    );
  },
  restoreProfile (profileId) {
    return ApiService.post(
      `${config.DATA_PROXY_ENDPOINT}${config.VALIDATION_ENDPOINT}restore_proposed_profile/${profileId}`
    );
  },

  getNewSkills (payload) {
    return ApiService.query(
      `${config.DATA_PROXY_ENDPOINT}${config.VALIDATION_ENDPOINT}new_skill`,
      {
        limit: payload.limit,
        offset: payload.offset,
        filter_work_field: payload.work_fields
      }
    );
  },
  validateNewSkill (payload) {
    return ApiService.post(
      `${config.DATA_PROXY_ENDPOINT}${config.VALIDATION_ENDPOINT}new_skill`,
      {
        id: payload.id,
        name: payload.name,
        profile_id: payload.profile_id
      }
    );
  },
  removeNewSkill (newSkillId) {
    return ApiService.delete(
      `${config.DATA_PROXY_ENDPOINT}${config.VALIDATION_ENDPOINT}new_skill`,
      newSkillId
    );
  },
  getStopWords (payload) {
    return ApiService.query(
      `${config.CORE_ENDPOINT}stopword`,
      {
        limit: payload.limit,
        offset: payload.offset,
        ascending: payload.ascending
      }
    );
  },
  getStopWordsByName (payload) {
    return ApiService.query(
      `${config.DATA_PROXY_ENDPOINT}${config.VALIDATION_ENDPOINT}stopword`,
      {
        name: payload.name,
        limit: payload.limit,
        offset: payload.offset,
        ascending: payload.ascending
      }
    );
  },
  createStopWord (payload) {
    return ApiService.post(
      `${config.DATA_PROXY_ENDPOINT}${config.VALIDATION_ENDPOINT}stopword`,
      {
        name: payload.name
      }
    );
  },
  removeStopWord (stopWordId) {
    return ApiService.delete(
      `${config.DATA_PROXY_ENDPOINT}${config.VALIDATION_ENDPOINT}stopword`,
      stopWordId
    );
  },
  getRankingProfiles (filters) {
    return ApiService.getFiltered(
      `${config.DATA_PROXY_ENDPOINT}${config.VALIDATION_ENDPOINT}ranking_profiles`,
      filters
    );
  },
  deleteProposedProfile (profileId) {
    return ApiService.delete(
      `${config.CORE_ENDPOINT}proposed-profile/${profileId}`
    );
  },
  fetchSkills (payload) {
    return ApiService.query(
      `${config.CORE_ENDPOINT}proposed-skill/`,
      {
        proposed_profile: payload.proposed_profile ?? null,
        name: payload.name ?? null,
        status: payload.status ?? null,
        limit: payload.limit ?? 10,
        offset: payload.offset ?? 0
      }
    );
  },
  getValidatedSkills (payload) {
    return ApiService.query(
      `${config.DATA_PROXY_ENDPOINT}${config.VALIDATION_ENDPOINT}knowledge/skill`,
      {
        limit: payload.limit,
        offset: payload.offset
      }
    );
  },
  getValidatedSkillsByName (payload) {
    return ApiService.query(
      `${config.DATA_PROXY_ENDPOINT}${config.VALIDATION_ENDPOINT}knowledge/skill`,
      {
        ...payload
      }
    );
  },
  getSkillsData (skillId) {
    return ApiService.query(
      `${config.DATA_PROXY_ENDPOINT}${config.MATCHING_ENDPOINT}job_profiles_by_skills`,
      {
        id: skillId,
        limit: 10,
        offset: 0
      }
    );
  },
  UpdateValidatedSkillData (payload) {
    if (payload.name) {
      return ApiService.put(
        `${config.DATA_PROXY_ENDPOINT}${config.VALIDATION_ENDPOINT}skill/validated/?skill_id=${payload.id}&is_validated=${payload.validation}&skill_type=${payload.skill_type}&name=${payload.name}&`
      );
    } else {
      return ApiService.put(
        `${config.DATA_PROXY_ENDPOINT}${config.VALIDATION_ENDPOINT}skill/validated/?skill_id=${payload.id}&is_validated=${payload.validation}&skill_type=${payload.skill_type}&`
      );
    }
  },
  getProfileSkills (payload) {
    return ApiService.query(`${config.DATA_PROXY_ENDPOINT}${config.MATCHING_ENDPOINT}job_profile_skills`, {
      job_profile_id: payload.id,
      limit: 10,
      offset: payload.offset
    });
  },
  deleteValidatedSkill (skillId) {
    return ApiService.delete(`${config.DATA_PROXY_ENDPOINT}${config.MATCHING_ENDPOINT}delete_skill/${skillId}`
    );
  },
  fetchValidatorProposedProfiles (payload) {
    return ApiService.query('validator/validator_proposed_profile',
      {
        search: payload.name ?? null,
        limit: payload.limit ?? 10,
        status: payload.status?.type && payload.status?.type !== -1 ? payload.status?.name : null,
        sector: payload.sector?.id && payload.sector.id !== -1 ? payload.sector.name : null,
        offset: payload.offset ?? 0
      });
  },
  fetchProposedJobProfile (jobProfileID) {
    return ApiService.get(`${config.VALIDATOR_ENDPOINT}validator_proposed_profile/${jobProfileID}`);
  },
  fetchNotValidatedJobProfiles (payload) {
    return ApiService.query('validator/validator_proposed_profile',
      {
        search: payload.name ?? null,
        limit: payload.limit ?? 10,
        status: 'No validado',
        offset: payload.offset ?? 0
      });
  },
  validateProposedProfile (payload) {
    return ApiService.put(`${config.VALIDATOR_ENDPOINT}validator_proposed_profile/${payload.id}`, payload.jobProfile);
  },
  postJobProfile (payload) {
    return ApiService.post(`${config.VALIDATOR_ENDPOINT}validator_proposed_profile`, payload);
  }
};
export default ValidatorService;
