// Mutation Types
import * as types from './types';
// Services
import IndividualService from '@/services/IndividualService.js';
import { HARD_SKILL } from '@/services/Constants';

export default {
  async registerIndividual ({ commit }, payload) {
    try {
      const response = await IndividualService.registerIndividual(payload);
      commit(types.REGISTER_INDIVIDUAL, response);
    } catch (e) {
      commit(types.REGISTER_INDIVIDUAL, {});
      throw e;
    }
  },
  async getIndividualById ({ commit }, payload) {
    try {
      const response = await IndividualService.getIndividualById(payload);
      commit(types.SET_INDIVIDUAL_BY_ID, response.data);
    } catch (e) {
      commit(types.SET_INDIVIDUAL_BY_ID, {});
    }
  },
  async updateIndividual ({ commit }, payload) {
    const response = await IndividualService.updateIndividual(payload);
    commit(types.UPDATE_INDIVIDUAL, response);
  },
  async updatePreferredJobProfile ({ commit }, payload) {
    const response = await IndividualService.updatePreferredJobProfile(payload);
    commit(types.UPDATE_INDIVIDUAL, response);
  },
  async updateAvatar ({ commit }, src) {
    try {
      commit(types.UPDATE_AVATAR, src);
    } catch (e) {
      commit(types.UPDATE_AVATAR, {});
      throw e;
    }
  },
  async fetchProfessionalCertificate ({ commit }, payload) {
    try {
      const response = await IndividualService.fetchProfessionalCertificate(payload);
      commit(types.SET_PROFESSIONAL_CERTIFICATE, response.data.results);
      return response;
    } catch (e) {
      commit(types.SET_PROFESSIONAL_CERTIFICATE, []);
    }
  },
  async fetchFavoriteCourses ({ commit }, payload) {
    try {
      const response = await IndividualService.fetchFavoriteCourses(payload);
      commit(types.SET_FAVORITE_COURSES, response.data.results);
      commit(types.SET_TOTAL_FAVORITE_COURSES, response.data.count);
    } catch (e) {
      commit(types.SET_FAVORITE_COURSES, []);
      commit(types.SET_TOTAL_FAVORITE_COURSES, 0);
    }
  },
  async postFavoriteCourses (_, payload) {
    try {
      await IndividualService.postFavoriteCourses(payload);
    } catch (e) {
      throw e.response.data.message;
    }
  },
  async deleteFavoriteCourses (_, payload) {
    try {
      await IndividualService.deleteFavoriteCourses(payload);
    } catch (e) {
      throw e.response.data.message;
    }
  },
  async fetchMostDemandCourses ({ commit }) {
    try {
      const response = await IndividualService.fetchMostDemandCourses();
      commit(types.SET_MOST_DEMAND_COURSES, response.data.results);
    } catch (e) {
      commit(types.SET_MOST_DEMAND_COURSES, []);
    }
  },
  async fetchMostDemandJobProfiles ({ commit }) {
    try {
      const response = await IndividualService.fetchMostDemandJobProfiles();
      commit(types.SET_MOST_DEMAND_JOB_PROFILE, response.data.results);
    } catch (e) {
      commit(types.SET_MOST_DEMAND_JOB_PROFILE, []);
    }
  },
  async fetchRecommendedJobProfiles ({ commit }, payload) {
    try {
      const response = await IndividualService.fetchRecommendedJobProfiles(payload);
      commit(types.SET_RECOMMENDED_JOB_PROFILES, response.data.results);
      commit(types.SET_TOTAL_RECOMMENDED_JOB_PROFILES, response.data.count);
    } catch (e) {
      commit(types.SET_RECOMMENDED_JOB_PROFILES, []);
      commit(types.SET_TOTAL_RECOMMENDED_JOB_PROFILES, 0);
    }
  },
  async fetchCoursesRecommends ({ commit }, payload) {
    try {
      const response = await IndividualService.fetchCoursesRecommends(payload);
      commit(types.SET_RECOMMENDED_COURSES, response.data.results);
      commit(types.SET_TOTAL_RECOMMENDED_COURSES, response.data.count);
    } catch (e) {
      commit(types.SET_RECOMMENDED_COURSES, []);
      commit(types.SET_TOTAL_RECOMMENDED_COURSES, 0);
    }
  },
  async fetchCoursesRecommendsFilterSkill ({ commit }, payload) {
    try {
      const response = await IndividualService.fetchCoursesRecommendsFilterSkill(
        payload
      );
      commit(types.SET_RECOMMENDED_COURSES, response.data.results);
    } catch (e) {
      commit(types.SET_RECOMMENDED_COURSES, []);
    }
  },
  async fetchCourseDetail ({ commit }, payload) {
    try {
      const response = await IndividualService.fetchCourseDetail(payload);
      commit(types.SET_COURSE_DETAIL, response.data);
    } catch (e) {
      commit(types.SET_COURSE_DETAIL, {});
    }
  },
  async fetchJobProfileDetail ({ commit }, payload) {
    try {
      const response = await IndividualService.fetchJobProfileDetail(payload);
      commit(types.SET_JOB_PROFILE_DETAIL, response.data);
    } catch (e) {
      commit(types.SET_JOB_PROFILE_DETAIL, {});
    }
  },
  async savedAvatar (_, payload) {
    try {
      const res = await IndividualService.savedAvatar(payload);
      return res;
    } catch (e) {
    }
  },
  async postChosenCourse (_, payload) {
    try {
      await IndividualService.postChosenCourse(payload);
    } catch (e) {
    }
  },
  async fetchReportHardSkill ({ commit }, individualId) {
    try {
      const response = await IndividualService.fetchReportHardSkill(individualId);
      commit(types.SET_MATCH_HARD_SKILLS, response.data);
    } catch (e) {
      commit(types.SET_MATCH_HARD_SKILLS, []);
    }
  },
  async fetchSkills ({ commit }, payload) {
    try {
      const response = await IndividualService.fetchSkills(payload);
      if (payload.type_skill === HARD_SKILL) {
        commit(types.SET_HARD_SKILLS, response.data.results);
      } else {
        commit(types.SET_SOFT_SKILLS, response.data.results);
      }
    } catch (e) {
    }
  },
  async fetchWorkField ({ commit }, workFieldId) {
    try {
      const response = await IndividualService.fetchWorkField(workFieldId);
      commit(types.SET_WORK_FIELDS, response.data.results);
    } catch (e) {
      commit(types.SET_WORK_FIELDS, []);
    }
  },
  async softSkill_test ({ commit }, id) {
    try {
      return await IndividualService.softSkill_test(id);
    } catch (e) {
    }
  },
  async registerSkillsVideo ({ commit }, payload) {
    try {
      return await IndividualService.registerSoftSkillVideo(payload.employee);
    } catch (e) {
    }
    try {
      const response = await IndividualService.fetchReportHardSkill(payload);
      commit(types.SET_MATCH_HARD_SKILLS, response.data.data);
    } catch (e) {
      commit(types.SET_MATCH_HARD_SKILLS, []);
    }
  },
  async postSoftSkillsQuestions ({ commit }, payload) {
    try {
      const response = await IndividualService.postSoftSkillsQuestions(payload);
      commit(types.SET_SOFT_SKILLS_REPORT, response.data.data);
    } catch (e) {
      commit(types.SET_SOFT_SKILLS_REPORT, []);
      throw e;
    }
  },
  async saveSoftSkillDetection ({ commit }, payload) {
    const response = await IndividualService.saveSoftSkillDetection(payload);
    if (response.data.data) commit(types.SET_SOFT_SKILLS_REPORT, response.data.data);
    return response;
  },
  async fetchReportSoftSkill ({ commit }, individualId) {
    try {
      const response = await IndividualService.fetchReportSoftSkill(individualId);
      commit(types.SET_SOFT_SKILLS_REPORT, response.data);
      return response;
    } catch (e) {
      commit(types.SET_SOFT_SKILLS_REPORT, []);
      throw e;
    }
  },
  async deleteIndividual ({ commit }, payload) {
    return await IndividualService.deleteIndividual(payload);
  },
  deleteIndividualAccount ({ commit }, payload) {
    return IndividualService.deleteIndividualAccount(payload);
  },
  async fetchJobProfiles ({ commit }, payload) {
    try {
      const response = await IndividualService.fetchJobProfiles(payload);
      commit(types.SET_JOB_PROFILES, response.data.results);
    } catch (e) {
      commit(types.SET_JOB_PROFILES, []);
    }
  },
  async transferIndividualData ({ commit }, payload) {
    try {
      const response = await IndividualService.transferIndividualData(payload);
      commit(types.SET_INDIVIDUAL_BY_ID, response.data);
    } catch (e) {
      commit(types.SET_INDIVIDUAL_BY_ID, {});
    }
  }
};
