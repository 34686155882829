// Mutation Types
import * as types from './types';

export default {
  [types.SET_INDIVIDUAL_PRODUCTS] (state, payload) {
    state.individualProducts = payload;
  },
  [types.PRODUCTS_EDUCATION_CENTER] (state, payload) {
    state.productsEducationCenter = payload;
  },
  [types.SET_PRODUCTS_MANAGER] (state, payload) {
    state.productsManager = payload;
  },
  [types.CHECKOUT_STATUS] (state, payload) {
    state.checkoutStatus = payload;
  },
  [types.CHECKOUT_URL] (state, payload) {
    state.checkoutUrl = payload.SessionURL;
  }
};
