import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import i18n from '@/i18n';
Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    /* All Users */
    {
      path: '/',
      component: () =>
        import('@/components/navigationBar/NavigationBarHomePage.vue'),
      meta: {
        rule: 'isPublic'
      },
      children: [
        {
          path: '/register',
          name: 'IndividualRegister',
          component: () => import('@/pages/individual/IndividualRegisterPage.vue'),
          meta: {
            rule: 'isPreCandidate'
          }
        },
        {
          path: '/center/subscription',
          name: 'EducationCentreRegister',
          component: () =>
            import('@/views/educationCenter/EducationCenterSubscriptionPlan'),
          meta: {
            rule: 'isPreCandidate'
          }
        },
        {
          path: '/company/subscription',
          name: 'CompanyRegister',
          component: () => import('@/views/manager/ManagerPlanProcess'),
          meta: {
            rule: 'isPreCandidate'
          }
        },
        {
          path: '/privacy-policy',
          name: 'PrivacyPolicy',
          component: () => import('@/views/application/PrivacyPolicy.vue'),
          meta: {
            rule: 'isRegistering'
          }
        },
        {
          path: '/',
          name: 'Homepage',
          component: () => import('@/pages/application/HomePage.vue'),
          meta: {
            rule: 'isPublic',
            type: 'general'
          }
        },
        {
          path: 'auth/outlook/',
          name: 'HomepageAuthOutlook',
          component: () => import('@/pages/application/HomePage.vue'),
          meta: {
            rule: 'isPublic',
            type: 'general'
          }
        },
        {
          path: 'auth/linkedin/',
          name: 'HomepageAuthLinkedin',
          component: () => import('@/pages/application/HomePage.vue'),
          meta: {
            rule: 'isPublic',
            type: 'general'
          }
        },
        {
          path: '/login/individual',
          name: 'HomePageIndividual',
          component: () => import('@/pages/individual/HomePageIndividual.vue'),
          meta: {
            rule: 'isPublic',
            type: 'individual'
          }
        },
        {
          path: '/login/individual/prices',
          name: 'IndividualPrices',
          component: () => import('@/views/individual/IndividualPrices.vue'),
          meta: {
            rule: 'isPublic',
            type: 'individual'
          }
        },
        {
          path: '/login/center',
          name: 'HomePageEducationCenter',
          component: () =>
            import('@/pages/educationCenter/HomePageEducationCenter.vue'),
          meta: {
            rule: 'isPublic',
            type: 'center'
          }
        },
        {
          path: '/login/center/prices',
          name: 'EducationCenterPrices',
          component: () =>
            import('@/views/educationCenter/EducationCenterPrices.vue'),
          meta: {
            rule: 'isPublic',
            type: 'center'
          }
        },
        {
          path: '/login/company',
          name: 'HomeManager',
          component: () => import('@/views/manager/HomeManager'),
          meta: {
            rule: 'isPublic',
            type: 'manager'
          }
        },
        {
          path: '/login/company/prices',
          name: 'ManagerPrices',
          component: () => import('@/views/manager/ManagerPrices'),
          meta: {
            rule: 'isPublic'
          }
        },
        {
          path: '/manager-contact',
          name: 'ManagerContactUs',
          component: () => import('@/views/manager/ContactUs'),
          meta: {
            rule: 'isPublic'
          }
        }
      ]
    },
    /* All Users */
    /* Individual */
    {
      path: '/',
      component: () =>
        import('@/components/navigationBar/NavigationBarIndividual.vue'),
      meta: {
        rule: 'isUser'
      },
      children: [
        {
          path: '/individual',
          name: 'IndividualDashboard',
          component: () => import('@/views/individual/IndividualDashboard.vue'),
          meta: {
            rule: 'isCandidate'
          }
        },
        {
          path: '/individual/courses',
          name: 'IndividualCourses',
          component: () => import('@/views/individual/IndividualCourses.vue'),
          meta: {
            rule: 'isCandidate',
            breadcrumb: [
              {
                name: i18n.t('profile.courses_for_you')
              }
            ]
          }
        },
        {
          path: '/individual/profiles',
          name: 'IndividualJobProfiles',
          component: () =>
            import('@/views/individual/IndividualJobProfiles.vue'),
          meta: {
            rule: 'isCandidate',
            breadcrumb: [
              {
                name: i18n.t('profile.profiles_recomends')
              }
            ]
          }
        },
        {
          path: '/individual/course-details/:courseId',
          name: 'IndividualCourseDetails',
          props: true,
          component: () =>
            import('@/views/individual/IndividualCourseDetails.vue'),
          meta: {
            rule: 'isCandidate',
            breadcrumb: [
              {
                name: i18n.t('profile.courses_for_you'),
                link: { name: 'IndividualCourses' },
                color: 'white'
              },
              {
                name: i18n.t('course.details'),
                color: 'white'
              }
            ]
          }
        },
        {
          path: '/individual/job-profile-details/:idJobProfile/:idWorkfield',
          name: 'IndividualJobProfileDetail',
          component: () =>
            import('@/views/individual/IndividualJobProfileDetail.vue'),
          meta: {
            rule: 'isCandidate',
            breadcrumb: [
              {
                name: i18n.t('profile.profiles_recomends'),
                link: { name: 'IndividualJobProfilesPage' },
                color: 'white'
              },
              {
                name: i18n.t('profile.job_profile_details'),
                color: 'white'
              }
            ]
          }
        },
        {
          path: '/individual/profile-edit',
          name: 'IndividualEditProfile',
          component: () =>
            import('@/views/individual/IndividualEditProfile.vue'),
          meta: {
            rule: 'isCandidate'
          }
        },
        {
          path: '/individual/favorites',
          name: 'IndividualFavoriteCourses',
          component: () =>
            import('@/views/individual/IndividualFavoriteCourses.vue'),
          meta: {
            rule: 'isCandidate'
          }
        },
        {
          path: '/individual/badges-certificates',
          name: 'IndividualBadgesAndCertificates',
          component: () =>
            import('@/views/individual/IndividualBadgesAndCertificates.vue'),
          meta: {
            rule: 'isCandidate'
          }
        },
        {
          path: '/individual/hard-skills',
          name: 'IndividualHardSkills',
          component: () =>
            import('@/views/individual/IndividualHardSkills.vue'),
          meta: {
            rule: 'isCandidate',
            breadcrumb: [
              {
                text: i18n.t('navbar.hard_skills'),
                color: 'grey'
              }
            ]
          }
        },
        {
          path: '/individual/compare-your-hard-skills',
          name: 'IndividualHardSkillsForJobProfile',
          component: () =>
            import('@/views/individual/IndividualHardSkillsForJobProfile.vue'),
          meta: {
            rule: 'isCandidate',
            breadcrumb: [
              {
                text: i18n.t('navbar.hard_skills'),
                color: 'grey'
              }
            ]
          }
        },
        {
          path: '/individual/subscription-plans',
          name: 'IndividualChangePlan',
          component: () => import('@/views/individual/IndividualPrices.vue'),
          meta: {
            rule: 'isCandidate'
          }
        },
        {
          path: '/individual/cancel-subscription',
          name: 'IndividualCancelMySubscriptionPlan',
          component: () =>
            import('@/views/individual/IndividualCancelMySubscriptionPlan.vue'),
          meta: {
            rule: 'isCandidate'
          }
        },
        {
          path: '/individual/prices',
          name: 'IndividualMySubscription',
          component: () => import('@/views/individual/IndividualMySubscription.vue'),
          meta: {
            rule: 'isCandidate'
          }
        },
        {
          path: '/individual/soft-skills',
          name: 'IndividualSoftSkills',
          component: () =>
            import('@/views/individual/IndividualSoftSkills.vue'),
          meta: {
            rule: 'isCandidate',
            breadcrumb: [
              {
                text: i18n.t('navbar.soft_skills'),
                color: 'grey'
              }
            ]
          }
        },
        {
          path: '/individual/compare-your-soft-skills',
          name: 'IndividualSoftSkillsForJobProfile',
          component: () =>
            import('@/views/individual/IndividualSoftSkillsForJobProfile.vue'),
          meta: {
            rule: 'isCandidate',
            breadcrumb: [
              {
                text: i18n.t('navbar.soft_skills'),
                color: 'grey'
              }
            ]
          }
        },
        {
          path: '/individual/faqs',
          name: 'IndividualFaqs',
          component: () => import('@/views/individual/IndividualFaqs.vue'),
          meta: {
            rule: 'isCandidate'
          }
        },
        {
          path: '/individual/privacy-policy',
          name: 'IndividualPrivacyPolicy',
          component: () => import('@/views/application/PrivacyPolicy.vue'),
          meta: {
            rule: 'isCandidate'
          }
        },
        {
          path: '/individual/technical-support',
          name: 'IndividualTechnicalSupport',
          component: () =>
            import('@/views/individual/IndividualTechnicalSupport.vue'),
          meta: {
            rule: 'isCandidate'
          }
        }
      ]
    },
    {
      path: '/register/checkout-purchase-fail',
      name: 'CheckoutPurchaseFail',
      component: () => import('@/pages/application/CheckoutFail.vue'),
      meta: {
        rule: 'isUser'
      }
    },
    /* Individual */
    /* education Center */
    {
      path: '/',
      component: () =>
        import('@/components/navigationBar/NavigationBarEducationCenter.vue'),
      meta: {
        rule: 'isPublic'
      },
      children: [
        {
          path: '/center',
          name: 'EducationCenterDashboard',
          component: () =>
            import('@/views/educationCenter/EducationCenterDashboard.vue'),
          meta: {
            rule: 'isTrainner'
          }
        },
        {
          path: '/center-success',
          name: 'EducationCenterDashboardSuccess',
          component: () =>
            import('@/views/educationCenter/EducationCenterDashboard.vue'),
          meta: {
            rule: 'isTrainner'
          }
        },
        {
          path: '/favorites-saved-courses',
          name: 'EducationCenterFavoritesCoursesSaved',
          component: () =>
            import(
              '@/views/educationCenter/EducationCenterCoursesSavedByUser.vue'
            ),
          meta: {
            rule: 'isTrainner'
          }
        },
        {
          path: '/leads-by-courses',
          name: 'EducationCenterLeadsByCourses',
          component: () =>
            import(
              '@/views/educationCenter/EducationCenterLeadsByCourses.vue'
            ),
          meta: {
            rule: 'isTrainner'
          }
        },
        {
          path: '/center/courses/:headquarterId?',
          name: 'EducationCenterCourses',
          component: () =>
            import('@/views/educationCenter/EducationCenterCourses.vue'),
          meta: {
            rule: 'isTrainner'
          }
        },
        {
          path: '/center/course-detail/:courseId',
          name: 'EducationCenterCourseDetail',
          component: () =>
            import('@/views/educationCenter/EducationCenterCourseDetail.vue'),
          meta: {
            rule: 'isTrainner'
          }
        },
        {
          path: '/center/edit-course/:courseId',
          name: 'EducationCenterEditCourse',
          props: true,
          component: () =>
            import('@/views/educationCenter/EducationCenterEditCourse.vue'),
          meta: {
            rule: 'isTrainner',
            breadcrumb: [
              {
                name: i18n.t('center.edit_course')
              }
            ]
          }
        },
        {
          path: '/center/upload-individual-courses',
          name: 'EducationCenterUploadIndividualCourses',
          component: () =>
            import(
              '@/views/educationCenter/EducationCenterUploadIndividualCourses.vue'
            ),
          meta: {
            rule: 'isTrainner',
            breadcrumb: [
              {
                name: i18n.t('center.manage_courses')
              }
            ]
          }
        },
        {
          path: '/center/upload-multiple-courses',
          name: 'EducationCenterUploadMultipleCourses',
          component: () =>
            import(
              '@/views/educationCenter/EducationCenterUploadMultipleCourses.vue'
            ),
          meta: {
            rule: 'isTrainner',
            breadcrumb: [
              {
                name: i18n.t('center.manage_courses')
              }
            ]
          }
        },
        {
          path: '/center/profile-edit',
          name: 'EducationCenterEditProfile',
          component: () =>
            import('@/views/educationCenter/EducationCenterEditProfile.vue'),
          meta: {
            rule: 'isTrainner',
            breadcrumb: []
          }
        },
        {
          path: '/center/trends',
          name: 'EducationCenterTrends',
          component: () =>
            import('@/views/educationCenter/EducationCenterTrends.vue'),
          meta: {
            rule: 'isTrainner'
          }
        },
        {
          path: '/center/faqs',
          name: 'EducationCenterFaqs',
          component: () =>
            import('@/views/educationCenter/EducationCenterFaqs'),
          meta: {
            rule: 'isTrainner'
          }
        },
        {
          path: '/center/technical-support',
          name: 'EducationCenterTechnicalSupport',
          component: () =>
            import('@/views/educationCenter/EducationCenterTechnicalSupport.vue'),
          meta: {
            rule: 'isTrainner'
          }
        },
        {
          path: '/center/prices',
          name: 'EducationCenterMySubscriptionPlan',
          component: () =>
            import('@/views/educationCenter/EducationCenterMySubscriptionPlan.vue'),
          meta: {
            rule: 'isTrainner'
          }
        },
        {
          path: '/center/cancel-subscription',
          name: 'EducationCenterCancelMySubscriptionPlan',
          component: () =>
            import('@/views/educationCenter/EducationCenterCancelMySubscriptionPlan.vue'),
          meta: {
            rule: 'isTrainner'
          }
        },
        {
          path: '/center/subscription-plans',
          name: 'EducationCenterSubscriptionPlan',
          component: () =>
            import('@/views/educationCenter/EducationCenterPrices.vue'),
          meta: {
            rule: 'isTrainner'
          }
        },
        {
          path: '/center/privacy-policy',
          name: 'centerPrivacyPolicy',
          component: () => import('@/views/application/PrivacyPolicy.vue'),
          meta: {
            rule: 'isTrainner'
          }
        },
        {
          path: '/center/training-centers',
          name: 'EducationCenterTrainingCenters',
          component: () => import('@/views/educationCenter/EducationCenterTrainingCenters.vue'),
          meta: {
            rule: 'isTrainner'
          }
        }
      ]
    },
    /* Manager */
    {
      path: '/',
      component: () =>
        import('@/components/navigationBar/NavigationBarManager'),
      meta: {
        rule: 'isUser'
      },
      children: [
        {
          path: '/manager-dashboard',
          name: 'ManagerDashboard',
          component: () => import('@/views/manager/ManagerDashboard'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-dashboard-success',
          name: 'ManagerDashboardSuccess',
          component: () => import('@/views/manager/ManagerDashboard'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-employees',
          name: 'ManagerEmployees',
          component: () => import('@/views/manager/ManagerEmployees'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-employees/:office',
          name: 'ManagerEmployeesByOffice',
          component: () => import('@/views/manager/ManagerEmployees'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-employee/:id/job-info/:jobId',
          name: 'ManagerEmployeeJob',
          component: () => import('@/views/manager/ManagerEmployeeJobInfo'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-employee/:id/job-info/:jobId/view-proposal',
          name: 'ManagerEmployeeProposal',
          component: () => import('@/views/manager/ManagerEmployeeViewProposal'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-employee/:id',
          name: 'ManagerEmployeeData',
          component: () => import('@/views/manager/ManagerEmployee'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/add-employee',
          name: 'ManagerAddEmployee',
          component: () => import('@/views/manager/ManagerAddEmployee'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/edit-employee/:id',
          name: 'ManagerAddEmployeeDetail',
          component: () => import('@/views/manager/ManagerAddEmployee'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-trends',
          name: 'ManagerTrends',
          component: () => import('@/views/manager/ManagerTrends'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-profile',
          name: 'ManagerProfile',
          component: () => import('@/views/manager/ManagerProfileOptions'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-company-profile',
          name: 'ManagerCompanyProfile',
          component: () => import('@/views/manager/ManagerProfileOptions'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-faqs',
          name: 'ManagerFaqs',
          component: () => import('@/views/manager/ManagerProfileOptions'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-stored-reports',
          name: 'ManagerStoredReports',
          component: () => import('@/views/manager/ManagerProfileOptions'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-permissions-users',
          name: 'ManagerPermissionsUserRolPage',
          component: () => import('@/views/manager/ManagerProfileOptions'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-rol-users',
          name: 'ManagerRolUsersPage',
          component: () => import('@/views/manager/ManagerProfileOptions'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-deleted-employees',
          name: 'ManagerDeletedEmployeesPage',
          component: () => import('@/views/manager/ManagerProfileOptions'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-deleted-job-profiles',
          name: 'ManagerDeletedJobProfilesPage',
          component: () => import('@/views/manager/ManagerProfileOptions'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-subscriptions-plan',
          name: 'ManagerSubscriptionsPlanPage',
          component: () => import('@/views/manager/ManagerProfileOptions'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-work-centers',
          name: 'ManagerWorkCenters',
          component: () => import('@/views/manager/ManagerProfileOptions'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-departments',
          name: 'ManagerDepartments',
          component: () => import('@/views/manager/ManagerProfileOptions'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-my-subscription-plan',
          name: 'ManagerMySubscriptionPage',
          component: () => import('@/views/manager/ManagerProfileOptions'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-cancel-subscription',
          name: 'ManagerCancelMySubscriptionPage',
          component: () => import('@/views/manager/ManagerProfileOptions'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-options',
          name: 'ManagerOptions',
          component: () => import('@/views/manager/ManagerProfileOptions'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-job-positions',
          name: 'ManagerJobPositions',
          component: () => import('@/views/manager/ManagerJobPositions'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-job-positions/:departmentId',
          name: 'ManagerJobPositionsByDepartment',
          component: () => import('@/views/manager/ManagerJobPositions'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-add-job-position',
          name: 'ManagerAddJobPosition',
          component: () => import('@/views/manager/ManagerAddJobPosition'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/edit-job/:id',
          name: 'ManagerEditJobPosition',
          component: () => import('@/views/manager/ManagerAddJobPosition'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-soft-skills',
          name: 'ManagerSoftSkills',
          component: () => import('@/views/manager/ManagerSoftSkills'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-teams',
          name: 'ManagerWorkTeams',
          component: () => import('@/views/manager/ManagerWorkTeams'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/add-team',
          name: 'ManagerAddWorkTeam',
          component: () => import('@/views/manager/ManagerAddWorkTeam'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/add-team/:id',
          name: 'ManagerAddWorkTeamUpdate',
          component: () => import('@/views/manager/ManagerAddWorkTeam'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/proposal/work-team/:id',
          name: 'ManagerProposalWorkTeam',
          component: () => import('@/views/manager/ManagerProposalWorkTeam'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/proposal/work-team/:id/summary',
          name: 'ManagerProposalWorkTeamSummary',
          component: () => import('@/views/manager/ManagerProposalWorkTeam'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-job/:id',
          name: 'ManagerJobReport',
          component: () => import('@/views/manager/ManagerJobReport'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-company-employees/:id',
          name: 'ManagerCompanyJobEmployees',
          component: () => import('@/views/manager/ManagerJobEmployees'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager-recommended-employees/:id',
          name: 'ManagerRecommendedJobEmployees',
          component: () => import('@/views/manager/ManagerJobEmployees'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager/privacy-policy',
          name: 'ManagerPrivacyPolicy',
          component: () => import('@/views/application/PrivacyPolicy.vue'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager/course-details/:employeeJobProfileId/:employeeId/:courseId',
          name: 'ManagerCourseDetails',
          props: true,
          component: () =>
            import('@/views/manager/ManagerCourseDetails.vue'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager/course-details/:teamId/:courseId',
          name: 'ManagerTeamCourseDetails',
          props: true,
          component: () =>
            import('@/views/manager/ManagerCourseDetails.vue'),
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: '/manager/technical-support',
          name: 'ManagerTechnicalSupport',
          component: () =>
            import('@/views/manager/ManagerTechnicalSupport.vue'),
          meta: {
            rule: 'isManager'
          }
        }
      ]
    },
    /* Manager */
    /* Employee */
    {
      path: '/',
      component: () =>
        import('@/components/navigationBar/NavigationBarEmployee.vue'),
      meta: {
        rule: 'isEmployee'
      },
      children: [
        {
          path: '/employee-dashboard',
          name: 'EmployeeDashboard',
          component: () => import('@/views/employee/EmployeeDashboard.vue'),
          meta: {
            rule: 'isEmployee'
          }
        },
        {
          path: '/employee/courses',
          name: 'EmployeeCourses',
          component: () => import('@/views/employee/EmployeeCourses.vue'),
          meta: {
            rule: 'isEmployee'
          }
        },
        {
          path: '/employee/job-profiles',
          name: 'EmployeeJobProfiles',
          component: () => import('@/views/employee/EmployeeJobProfiles.vue'),
          meta: {
            rule: 'isEmployee',
            breadcrumb: [
              {
                name: i18n.t('profile.profiles_recomends')
              }
            ]
          }
        },
        {
          path: '/employee/course-details/:courseId',
          name: 'EmployeeCourseDetails',
          props: true,
          component: () => import('@/views/employee/EmployeeCourseDetails.vue'),
          meta: {
            rule: 'isEmployee',
            breadcrumb: [
              {
                name: i18n.t('profile.courses_for_you'),
                link: { name: 'EmployeeCourses' },
                color: 'white'
              },
              {
                name: i18n.t('course.details'),
                color: 'white'
              }
            ]
          }
        },
        {
          path: '/employee/job-profile-details/:idJobProfile',
          name: 'EmployeeJobProfileDetail',
          component: () =>
            import('@/views/employee/EmployeeJobProfileDetail.vue'),
          props: true,
          meta: {
            rule: 'isEmployee',
            breadcrumb: [
              {
                name: i18n.t('profile.profiles_recomends'),
                link: { name: 'EmployeeJobProfiles' },
                color: 'white'
              },
              {
                name: i18n.t('profile.job_profile_details'),
                color: 'white'
              }
            ]
          }
        },
        {
          path: '/employee/profile-edit',
          name: 'EmployeeEditProfile',
          component: () => import('@/views/employee/EmployeeEditProfile.vue'),
          meta: {
            rule: 'isEmployee'
          }
        },
        {
          path: '/employee/favorites',
          name: 'EmployeeFavoriteCourses',
          component: () =>
            import('@/views/employee/EmployeeFavoriteCourses.vue'),
          meta: {
            rule: 'isEmployee'
          }
        },
        {
          path: '/employee/badges-certificates',
          name: 'EmployeeBadgesAndCertificates',
          component: () =>
            import('@/views/employee/EmployeeBadgesAndCertificates.vue'),
          meta: {
            rule: 'isEmployee'
          }
        },
        {
          path: '/employee/hard-skills',
          name: 'EmployeeHardSkills',
          component: () => import('@/views/employee/EmployeeHardSkills.vue'),
          meta: {
            rule: 'isEmployee',
            breadcrumb: [
              {
                text: i18n.t('navbar.hard_skills'),
                color: 'grey'
              }
            ]
          }
        },
        {
          path: '/employee/compare-your-hard-skills',
          name: 'EmployeeHardSkillsForJobProfile',
          component: () =>
            import('@/views/employee/EmployeeHardSkillsForJobProfile.vue'),
          meta: {
            rule: 'isEmployee',
            breadcrumb: [
              {
                text: i18n.t('navbar.hard_skills'),
                color: 'grey'
              }
            ]
          }
        },
        {
          path: '/employee/soft-skills',
          name: 'EmployeeSoftSkills',
          component: () => import('@/views/employee/EmployeeSoftSkills.vue'),
          meta: {
            rule: 'isEmployee',
            breadcrumb: [
              {
                text: i18n.t('navbar.soft_skills'),
                color: 'grey'
              }
            ]
          }
        },
        {
          path: '/employee/compare-your-soft-skills',
          name: 'EmployeeSoftSkillsForJobProfile',
          component: () =>
            import('@/views/employee/EmployeeSoftSkillsForJobProfile.vue'),
          meta: {
            rule: 'isEmployee',
            breadcrumb: [
              {
                text: i18n.t('navbar.soft_skills'),
                color: 'grey'
              }
            ]
          }
        },
        {
          path: '/employee/faqs',
          name: 'EmployeeFaqs',
          component: () => import('@/views/employee/EmployeeFaqs.vue'),
          meta: {
            rule: 'isEmployee'
          }
        },
        {
          path: '/employee/privacy-policy',
          name: 'EmployeePrivacyPolicy',
          component: () => import('@/views/application/PrivacyPolicy.vue'),
          meta: {
            rule: 'isEmployee'
          }
        }
      ]
    },
    {
      path: '/employee-register/',
      name: 'EmployeeRegister',
      component: () => import('@/views/employee/EmployeeRegister.vue'),
      meta: {
        rule: 'isEmployee'
      }
    },
    {
      path: '/account-activation/:token_activation',
      name: 'ActivationAccountCandidate',
      component: () => import('@/pages/application/Activation.vue'),
      props: true,
      meta: {
        rule: 'isPublic'
      }
    },
    /* Validator */
    {
      path: '/',
      component: () =>
        import('@/components/navigationBar/NavigationBarValidator.vue'),
      meta: {
        rule: 'isValidator'
      },
      children: [
        // {
        //   path: 'validator',
        //   name: 'HomeValidator',
        //   component: () => import('@/views/validator/HomeValidator.vue'),
        //   meta: {
        //     rule: 'isValidator'
        //   }
        // },
        {
          path: 'validator/skills',
          name: 'ValidatorSkills',
          component: () => import('@/views/validator/ValidatorSkills.vue'),
          meta: {
            rule: 'isValidator',
            authRequired: true
          }
        },
        {
          path: 'validator/job-profiles',
          name: 'ValidatorJobProfiles',
          component: () => import('@/views/validator/ValidatorJobProfiles.vue'),
          meta: {
            rule: 'isValidator',
            authRequired: true
          }
        },
        {
          path: 'validator/edit-job-profile/:jobProfileId',
          name: 'ValidatorEditJobProfiles',
          component: () => import('@/views/validator/ValidatorValidateJobProfile.vue'),
          meta: {
            rule: 'isValidator',
            authRequired: true
          }
        }
        // {
        //   path: 'new-skills',
        //   name: 'ValidatorNewSkills',
        //   component: () => import('@/views/validator/ValidatorNewSkills.vue'),
        //   meta: {
        //     rule: 'isValidator',
        //     authRequired: true
        //   }
        // },
        // {
        //   path: 'validated-skills',
        //   name: 'ValidatorValidatedSkills',
        //   component: () =>
        //     import('@/views/validator/ValidatorValidatedSkills.vue'),
        //   meta: {
        //     rule: 'isValidator'
        //   }
        // },
        // {
        //   path: 'validated-skill-details/:id',
        //   name: 'ValidatorValidatedSkillDetailsPage',
        //   component: () =>
        //     import('@/views/validator/ValidatorValidatedSkillDetails'),
        //   meta: {
        //     rule: 'isValidator'
        //   }
        // },
        // {
        //   path: 'stop-words',
        //   name: 'ValidatorCreateStopWords',
        //   component: () => import('@/views/validator/ValidatorStopWords.vue'),
        //   meta: {
        //     rule: 'isValidator'
        //   }
        // },
        // {
        //   path: 'job-profiles-validate',
        //   name: 'ValidatorJobProfilePending',
        //   component: () =>
        //     import('@/views/validator/ValidatorJobProfilePending.vue'),
        //   meta: {
        //     rule: 'isValidator'
        //   }
        // },
        // {
        //   path: 'job-profiles-validated',
        //   name: 'ValidatorJobProfileValidated',
        //   component: () =>
        //     import('@/views/validator/ValidatorJobProfileValidated.vue'),
        //   meta: {
        //     rule: 'isValidator'
        //   }
        // },
        // {
        //   path: 'job-profiles-most-demand',
        //   name: 'ValidatorJobProfileMostDemand',
        //   component: () =>
        //     import('@/views/validator/ValidatorJobProfileMostDemand.vue'),
        //   meta: {
        //     rule: 'isValidator'
        //   }
        // },
        // {
        //   path: 'order-skills-profile/:id',
        //   name: 'ValidatorOrderSkillsProfile',
        //   component: () =>
        //     import('@/views/validator/ValidatorOrderSkillsByProfile.vue'),
        //   meta: {
        //     rule: 'isValidator',
        //     authRequired: true
        //   }
        // },
        // {
        //   path: 'job-profiles-discarded',
        //   name: 'ValidatorJobProfileDiscarded',
        //   component: () =>
        //     import('@/views/validator/ValidatorJobProfileDiscarded.vue'),
        //   meta: {
        //     rule: 'isValidator'
        //   }
        // },
        // {
        //   path: 'new-skills-validate',
        //   name: 'ValidatorValidateNewSkills',
        //   component: () =>
        //     import('@/views/validator/ValidatorValidateNewSkills.vue'),
        //   meta: {
        //     rule: 'isValidator'
        //   }
        // }
      ]
    },
    /* Validator */
    {
      path: '/welcome-empleable',
      name: 'WelcomePage',
      component: () => import('@/pages/application/WelcomePage.vue'),
      meta: {
        rule: 'isUser'
      }
    },
    {
      path: '/welcome/center',
      name: 'WelcomePageCenter',
      component: () => import('@/pages/application/WelcomePageCenter.vue'),
      meta: {
        rule: 'isTrainner'
      }
    },
    {
      path: '/welcome/manager',
      name: 'WelcomePageManager',
      component: () => import('@/pages/application/WelcomePageManager.vue'),
      meta: {
        rule: 'isManager'
      }
    },
    {
      path: '/email-verifier',
      name: 'EmailVerifier',
      component: () => import('@/pages/application/EmailVerifier.vue'),
      props: true,
      meta: {
        rule: 'isPublic'
      }
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: () => import('@/pages/application/ResetPassword.vue'),
      props: true,
      meta: {
        rule: 'isPublic'
      }
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: () => import('@/pages/application/ForgotPassword.vue'),
      meta: {
        rule: 'isPublic'
      }
    },
    {
      path: '/confirm-change-password/:email',
      name: 'ConfirmEmailToChangePassword',
      component: () =>
        import('@/pages/application/ConfirmEmailToChangePassword.vue'),
      meta: {
        rule: 'isPublic'
      }
    },
    {
      path: '/not_authorized',
      name: 'NotAuthorized',
      component: () => import('@/pages/application/NotAuthorized.vue'),
      meta: {}
    },
    {
      path: '/404',
      name: 'error404',
      component: () => import('@/pages/application/NotFound.vue'),
      meta: {
        rule: '*'
      }
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/404'
    }
  ],
  duplicateNavigationPolicy: 'ignore'
});
router.beforeEach((to, from, next) => {
  const currentUser = store.getters['user/userInfo'];
  if (currentUser.user_type === 'individual' && to.path === '/') {
    next({ name: 'IndividualDashboard' });
  } else if (currentUser.user_type === 'trainer' && to.path === '/') {
    next({ name: 'EducationCenterDashboard' });
  } else if (currentUser.user_type === 'manager_education' && to.path === '/') {
    next({ name: 'EducationCenterDashboard' });
  } else if (currentUser.user_type === 'manager' && to.path === '/') {
    if (currentUser?.subscription?.super_managers?.tot_subscription >= 1) {
      next({ name: 'ManagerDashboard' });
    } else {
      next({ name: 'ManagerSubscriptionsPlanPage' });
    }
  } else if (currentUser.user_type === 'employee' && to.path === '/') {
    next({ name: 'EmployeeDashboard' });
  } else if (currentUser.user_type === 'validator' && to.path === '/') {
    next({ name: 'ValidatorJobProfiles' });
  } else if (currentUser.user_type === 'default' && to.path === '/') {
    if (currentUser.intent_type_register === 'education-centre') {
      next({ name: 'EducationCentreRegister' });
    } else if (currentUser.intent_type_register === 'individual') {
      next({ name: 'IndividualRegister' });
    } else if (currentUser.intent_type_register === 'company') {
      next({ name: 'CompanyRegister' });
    }
  } else if (to.name === 'initial') {
    next({
      name: 'Homepage'
    });
  } else next();
});

router.afterEach((to, from, next) => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
});

export default router;
