import config from '@/config/config.js';
import ApiService from '@/services';

export const EmployeeService = {
  completeRegisterEmployee (payload) {
    return ApiService.put(
      `${config.CORE_ENDPOINT}employee-profile/${payload.id}`,
      {
        user: payload.user,
        first_name: payload.firstName,
        last_name: payload.lastName,
        profile: {
          level_study: payload.profile.levelStudy.id,
          range_year_experience: payload.profile.rangeYearExperience,
          hard_skill: payload.profile.hardSkills,
          soft_skill: payload.profile.softSkills,
          work_field: payload.profile.workfields,
          per_match_base: payload.profile.perMatchBase,
          certificates: payload.profile.certificates,
          preferred_work_field: payload.profile.preferredWorkfields || null
        }
      }
    );
  },
  fetchEmployeeById (payload) {
    return ApiService.get(`${config.CORE_ENDPOINT}employee-profile/${payload}`);
  },
  updateEmployee (payload) {
    return ApiService.put(
      `${config.CORE_ENDPOINT}employee-profile/${payload.id}`,
      {
        user: payload.user,
        first_name: payload.first_name,
        last_name: payload.last_name,
        // document: payload.document,
        authorization_share_data: payload.authorization_share_data,
        profile: {
          id: payload.profile.id,
          level_study: payload.profile.level_study.id,
          range_year_experience: payload.profile.range_year_experience.id,
          hard_skill: payload.profile.hard_skill,
          soft_skill: payload.profile.soft_skill,
          work_field: payload.profile.work_field,
          certificates: payload.profile.certificates
        }
        // address: payload.address
      }
    );
  },
  newsletter (payload) {
    return ApiService.post(`${config.CORE_ENDPOINT}newsletter`, {
      email: payload
    });
  },
  deleteEmployee (employeeId) {
    return ApiService.delete(`${config.CORE_ENDPOINT}employees`, employeeId);
  },
  getCertificateOfProfessionalism (payload) {
    return ApiService.query(
      `${config.CORE_ENDPOINT}professional-certificate`,
      {
        name: payload.search,
        limit: 10,
        offset: 0
      }
    );
  },
  fetchFavoriteCourses (payload) {
    return ApiService.query(`${config.CORE_ENDPOINT}employee-profile/${payload.id}/favorite-courses/`, {
      name: payload.name || null,
      type_modality: payload.type_modality || null,
      min_hour: payload.min_hour || null,
      max_hour: payload.max_hour || null,
      min_price: payload.min_price || null,
      max_price: payload.max_price || null,
      offset: payload.offset || 0,
      limit: payload.limit || 10
    });
  },
  postFavoriteCourses (courseId) {
    return ApiService.post(
      `${config.EDUCATION_ENDPOINT}favorite-course/`,
      {
        course: courseId
      }
    );
  },
  deleteFavoriteCourse (courseId) {
    return ApiService.delete(
      `${config.EDUCATION_ENDPOINT}favorite-course/${courseId}`
    );
  },
  getMostDemandCourses () {
    return ApiService.query(`${config.EDUCATION_ENDPOINT}ranking-courses`);
  },
  getMostDemandJobProfiles () {
    return ApiService.query(`${config.CORE_ENDPOINT}ranking-job-profiles`);
  },
  fetchJobProfilesRecommends (payload) {
    return ApiService.query(`${config.CORE_ENDPOINT}employee-profile/${payload.id}/recommended-job-profiles`, {
      limit: payload.limit ?? 10,
      offset: payload.offset ?? 0
    });
  },
  fetchCoursesRecommends (payload) {
    return ApiService.query(`${config.CORE_ENDPOINT}employee-profile/${payload.id}/recommended-courses`, {
      skill_name: payload.skill_name || null,
      name: payload.name || null,
      type_modality: payload.type_modality || null,
      min_hour: payload.min_hour || null,
      max_hour: payload.max_hour || null,
      min_price: payload.min_price || null,
      max_price: payload.max_price || null,
      limit: payload.limit || 10,
      offset: payload.offset || 0
    });
  },
  fetchCourseDetail (payload) {
    return ApiService.query(`${config.CORE_ENDPOINT}employee-profile/${payload.id}/recommended-courses/${payload.courseId}`);
  },
  getJobProfileDetail (payload) {
    return ApiService.query(
      `${config.DATA_ENDPOINT}detail-job-profile/${payload}`
    );
  },

  savedAvatar (payload) {
    return ApiService.put(
      `${config.CORE_ENDPOINT}employee-profile/${payload.id}/files`,
      payload.avatar,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  },
  postChosenCourse (payload) {
    return ApiService.post(`${config.CORE_ENDPOINT}employee-profile/${payload.id}/recommended-courses/${payload.courseId}/chosen-course`);
  },
  getSoftSkillReport (id) {
    return ApiService.get(`${config.CORE_ENDPOINT}employee-profile/${id}/report-soft-skill`);
  },
  fetchHardSkillsReport (id) {
    return ApiService.get(`${config.CORE_ENDPOINT}employee-profile/${id}/report-hard-skill`);
  },
  saveFiles (payload) {
    return ApiService.put(
      `${config.CORE_ENDPOINT}employee-profile/${payload.id}/files`,
      payload.cv,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  }
};

export default EmployeeService;
