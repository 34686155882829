// Mutation Types
import * as types from './types';

// Store
import store from '@/store';

export default {
  [types.LOGIN] (state, payload) {
    state.info = payload.user;
    state.childId = payload.user.child_id;
    state.accessToken = payload.access;
    state.refreshToken = payload.refresh;
    state.isLogged = true;
  },
  [types.USER_INFO] (state, payload) {
    state.info = payload;
  },
  [types.REGISTER] (state, payload) {
    state.info = payload.user;
    state.childId = payload.user.child_id;
    state.accessToken = payload.access;
    state.refreshToken = payload.refresh;
    state.isLogged = true;
  },
  [types.LOGOUT] () {
    store.commit('RESET_STORE');
  },
  [types.USER_PREFERENCES_LANGUAGE] (state, payload) {
    state.language = payload;
  },
  [types.ME] (state, payload) {
    state.info = null;
    state.info = payload;
    state.childId = payload.child_id;
    state.isLogged = true;
  },
  [types.ACTIVATION] (state, payload) {
    state.activation = payload;
  }
};
