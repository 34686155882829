<template>
  <v-dialog
      v-model="cookiesDialog"
      max-width="780"
  >
    <v-card class="cookies-card rounded-lg">
      <v-toolbar
          flat
          color="primary"
          dark
      >
        <v-toolbar-title class="cookie-title">{{ $t(title) }}</v-toolbar-title>
      </v-toolbar>
      <v-tabs vertical>
        <v-tab class="cookie-tab">
          {{ $t('cookies.cookies_title') }}
        </v-tab>

        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p class="cookies-information ma-0">
                {{ $t('cookies.cookies_information') }}
              </p>
            </v-card-text>
          </v-card>

          <hr width="90%" class="separator mx-auto"/>

          <v-card flat>
            <v-card-text>
              <div
                  v-for="(cookie, index) in cookies"
                  class="d-flex justify-space-between align-center"
                  :key="index"
              >
                <p class="cookie-name ma-0">
                  {{ cookie.name }}
                </p>
                <v-switch
                    v-model="cookie.accept"
                    inset
                />
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
      <v-card-actions class="d-flex justify-end pa-4">
        <v-btn
          @click="saveCookies"
          color="primary"
        >
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CookiesManageDialog',
  props: {
    title: {
      type: String,
      default: 'cookies.title'
    },
    cookies: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      cookiesDialog: false,
      listCookies: []
    };
  },
  methods: {
    open () {
      this.listCookies = this.cookies;
      this.cookiesDialog = true;
    },
    saveCookies () {
      this.$parent.$emit('customCookies', this.listCookies);
    }
  }
};
</script>

<style scoped lang="scss">
.cookies-card {
  .cookie-title {
    color: #FFFFFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
  }
  .cookies-information{
    color: var(--v-grayText-base);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .cookie-name,
  .cookie-tab {
    color: var(--v-grayText-base);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
  }
}
</style>
