// Mutation Types
import * as types from './types';

export default {
  [types.GET_HARD_SKILLS] (state, payload) {
    state.hardSkills = payload.items;
  },
  [types.SET_UNVALIDATED_PROFILES] (state, payload) {
    state.profilesUnvalidated = payload;
  },
  [types.SET_TOTAL_UNVALIDATED_PROFILES] (state, payload) {
    state.totalUnvalidatedProfiles = payload;
  },
  [types.SET_VALIDATED_PROFILES] (state, payload) {
    state.profilesValidated = payload;
  },
  [types.SET_TOTAL_VALIDATED_PROFILES] (state, payload) {
    state.totalValidatedProfiles = payload;
  },
  [types.GET_DELETED_POFILES] (state, payload) {
    state.profiles.results.push(...payload.items);
    state.profiles.count = payload.total;
  },
  [types.RESTORE_PROFILE] (state, payload) {
    const index = state.profiles.results.findIndex((p) => p.id === payload);
    state.profiles.results.splice(index, 1);
    state.profiles.count--;
  },
  [types.VALIDATE_PROFILE] (state, payload) {
    const index = state.profiles.results.findIndex((p) => p.id === payload);
    state.profiles.results.splice(index, 1);
    state.profiles.count--;
  },
  [types.DELETE_PROFILE] (state, payload) {
    const index = state.profiles.results.findIndex((p) => p.id === payload);
    state.profiles.results.splice(index, 1);
    state.profiles.count--;
  },
  [types.DELETE_UNVALIDATED_ROFILE] (state, payload) {
    const index = state.profilesUnvalidated.findIndex((p) => p.id === payload);
    state.profilesUnvalidated.splice(index, 1);
    state.profilesUnvalidated.count--;
  },
  [types.CLEAR_PROFILES] (state) {
    state.profiles.results = [];
    state.profiles.count = 0;
  },
  [types.GET_NEW_SKILLS] (state, payload) {
    state.newSkills.results.push(...payload.items);
    state.newSkills.count = payload.total;
  },
  [types.CLEAR_NEW_SKILLS] (state) {
    state.newSkills.results = [];
    state.newSkills.count = 0;
  },
  [types.VALIDATE_NEW_SKILL] (state, payload) {
    const index = state.newSkills.results.indexOf(payload.id);
    state.newSkills.results.splice(index, 1);
    state.newSkills.count--;
  },
  [types.REMOVE_NEW_SKILL] (state, payload) {
    const index = state.newSkills.results.indexOf(payload);
    state.newSkills.results.splice(index, 1);
    state.newSkills.count--;
  },
  [types.GET_STOP_WORDS] (state, payload) {
    state.stopWords.results.push(...payload.items);
    state.stopWords.count = payload.total;
  },
  [types.REMOVE_STOP_WORD] (state, payload) {
    const index = state.stopWords.results.indexOf(payload);
    state.stopWords.results.splice(index, 1);
    state.stopWords.count--;
  },
  [types.CLEAR_STOP_WORD] (state) {
    state.stopWords.results = [];
    state.stopWords.count = 0;
  },
  [types.GET_SECTORS] (state, payload) {
    state.sectors.results = payload.results;
  },
  [types.GET_RANKING] (state, payload) {
    state.ranking = payload.items;
  },
  [types.SET_STOP_WORDS] (state, payload) {
    state.listStopWords = payload.items;
  },
  [types.SET_TOTAL_WORDS] (state, payload) {
    state.totalWords = payload.total;
  },
  [types.SET_SKILLS] (state, payload) {
    state.skills = payload;
  },
  [types.SET_TOTAL_SKILLS] (state, payload) {
    state.totalSkills = payload;
  },
  [types.SET_VALIDATED_SKILLS] (state, payload) {
    state.validatedSkills = payload.items;
  },
  [types.SET_TOTAL_VALIDATED_SKILLS] (state, payload) {
    state.totalValidatedSkills = payload.total;
  },
  [types.SET_MOST_DEMAND_PROFILES] (state, payload) {
    state.mostDemandProfiles = payload.items;
  },
  [types.SET_TOTAL_MOST_DEMAND_PROFILES] (state, payload) {
    state.totalMostDemandProfiles = payload.total;
  },
  [types.SET_VALIDATED_SKILLS_FILTERS] (state, payload) {
    state.validatedSkillsFilters = payload;
  },
  [types.SET_TOTAL_JOB_PROFILES_SKILLS] (state, payload) {
    state.totalJobProfilesSkills = payload;
  },
  [types.SET_TOTAL_JOB_PROFILES_KNOWLEDGE] (state, payload) {
    state.totalJobProfilesKnowledge = payload;
  },
  [types.SET_JOB_PROFILES_SKILLS] (state, payload) {
    state.jobProfileSkills = payload;
  },
  [types.SET_JOB_PROFILES_KNOWLEDGE] (state, payload) {
    state.jobProfileKnowledge = payload;
  },
  [types.SET_PROPOSED_JOB_PROFILES] (state, payload) {
    state.fetchProposedProfiles = payload;
  },
  [types.SET_PROPOSED_JOB_PROFILE] (state, payload) {
    state.proposedJobProfile = payload;
  },
  [types.SET_TOTAL_PROPOSED_JOB_PROFILES] (state, payload) {
    state.totalProposedJobProfile = payload;
  },
  [types.SET_NOT_VALIDATED_JOB_PROFILES] (state, payload) {
    state.notValidatedJobProfiles = payload;
  },
  [types.SET_TOTAL_NOT_VALIDATED_JOB_PROFILES] (state, payload) {
    state.totalNotValidatedJobProfiles = payload;
  }
};
