// Mutation Types
import * as types from './types';

export default {
  [types.SET_HARD_SKILLS] (state, payload) {
    state.hardSkills = payload;
  },
  [types.SET_SOFT_SKILLS] (state, payload) {
    state.softSkills = payload;
  },
  [types.SET_WORK_FIELDS] (state, payload) {
    state.workfields = payload;
  },
  [types.GET_WORKFIELD_COMPUTED] (state, payload) {
    state.workfieldsComputed = payload.data;
  },
  [types.SET_JOB_PROFILES] (state, payload) {
    state.jobProfiles = payload;
  },
  [types.GET_LEVEL_OF_STUDY] (state, payload) {
    state.levelOfStudy = payload;
  },
  [types.GET_YEARS_EXPERIENCE] (state, payload) {
    state.yearsExperience = payload;
  },
  [types.SET_TOTAL_SKILLS] (state, payload) {
    state.totalSkills = payload;
  }
};
