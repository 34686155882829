/* eslint-disable */
// Mutation Types
import * as types from './types';

// Services
import knowledgeService from '@/services/KnowledgeService.js';
import { SOFT_SKILL } from '@/services/Constants';
import ApiService from "@/services";
import config from "@/config/config";
import IndividualService from "@/services/IndividualService";

export default {
  async getHardSkills({ commit }, payload) {
    // try {
    //     const response = await knowledgeService.getHardSkills(payload);
    //     commit(types.GET_HARD_SKILLS, response.data);
    //     } catch (e) {
    //     commit(types.GET_HARD_SKILLS, []);
    // }
  },
  async fetchSkills({ commit }, payload) {
    try {
      const response = await knowledgeService.fetchSkills(payload);
      if (payload.is_validator) {
        commit(types.SET_HARD_SKILLS, response.data.results);
        commit(types.SET_TOTAL_SKILLS, response.data.count);
      } else {
        if (payload.type_skill === SOFT_SKILL) {
          commit(types.SET_SOFT_SKILLS, response.data.results);
          commit(types.SET_TOTAL_SKILLS, response.data.count);
        } else {
          commit(types.SET_HARD_SKILLS, response.data.results);
          commit(types.SET_TOTAL_SKILLS, response.data.count);
        }
      }
    } catch (e) {
      if (payload.type_skill === SOFT_SKILL) {
        commit(types.SET_SOFT_SKILLS, []);
      } else {
        commit(types.SET_HARD_SKILLS, []);
      }
    }
  },
  async fetchSkill ({ commit }, skillId) {
    try {
      const response =  await knowledgeService.fetchSkill(skillId);
      return response.data;
    } catch (e) {
    }
  },
  async getSoftSkills({ commit }, payload) {
    // try {
    //     const response = await knowledgeService.getSoftSkills(payload);
    //     commit(types.GET_SOFT_SKILLS, response.data);
    //     } catch (e) {
    //     commit(types.GET_SOFT_SKILLS, []);
    // }
  },
  async getLevelOfStudy({ commit }, payload) {
    try {
        const response = await knowledgeService.getLevelOfStudy(payload);
        commit(types.GET_LEVEL_OF_STUDY, response.data);
        } catch (e) {
        commit(types.GET_LEVEL_OF_STUDY, []);
    }
  },
  async fetchWorkField ({ commit }, workFieldId) {
    try {
      const response = await knowledgeService.fetchWorkField(workFieldId);
      commit(types.SET_WORK_FIELDS, response.data.results);
    } catch (e) {
      commit(types.SET_WORK_FIELDS, []);
    }
  },
  async fetchWorkfields({ commit }, payload) {
    try {
      const response = await knowledgeService.fetchWorkfields(payload);
      commit(types.SET_WORK_FIELDS, response.data.results);
    } catch (e) {
      commit(types.SET_WORK_FIELDS, []);
    }
  },
  async getYearsExperience({ commit }, payload) {
    try {
        const response = await knowledgeService.getYearsExperience(payload);
        commit(types.GET_YEARS_EXPERIENCE, response.data);
        } catch (e) {
        commit(types.GET_YEARS_EXPERIENCE, []);
    }
  },
  async getWorkfieldsComputed({ commit }, payload) {
      try {
        const response = await knowledgeService.getWorkfieldsComputed(payload);
        commit(types.GET_WORKFIELD_COMPUTED, response.data);
        } catch (e) {
        commit(types.GET_WORKFIELD_COMPUTED, []);
      }
  },
  async fetchJobProfiles({ commit }, payload) {
    try {
      const response = await knowledgeService.fetchJobProfiles(payload);
      commit(types.SET_JOB_PROFILES, response.data.results);
      } catch (e) {
      commit(types.SET_JOB_PROFILES, []);
    }
  },
  async fetchRankingJobProfile ({ commit }, payload) {
    try {
      const response = await knowledgeService.fetchRankingJobProfiles(payload);
      commit(types.SET_JOB_PROFILES, response.data.results);
    } catch (e) {
      commit(types.SET_JOB_PROFILES, []);
    }
  },
  async updateSkill ({ commit }, payload) {
    await knowledgeService.updateSkill(payload.id, payload.data);
  }
};
