export default {
  areaKnowledge: [],
  totalAreaKnowledge: 0,
  employees: [],
  totalEmployees: 0,
  employee: {},
  branchOffice: [],
  totalBranchOffice: 0,
  jobProfiles: [],
  countries: [],
  states: [],
  localities: [],
  jobPositions: [],
  totalJobProfiles: 0,
  jobPosition: [],
  jobEmployees: [],
  recommendedJobEmployees: [],
  company: {},
  marketSkills: [],
  marketHomePageSkills: [],
  managerData: {},
  companyData: {},
  marketJobProfiles: [],
  team: [],
  defaultRolPermissions: [],
  courseDetail: {},
  companyRolePermissions: [],
  userRolPermissions: ''
};
