// Mutation Types
import * as types from './types';

// Services
import PricesService from '@/services/PricesService.js';

export default {
  async fetchIndividualProducts ({ commit }, payload) {
    try {
      const response = await PricesService.fetchIndividualProducts(payload);
      commit(types.SET_INDIVIDUAL_PRODUCTS, response.data);
      return response;
    } catch (e) {
      commit(types.SET_INDIVIDUAL_PRODUCTS, []);
    }
  },
  async getProductsEducationCenter ({ commit }) {
    try {
      const response = await PricesService.getProductsEducationCenter();
      commit(types.PRODUCTS_EDUCATION_CENTER, response.data);
    } catch (e) {
      commit(types.PRODUCTS_EDUCATION_CENTER, []);
    }
  },
  async fetchProductsManager ({ commit }) {
    try {
      const response = await PricesService.fetchProductsManager();
      commit(types.SET_PRODUCTS_MANAGER, response.data);
    } catch (e) {
      commit(types.SET_PRODUCTS_MANAGER, []);
    }
  },
  async payMethod ({ commit }, payload) {
    try {
      const response = await PricesService.postSessionCheckout(payload);
      commit(types.CHECKOUT_URL, response.data);
      return response;
    } catch (e) {
      commit(types.CHECKOUT_URL, {});
      throw e;
    }
  },
  async cancelSubscription ({ commit }, payload) {
    try {
      const response = await PricesService.postCancelSubscription(payload);
      // commit(types.CHECKOUT_URL, response.data);
      return response;
    } catch (e) {
    }
  },
  async updateSubscription ({ commit }, payload) {
    // try {
    const response = await PricesService.postUpdateSubscription(payload);
    // commit(types.CHECKOUT_URL, response.data);
    return response;
    // } catch (e) {
    // }
  }
};
