export default {
  individual: {},
  certificates: [],
  favoriteCourses: [],
  totalFavoritesCourses: 0,
  recommendedJobProfiles: [],
  totalRecommendedJobProfiles: 0,
  recommendedCourses: [],
  totalRecommendedCourses: 0,
  courseDetail: null,
  jobProfileDetail: null,
  mostDemandCourses: [],
  mostDemandJobProfiles: [],
  matchHardSkillsWithJobProfile: {},
  softSkillReport: {},
  hardSkills: [],
  softSkills: [],
  jobProfiles: [],
  workFields: []
};
