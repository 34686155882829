import Vue from 'vue';
import store from '@/store';
import { AclInstaller, AclCreate, AclRule } from 'vue-acl';
import router from '@/router';

Vue.use(AclInstaller);

let initialRole = 'public';
if (store.getters['user/type']) {
  initialRole = store.getters['user/type'];
}

export default new AclCreate({
  initial: initialRole,
  notfound: {
    path: '/',
    forwardQueryParams: true
  },
  router,
  acceptLocalRules: true,
  globalRules: {
    isPublic: new AclRule('public').generate(),
    isUser: new AclRule('validator')
      .or('individual')
      .or('manager')
      .or('employee')
      .or('manager_education')
      .or('trainer')
      .generate(),
    isPreCandidate: new AclRule('default').generate(),
    isCandidate: new AclRule('individual').or('employee').generate(),
    isManager: new AclRule('manager').generate(),
    isEmployee: new AclRule('employee').generate(),
    isTrainner: new AclRule('trainer').or('manager_education').generate(),
    isValidator: new AclRule('validator').generate(),
    isRegistering: new AclRule('default')
      .or('employee').generate()
  }
});
