import config from '@/config/config.js';
import ApiService from '@/services';

export const AuthService = {
  fetchIndividualProducts () {
    return ApiService.get(`${config.PAYMENT_ENDPOINT}ind-products`);
  },
  getProductsEducationCenter () {
    return ApiService.get(`${config.PAYMENT_ENDPOINT}educ-products`);
  },
  fetchProductsManager () {
    return ApiService.get(`${config.PAYMENT_ENDPOINT}comp-products`);
  },
  postSessionCheckout (payload) {
    return ApiService.post(
      `${config.PAYMENT_ENDPOINT}create-checkout-session`,
      {
        shopping_cart: payload
      }
    );
  },
  postCancelSubscription (payload) {
    return ApiService.post(
      `${config.PAYMENT_ENDPOINT}cancel-subscription`,
      {
        subscription_id: payload.id,
        cancellation_details: payload.cancellation_details
      }
    );
  },
  postUpdateSubscription (payload) {
    return ApiService.post(
      `${config.PAYMENT_ENDPOINT}update-subscription`,
      {
        subscription_id: payload.id,
        items: payload.items
      }
    );
  }
};

export default AuthService;
