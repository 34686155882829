// Mutation Types
import * as types from './types';
import ApplicationService from '@/services/ApplicationService';

export default {
  enableLoading ({ commit }) {
    commit(types.SET_LOADING, true);
  },
  disableLoading ({ commit }) {
    commit(types.SET_LOADING, false);
  },
  setLanguage ({ commit }, payload) {
    commit(types.SET_LANGUAGE, payload);
  },
  async getUserPreferencesLanguage ({ commit }) {
    const response = await ApplicationService.getUserPreferencesLanguage();
    commit(types.USER_PREFERENCES_LANGUAGE, response.data.language);
  },
  async updateUserPreferencesLanguage ({ commit }, lang) {
    ApplicationService.updateUserPreferencesLanguage(lang);

    commit(types.USER_PREFERENCES_LANGUAGE, lang);
  },
  async subscribeNewsletter ({ commit }, email) {
    return ApplicationService.subscribeNewsletter(email);
  },
  async sendContactForm ({ commit }, payload) {
    return ApplicationService.sendContactForm(payload);
  },
  async fetchMarketTrendReport ({ commit }, payload) {
    return await ApplicationService.fetchMarketTrendReport(payload);
  }
};
