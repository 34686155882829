import Vue from 'vue';
import App from '@/App.vue';
import 'regenerator-runtime';
// Axios
import Axios from 'axios';
import VueAxios from 'vue-axios';
// API Service
import ApiService from '@/services';
// Vuex
import store from '@/store';
// Router
import router from '@/router';
// Sass
import '@/sass/main.scss';
// Traductions
import i18n from '@/i18n';
// Notifications
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
// acl
import acl from '@/config/acl.js';
// Vuetify
import vuetify from '@/plugins/vuetify';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
// Error logger (?)
/* Google API */
import GAuth from 'vue-google-oauth2';
// Middlewares
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
// Meta data
import VueMeta from 'vue-meta';

Vue.use(VueTelInputVuetify, {
  vuetify
});

Vue.use(VueAxios, Axios);

ApiService.init();

const options = {
  position: 'bottom-right',
  transition: 'Vue-Toastification__fade',
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false
};
// https://github.com/Maronato/vue-toastification/tree/main

Vue.use(Toast, options);

const gauthOption = {
  clientId:
    '818080509948-906234hharl2vfjkf44ufll7ve314upq.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'consent',
  fetch_basic_profile: true
};
Vue.use(GAuth, gauthOption);
Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
  language: 'es',
  libraries: 'places',
  installComponents: true,
  vueGoogleMapsCompatibility: false
});

Vue.config.productionTip = false;

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

new Vue({
  vuetify,
  store,
  router,
  i18n,
  acl,
  render: (h) => h(App)
}).$mount('#app');
