export default {
  // API Configuration
  // -------------------------------------------------------------------
  API_URL: process.env.VUE_APP_ROOT_API,

  // Auth
  // ------------------------------------
  AUTH_ENDPOINT: 'auth/',

  // Knowledge
  // ------------------------------------
  KNOWLEDGE_ENDPOINT: 'knowledge/',

  // Education
  // ------------------------------------
  EDUCATION_ENDPOINT: 'education/',

  // Core
  // ------------------------------------
  CORE_ENDPOINT: 'core/',

  // Datasciense proxy
  // ------------------------------------
  DATA_PROXY_ENDPOINT: 'datascience/proxy/',

  // Datasciense
  // ------------------------------------
  DATA_ENDPOINT: 'datascience/',

  // Datasciense validation
  // ------------------------------------
  VALIDATION_ENDPOINT: 'validation/',

  // Datasciense matching
  // ------------------------------------
  MATCHING_ENDPOINT: 'matching/',

  // Products
  // ------------------------------------
  PAYMENT_ENDPOINT: 'payment/',

  // Constants
  // ------------------------------------
  CONSTANTS: {},

  // Validator
  // ------------------------------------
  VALIDATOR_ENDPOINT: 'validator/'
};
