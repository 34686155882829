import i18n from '@/i18n';

// company
export const COMP_SUPER_MANAGER = 'supermanager';
export const COMP_MANAGER = 'manager';
export const COMP_GESTOR = 'gestor';
export const COMP_EMPLOYEE = 'employee';
export const EMPLOYEE_INCOMPLETE = 'incomplete';
export const EMPLOYEE_COMPLETE = 'complete';
export const PERMISSIONS = {
  active_account: 1,
  edit_company_data: 2,
  create_manager_user: 3,
  update_user_privileges: 4,
  create_employee: 5,
  deactivate_employee: 6,
  delete_employee: 7,
  create_job_position: 8,
  edit_job_position: 9,
  delete_job_position: 10,
  view_all_employees: 11,
  view_employees_by_country: 12,
  view_employees_by_job_region: 13,
  view_employees_by_department: 14,
  download_data_employee_pdf: 15,
  compare_all_employees: 16,
  compare_employee_by_country: 17,
  compare_employee_by_region: 18,
  compare_employee_by_department: 19,
  download_formation_proposal_pdf: 20,
  send_formation_proposal: 21
};

// education center
export const EDUC_PLATINUM = 'platinum';
export const EDUC_GOLD = 'gold';
export const EDUC_SILVER = 'silver';
export const EDUC_BASIC = 'basic';

// user types
export const USER_INDIVIDUAL = 'individual';
export const USER_EMPLOYEE = 'employee';
export const USER_TRAINER = 'trainer';
export const USER_EDUCATION_MANAGER = 'manager_education';

// empleable modules
export const COMPANY_MODULE = 'company';
export const CANDIDATE_MODULE = 'candidate';
export const EDUCATION_CENTER_MODULE = 'education_center';

// individual
export const INDIVIDUAL_BASIC = 'basic';
export const INDIVIDUAL_PREMIUM = 'premium';
export const SOFT_SKILL = 0;
export const HARD_SKILL = 1;
export const PROCESSING_VIDEO = 5;
export const PROCESSED_VIDEO = 1;

// Validator
export const VALIDATOR_VALID_STATUS = 7;
export const VALIDATOR_INVALID_STATUS = 8;
export const VALIDATOR_DISCARDED_STATUS = 9;
export const VALIDATOR_PENDING_STATUS = 'pending';
export const VALIDATOR_APPROVED_STATUS = 'approved';
export const VALIDATOR_REJECTED_STATUS = 'rejected';
export const VALIDATOR_INAPPROPRIATE_STATUS = 'inappropriate';
export const VALIDATOR_HARD_SKILL = 'H';
export const VALIDATOR_SOFT_SKILL = 'S';

// plan highlights
export const EDUC_BASIC_FEATURES = [
  {
    name: 'home.center_prices.upload_courses',
    include: 1,
    course: 10
  },
  {
    name: 'home.center_prices.cancel_service',
    include: 1
  },
  {
    name: 'home.center_prices.renewal_auto',
    include: 1
  }
];
export const EDUC_SILVER_FEATURES = [
  {
    name: 'home.center_prices.upload_courses',
    include: 1,
    course: 30
  },
  {
    name: 'home.center_prices.cancel_service',
    include: 1
  },
  {
    name: 'home.center_prices.renewal_auto',
    include: 1
  },
  {
    name: 'home.center_prices.trends',
    include: 1
  }
];
export const EDUC_GOLD_FEATURES = [
  {
    name: 'home.center_prices.upload_courses',
    include: 1,
    course: 50
  },
  {
    name: 'home.center_prices.cancel_service',
    include: 1
  },
  {
    name: 'home.center_prices.renewal_auto',
    include: 1
  },
  {
    name: 'home.center_prices.trends',
    include: 1
  }
];
export const EDUC_PLATINUM_FEATURES = [
  {
    name: 'home.center_prices.load_complete',
    include: 1,
    course: -1
  },
  {
    name: 'home.center_prices.cancel_service',
    include: 1
  },
  {
    name: 'home.center_prices.renewal_auto',
    include: 1
  },
  {
    name: 'home.center_prices.trends',
    include: 1
  }
];
export const INDIVIDUAL_BASIC_FEATURES = [
  {
    name: 'home.individual_prices.competencies',
    include: 1
  },
  {
    name: 'home.individual_prices.trends',
    include: 1
  },
  {
    name: 'home.individual_prices.itineraries_formative',
    include: 1
  },
  {
    name: 'home.individual_prices.skills_validation',
    include: 0
  },
  {
    name: 'home.individual_prices.psychological_profile',
    include: 0
  }
];
export const INDIVIDUAL_PREMIUM_FEATURES = [
  {
    name: 'home.individual_prices.competencies',
    include: 1
  },
  {
    name: 'home.individual_prices.trends',
    include: 1
  },
  {
    name: 'home.individual_prices.itineraries_formative',
    include: 1
  },
  {
    name: 'home.individual_prices.skills_validation',
    include: 1
  },
  {
    name: 'home.individual_prices.psychological_profile',
    include: 1
  }
];
export const COUNTRY_LIST = [
  { name: i18n.t(`countries.${'Afghanistan'}`), code: 'AF' },
  { name: i18n.t(`countries.${'Åland Islands'}`), code: 'AX' },
  { name: i18n.t(`countries.${'Albania'}`), code: 'AL' },
  { name: i18n.t(`countries.${'Algeria'}`), code: 'DZ' },
  { name: i18n.t(`countries.${'American Samoa'}`), code: 'AS' },
  { name: i18n.t(`countries.${'AndorrA'}`), code: 'AD' },
  { name: i18n.t(`countries.${'Angola'}`), code: 'AO' },
  { name: i18n.t(`countries.${'Anguilla'}`), code: 'AI' },
  { name: i18n.t(`countries.${'Antarctica'}`), code: 'AQ' },
  { name: i18n.t(`countries.${'Antigua and Barbuda'}`), code: 'AG' },
  { name: i18n.t(`countries.${'Argentina'}`), code: 'AR' },
  { name: i18n.t(`countries.${'Armenia'}`), code: 'AM' },
  { name: i18n.t(`countries.${'Aruba'}`), code: 'AW' },
  { name: i18n.t(`countries.${'Australia'}`), code: 'AU' },
  { name: i18n.t(`countries.${'Austria'}`), code: 'AT' },
  { name: i18n.t(`countries.${'Azerbaijan'}`), code: 'AZ' },
  { name: i18n.t(`countries.${'Bahamas'}`), code: 'BS' },
  { name: i18n.t(`countries.${'Bahrain'}`), code: 'BH' },
  { name: i18n.t(`countries.${'Bangladesh'}`), code: 'BD' },
  { name: i18n.t(`countries.${'Barbados'}`), code: 'BB' },
  { name: i18n.t(`countries.${'Belarus'}`), code: 'BY' },
  { name: i18n.t(`countries.${'Belgium'}`), code: 'BE' },
  { name: i18n.t(`countries.${'Belize'}`), code: 'BZ' },
  { name: i18n.t(`countries.${'Benin'}`), code: 'BJ' },
  { name: i18n.t(`countries.${'Bermuda'}`), code: 'BM' },
  { name: i18n.t(`countries.${'Bhutan'}`), code: 'BT' },
  { name: i18n.t(`countries.${'Bolivia'}`), code: 'BO' },
  { name: i18n.t(`countries.${'Bosnia and Herzegovina'}`), code: 'BA' },
  { name: i18n.t(`countries.${'Botswana'}`), code: 'BW' },
  { name: i18n.t(`countries.${'Bouvet Island'}`), code: 'BV' },
  { name: i18n.t(`countries.${'Brazil'}`), code: 'BR' },
  { name: i18n.t(`countries.${'British Indian Ocean Territory'}`), code: 'IO' },
  { name: i18n.t(`countries.${'Brunei Darussalam'}`), code: 'BN' },
  { name: i18n.t(`countries.${'Bulgaria'}`), code: 'BG' },
  { name: i18n.t(`countries.${'Burkina Faso'}`), code: 'BF' },
  { name: i18n.t(`countries.${'Burundi'}`), code: 'BI' },
  { name: i18n.t(`countries.${'Cambodia'}`), code: 'KH' },
  { name: i18n.t(`countries.${'Cameroon'}`), code: 'CM' },
  { name: i18n.t(`countries.${'Canada'}`), code: 'CA' },
  { name: i18n.t(`countries.${'Cape Verde'}`), code: 'CV' },
  { name: i18n.t(`countries.${'Cayman Islands'}`), code: 'KY' },
  { name: i18n.t(`countries.${'Central African Republic'}`), code: 'CF' },
  { name: i18n.t(`countries.${'Chad'}`), code: 'TD' },
  { name: i18n.t(`countries.${'Chile'}`), code: 'CL' },
  { name: i18n.t(`countries.${'China'}`), code: 'CN' },
  { name: i18n.t(`countries.${'Christmas Island'}`), code: 'CX' },
  { name: i18n.t(`countries.${'Cocos (Keeling) Islands'}`), code: 'CC' },
  { name: i18n.t(`countries.${'Colombia'}`), code: 'CO' },
  { name: i18n.t(`countries.${'Comoros'}`), code: 'KM' },
  { name: i18n.t(`countries.${'Congo'}`), code: 'CG' },
  { name: i18n.t(`countries.${'Congo, The Democratic Republic of the'}`), code: 'CD' },
  { name: i18n.t(`countries.${'Cook Islands'}`), code: 'CK' },
  { name: i18n.t(`countries.${'Costa Rica'}`), code: 'CR' },
  { name: i18n.t(`countries.${'Cote DIvoire'}`), code: 'CI' },
  { name: i18n.t(`countries.${'Croatia'}`), code: 'HR' },
  { name: i18n.t(`countries.${'Cuba'}`), code: 'CU' },
  { name: i18n.t(`countries.${'Cyprus'}`), code: 'CY' },
  { name: i18n.t(`countries.${'Czech Republic'}`), code: 'CZ' },
  { name: i18n.t(`countries.${'Denmark'}`), code: 'DK' },
  { name: i18n.t(`countries.${'Djibouti'}`), code: 'DJ' },
  { name: i18n.t(`countries.${'Dominica'}`), code: 'DM' },
  { name: i18n.t(`countries.${'Dominican Republic'}`), code: 'DO' },
  { name: i18n.t(`countries.${'Ecuador'}`), code: 'EC' },
  { name: i18n.t(`countries.${'Egypt'}`), code: 'EG' },
  { name: i18n.t(`countries.${'El Salvador'}`), code: 'SV' },
  { name: i18n.t(`countries.${'Equatorial Guinea'}`), code: 'GQ' },
  { name: i18n.t(`countries.${'Eritrea'}`), code: 'ER' },
  { name: i18n.t(`countries.${'Estonia'}`), code: 'EE' },
  { name: i18n.t(`countries.${'Ethiopia'}`), code: 'ET' },
  { name: i18n.t(`countries.${'Falkland Islands (Malvinas)'}`), code: 'FK' },
  { name: i18n.t(`countries.${'Faroe Islands'}`), code: 'FO' },
  { name: i18n.t(`countries.${'Fiji'}`), code: 'FJ' },
  { name: i18n.t(`countries.${'Finland'}`), code: 'FI' },
  { name: i18n.t(`countries.${'France'}`), code: 'FR' },
  { name: i18n.t(`countries.${'French Guiana'}`), code: 'GF' },
  { name: i18n.t(`countries.${'French Polynesia'}`), code: 'PF' },
  { name: i18n.t(`countries.${'French Southern Territories'}`), code: 'TF' },
  { name: i18n.t(`countries.${'Gabon'}`), code: 'GA' },
  { name: i18n.t(`countries.${'Gambia'}`), code: 'GM' },
  { name: i18n.t(`countries.${'Georgia'}`), code: 'GE' },
  { name: i18n.t(`countries.${'Germany'}`), code: 'DE' },
  { name: i18n.t(`countries.${'Ghana'}`), code: 'GH' },
  { name: i18n.t(`countries.${'Gibraltar'}`), code: 'GI' },
  { name: i18n.t(`countries.${'Greece'}`), code: 'GR' },
  { name: i18n.t(`countries.${'Greenland'}`), code: 'GL' },
  { name: i18n.t(`countries.${'Grenada'}`), code: 'GD' },
  { name: i18n.t(`countries.${'Guadeloupe'}`), code: 'GP' },
  { name: i18n.t(`countries.${'Guam'}`), code: 'GU' },
  { name: i18n.t(`countries.${'Guatemala'}`), code: 'GT' },
  { name: i18n.t(`countries.${'Guernsey'}`), code: 'GG' },
  { name: i18n.t(`countries.${'Guinea'}`), code: 'GN' },
  { name: i18n.t(`countries.${'Guinea-Bissau'}`), code: 'GW' },
  { name: i18n.t(`countries.${'Guyana'}`), code: 'GY' },
  { name: i18n.t(`countries.${'Haiti'}`), code: 'HT' },
  { name: i18n.t(`countries.${'Heard Island and Mcdonald Islands'}`), code: 'HM' },
  { name: i18n.t(`countries.${'Holy See (Vatican City State)'}`), code: 'VA' },
  { name: i18n.t(`countries.${'Honduras'}`), code: 'HN' },
  { name: i18n.t(`countries.${'Hong Kong'}`), code: 'HK' },
  { name: i18n.t(`countries.${'Hungary'}`), code: 'HU' },
  { name: i18n.t(`countries.${'Iceland'}`), code: 'IS' },
  { name: i18n.t(`countries.${'India'}`), code: 'IN' },
  { name: i18n.t(`countries.${'Indonesia'}`), code: 'ID' },
  { name: i18n.t(`countries.${'Iran, Islamic Republic Of'}`), code: 'IR' },
  { name: i18n.t(`countries.${'Iraq'}`), code: 'IQ' },
  { name: i18n.t(`countries.${'Ireland'}`), code: 'IE' },
  { name: i18n.t(`countries.${'Isle of Man'}`), code: 'IM' },
  { name: i18n.t(`countries.${'Israel'}`), code: 'IL' },
  { name: i18n.t(`countries.${'Italy'}`), code: 'IT' },
  { name: i18n.t(`countries.${'Jamaica'}`), code: 'JM' },
  { name: i18n.t(`countries.${'Japan'}`), code: 'JP' },
  { name: i18n.t(`countries.${'Jersey'}`), code: 'JE' },
  { name: i18n.t(`countries.${'Jordan'}`), code: 'JO' },
  { name: i18n.t(`countries.${'Kazakhstan'}`), code: 'KZ' },
  { name: i18n.t(`countries.${'Kenya'}`), code: 'KE' },
  { name: i18n.t(`countries.${'Kiribati'}`), code: 'KI' },
  { name: i18n.t(`countries.${'Korea, Democratic PeopleS Republic of'}`), code: 'KP' },
  { name: i18n.t(`countries.${'Korea, Republic of'}`), code: 'KR' },
  { name: i18n.t(`countries.${'Kuwait'}`), code: 'KW' },
  { name: i18n.t(`countries.${'Kyrgyzstan'}`), code: 'KG' },
  { name: i18n.t(`countries.${'Lao PeopleS Democratic Republic'}`), code: 'LA' },
  { name: i18n.t(`countries.${'Latvia'}`), code: 'LV' },
  { name: i18n.t(`countries.${'Lebanon'}`), code: 'LB' },
  { name: i18n.t(`countries.${'Lesotho'}`), code: 'LS' },
  { name: i18n.t(`countries.${'Liberia'}`), code: 'LR' },
  { name: i18n.t(`countries.${'Libyan Arab Jamahiriya'}`), code: 'LY' },
  { name: i18n.t(`countries.${'Liechtenstein'}`), code: 'LI' },
  { name: i18n.t(`countries.${'Lithuania'}`), code: 'LT' },
  { name: i18n.t(`countries.${'Luxembourg'}`), code: 'LU' },
  { name: i18n.t(`countries.${'Macao'}`), code: 'MO' },
  { name: i18n.t(`countries.${'Macedonia, The Former Yugoslav Republic of'}`), code: 'MK' },
  { name: i18n.t(`countries.${'Madagascar'}`), code: 'MG' },
  { name: i18n.t(`countries.${'Malawi'}`), code: 'MW' },
  { name: i18n.t(`countries.${'Malaysia'}`), code: 'MY' },
  { name: i18n.t(`countries.${'Maldives'}`), code: 'MV' },
  { name: i18n.t(`countries.${'Mali'}`), code: 'ML' },
  { name: i18n.t(`countries.${'Malta'}`), code: 'MT' },
  { name: i18n.t(`countries.${'Marshall Islands'}`), code: 'MH' },
  { name: i18n.t(`countries.${'Martinique'}`), code: 'MQ' },
  { name: i18n.t(`countries.${'Mauritania'}`), code: 'MR' },
  { name: i18n.t(`countries.${'Mauritius'}`), code: 'MU' },
  { name: i18n.t(`countries.${'Mayotte'}`), code: 'YT' },
  { name: i18n.t(`countries.${'Mexico'}`), code: 'MX' },
  { name: i18n.t(`countries.${'Micronesia, Federated States of'}`), code: 'FM' },
  { name: i18n.t(`countries.${'Moldova, Republic of'}`), code: 'MD' },
  { name: i18n.t(`countries.${'Monaco'}`), code: 'MC' },
  { name: i18n.t(`countries.${'Mongolia'}`), code: 'MN' },
  { name: i18n.t(`countries.${'Montserrat'}`), code: 'MS' },
  { name: i18n.t(`countries.${'Morocco'}`), code: 'MA' },
  { name: i18n.t(`countries.${'Mozambique'}`), code: 'MZ' },
  { name: i18n.t(`countries.${'Myanmar'}`), code: 'MM' },
  { name: i18n.t(`countries.${'Namibia'}`), code: 'NA' },
  { name: i18n.t(`countries.${'Nauru'}`), code: 'NR' },
  { name: i18n.t(`countries.${'Nepal'}`), code: 'NP' },
  { name: i18n.t(`countries.${'Netherlands'}`), code: 'NL' },
  { name: i18n.t(`countries.${'Netherlands Antilles'}`), code: 'AN' },
  { name: i18n.t(`countries.${'New Caledonia'}`), code: 'NC' },
  { name: i18n.t(`countries.${'New Zealand'}`), code: 'NZ' },
  { name: i18n.t(`countries.${'Nicaragua'}`), code: 'NI' },
  { name: i18n.t(`countries.${'Niger'}`), code: 'NE' },
  { name: i18n.t(`countries.${'Nigeria'}`), code: 'NG' },
  { name: i18n.t(`countries.${'Niue'}`), code: 'NU' },
  { name: i18n.t(`countries.${'Norfolk Island'}`), code: 'NF' },
  { name: i18n.t(`countries.${'Northern Mariana Islands'}`), code: 'MP' },
  { name: i18n.t(`countries.${'Norway'}`), code: 'NO' },
  { name: i18n.t(`countries.${'Oman'}`), code: 'OM' },
  { name: i18n.t(`countries.${'Pakistan'}`), code: 'PK' },
  { name: i18n.t(`countries.${'Palau'}`), code: 'PW' },
  { name: i18n.t(`countries.${'Palestinian Territory, Occupied'}`), code: 'PS' },
  { name: i18n.t(`countries.${'Panama'}`), code: 'PA' },
  { name: i18n.t(`countries.${'Papua New Guinea'}`), code: 'PG' },
  { name: i18n.t(`countries.${'Paraguay'}`), code: 'PY' },
  { name: i18n.t(`countries.${'Peru'}`), code: 'PE' },
  { name: i18n.t(`countries.${'Philippines'}`), code: 'PH' },
  { name: i18n.t(`countries.${'Pitcairn'}`), code: 'PN' },
  { name: i18n.t(`countries.${'Poland'}`), code: 'PL' },
  { name: i18n.t(`countries.${'Portugal'}`), code: 'PT' },
  { name: i18n.t(`countries.${'Puerto Rico'}`), code: 'PR' },
  { name: i18n.t(`countries.${'Qatar'}`), code: 'QA' },
  { name: i18n.t(`countries.${'Reunion'}`), code: 'RE' },
  { name: i18n.t(`countries.${'Romania'}`), code: 'RO' },
  { name: i18n.t(`countries.${'Russian Federation'}`), code: 'RU' },
  { name: i18n.t(`countries.${'RWANDA'}`), code: 'RW' },
  { name: i18n.t(`countries.${'Saint Helena'}`), code: 'SH' },
  { name: i18n.t(`countries.${'Saint Kitts and Nevis'}`), code: 'KN' },
  { name: i18n.t(`countries.${'Saint Lucia'}`), code: 'LC' },
  { name: i18n.t(`countries.${'Saint Pierre and Miquelon'}`), code: 'PM' },
  { name: i18n.t(`countries.${'Saint Vincent and the Grenadines'}`), code: 'VC' },
  { name: i18n.t(`countries.${'Samoa'}`), code: 'WS' },
  { name: i18n.t(`countries.${'San Marino'}`), code: 'SM' },
  { name: i18n.t(`countries.${'Sao Tome and Principe'}`), code: 'ST' },
  { name: i18n.t(`countries.${'Saudi Arabia'}`), code: 'SA' },
  { name: i18n.t(`countries.${'Senegal'}`), code: 'SN' },
  { name: i18n.t(`countries.${'Serbia and Montenegro'}`), code: 'CS' },
  { name: i18n.t(`countries.${'Seychelles'}`), code: 'SC' },
  { name: i18n.t(`countries.${'Sierra Leone'}`), code: 'SL' },
  { name: i18n.t(`countries.${'Singapore'}`), code: 'SG' },
  { name: i18n.t(`countries.${'Slovakia'}`), code: 'SK' },
  { name: i18n.t(`countries.${'Slovenia'}`), code: 'SI' },
  { name: i18n.t(`countries.${'Solomon Islands'}`), code: 'SB' },
  { name: i18n.t(`countries.${'Somalia'}`), code: 'SO' },
  { name: i18n.t(`countries.${'South Africa'}`), code: 'ZA' },
  { name: i18n.t(`countries.${'South Georgia and the South Sandwich Islands'}`), code: 'GS' },
  { name: i18n.t(`countries.${'Spain'}`), code: 'ES' },
  { name: i18n.t(`countries.${'Sri Lanka'}`), code: 'LK' },
  { name: i18n.t(`countries.${'Sudan'}`), code: 'SD' },
  { name: i18n.t(`countries.${'Suriname'}`), code: 'SR' },
  { name: i18n.t(`countries.${'Svalbard and Jan Mayen'}`), code: 'SJ' },
  { name: i18n.t(`countries.${'Swaziland'}`), code: 'SZ' },
  { name: i18n.t(`countries.${'Sweden'}`), code: 'SE' },
  { name: i18n.t(`countries.${'Switzerland'}`), code: 'CH' },
  { name: i18n.t(`countries.${'Syrian Arab Republic'}`), code: 'SY' },
  { name: i18n.t(`countries.${'Taiwan, Province of China'}`), code: 'TW' },
  { name: i18n.t(`countries.${'Tajikistan'}`), code: 'TJ' },
  { name: i18n.t(`countries.${'Tanzania, United Republic of'}`), code: 'TZ' },
  { name: i18n.t(`countries.${'Thailand'}`), code: 'TH' },
  { name: i18n.t(`countries.${'Timor-Leste'}`), code: 'TL' },
  { name: i18n.t(`countries.${'Togo'}`), code: 'TG' },
  { name: i18n.t(`countries.${'Tokelau'}`), code: 'TK' },
  { name: i18n.t(`countries.${'Tonga'}`), code: 'TO' },
  { name: i18n.t(`countries.${'Trinidad and Tobago'}`), code: 'TT' },
  { name: i18n.t(`countries.${'Tunisia'}`), code: 'TN' },
  { name: i18n.t(`countries.${'Turkey'}`), code: 'TR' },
  { name: i18n.t(`countries.${'Turkmenistan'}`), code: 'TM' },
  { name: i18n.t(`countries.${'Turks and Caicos Islands'}`), code: 'TC' },
  { name: i18n.t(`countries.${'Tuvalu'}`), code: 'TV' },
  { name: i18n.t(`countries.${'Uganda'}`), code: 'UG' },
  { name: i18n.t(`countries.${'Ukraine'}`), code: 'UA' },
  { name: i18n.t(`countries.${'United Arab Emirates'}`), code: 'AE' },
  { name: i18n.t(`countries.${'United Kingdom'}`), code: 'GB' },
  { name: i18n.t(`countries.${'United States'}`), code: 'US' },
  { name: i18n.t(`countries.${'United States Minor Outlying Islands'}`), code: 'UM' },
  { name: i18n.t(`countries.${'Uruguay'}`), code: 'UY' },
  { name: i18n.t(`countries.${'Uzbekistan'}`), code: 'UZ' },
  { name: i18n.t(`countries.${'Vanuatu'}`), code: 'VU' },
  { name: i18n.t(`countries.${'Venezuela'}`), code: 'VE' },
  { name: i18n.t(`countries.${'Viet Nam'}`), code: 'VN' },
  { name: i18n.t(`countries.${'Virgin_Islands_British'}`), code: 'VG' },
  { name: i18n.t(`countries.${'Virgin_Islands_US'}`), code: 'VI' },
  { name: i18n.t(`countries.${'Wallis and Futuna'}`), code: 'WF' },
  { name: i18n.t(`countries.${'Western Sahara'}`), code: 'EH' },
  { name: i18n.t(`countries.${'Yemen'}`), code: 'YE' },
  { name: i18n.t(`countries.${'Zambia'}`), code: 'ZM' },
  { name: i18n.t(`countries.${'Zimbabwe'}`), code: 'ZW' }
].sort((a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
});

export const ACTIVE_STATE = 1;
export const INACTIVE_STATE = 2;
export const DELETE_STATE = 3;
export const PHONE_CODE_LIST = [
  {
    nameES: 'Afganistán',
    nameEN: 'Afghanistan',
    iso2: 'AF',
    iso3: 'AFG',
    phoneCode: '93'
  },
  {
    nameES: 'Albania',
    nameEN: 'Albania',
    iso2: 'AL',
    iso3: 'ALB',
    phoneCode: '355'
  },
  {
    nameES: 'Alemania',
    nameEN: 'Germany',
    iso2: 'DE',
    iso3: 'DEU',
    phoneCode: '49'
  },
  {
    nameES: 'Andorra',
    nameEN: 'Andorra',
    iso2: 'AD',
    iso3: 'AND',
    phoneCode: '376'
  },
  {
    nameES: 'Angola',
    nameEN: 'Angola',
    iso2: 'AO',
    iso3: 'AGO',
    phoneCode: '244'
  },
  {
    nameES: 'Anguila',
    nameEN: 'Anguilla',
    iso2: 'AI',
    iso3: 'AIA',
    phoneCode: '1 264'
  },
  {
    nameES: 'Antártida',
    nameEN: 'Antarctica',
    iso2: 'AQ',
    iso3: 'ATA',
    phoneCode: '672'
  },
  {
    nameES: 'Antigua y Barbuda',
    nameEN: 'Antigua and Barbuda',
    iso2: 'AG',
    iso3: 'ATG',
    phoneCode: '1 268'
  },
  {
    nameES: 'Arabia Saudita',
    nameEN: 'Saudi Arabia',
    iso2: 'SA',
    iso3: 'SAU',
    phoneCode: '966'
  },
  {
    nameES: 'Argelia',
    nameEN: 'Algeria',
    iso2: 'DZ',
    iso3: 'DZA',
    phoneCode: '213'
  },
  {
    nameES: 'Argentina',
    nameEN: 'Argentina',
    iso2: 'AR',
    iso3: 'ARG',
    phoneCode: '54'
  },
  {
    nameES: 'Armenia',
    nameEN: 'Armenia',
    iso2: 'AM',
    iso3: 'ARM',
    phoneCode: '374'
  },
  {
    nameES: 'Aruba',
    nameEN: 'Aruba',
    iso2: 'AW',
    iso3: 'ABW',
    phoneCode: '297'
  },
  {
    nameES: 'Australia',
    nameEN: 'Australia',
    iso2: 'AU',
    iso3: 'AUS',
    phoneCode: '61'
  },
  {
    nameES: 'Austria',
    nameEN: 'Austria',
    iso2: 'AT',
    iso3: 'AUT',
    phoneCode: '43'
  },
  {
    nameES: 'Azerbaiyán',
    nameEN: 'Azerbaijan',
    iso2: 'AZ',
    iso3: 'AZE',
    phoneCode: '994'
  },
  {
    nameES: 'Bélgica',
    nameEN: 'Belgium',
    iso2: 'BE',
    iso3: 'BEL',
    phoneCode: '32'
  },
  {
    nameES: 'Bahamas',
    nameEN: 'Bahamas',
    iso2: 'BS',
    iso3: 'BHS',
    phoneCode: '1 242'
  },
  {
    nameES: 'Bahrein',
    nameEN: 'Bahrain',
    iso2: 'BH',
    iso3: 'BHR',
    phoneCode: '973'
  },
  {
    nameES: 'Bangladesh',
    nameEN: 'Bangladesh',
    iso2: 'BD',
    iso3: 'BGD',
    phoneCode: '880'
  },
  {
    nameES: 'Barbados',
    nameEN: 'Barbados',
    iso2: 'BB',
    iso3: 'BRB',
    phoneCode: '1 246'
  },
  {
    nameES: 'Belice',
    nameEN: 'Belize',
    iso2: 'BZ',
    iso3: 'BLZ',
    phoneCode: '501'
  },
  {
    nameES: 'Benín',
    nameEN: 'Benin',
    iso2: 'BJ',
    iso3: 'BEN',
    phoneCode: '229'
  },
  {
    nameES: 'Bhután',
    nameEN: 'Bhutan',
    iso2: 'BT',
    iso3: 'BTN',
    phoneCode: '975'
  },
  {
    nameES: 'Bielorrusia',
    nameEN: 'Belarus',
    iso2: 'BY',
    iso3: 'BLR',
    phoneCode: '375'
  },
  {
    nameES: 'Birmania',
    nameEN: 'Myanmar',
    iso2: 'MM',
    iso3: 'MMR',
    phoneCode: '95'
  },
  {
    nameES: 'Bolivia',
    nameEN: 'Bolivia',
    iso2: 'BO',
    iso3: 'BOL',
    phoneCode: '591'
  },
  {
    nameES: 'Bosnia y Herzegovina',
    nameEN: 'Bosnia and Herzegovina',
    iso2: 'BA',
    iso3: 'BIH',
    phoneCode: '387'
  },
  {
    nameES: 'Botsuana',
    nameEN: 'Botswana',
    iso2: 'BW',
    iso3: 'BWA',
    phoneCode: '267'
  },
  {
    nameES: 'Brasil',
    nameEN: 'Brazil',
    iso2: 'BR',
    iso3: 'BRA',
    phoneCode: '55'
  },
  {
    nameES: 'Brunéi',
    nameEN: 'Brunei',
    iso2: 'BN',
    iso3: 'BRN',
    phoneCode: '673'
  },
  {
    nameES: 'Bulgaria',
    nameEN: 'Bulgaria',
    iso2: 'BG',
    iso3: 'BGR',
    phoneCode: '359'
  },
  {
    nameES: 'Burkina Faso',
    nameEN: 'Burkina Faso',
    iso2: 'BF',
    iso3: 'BFA',
    phoneCode: '226'
  },
  {
    nameES: 'Burundi',
    nameEN: 'Burundi',
    iso2: 'BI',
    iso3: 'BDI',
    phoneCode: '257'
  },
  {
    nameES: 'Cabo Verde',
    nameEN: 'Cape Verde',
    iso2: 'CV',
    iso3: 'CPV',
    phoneCode: '238'
  },
  {
    nameES: 'Camboya',
    nameEN: 'Cambodia',
    iso2: 'KH',
    iso3: 'KHM',
    phoneCode: '855'
  },
  {
    nameES: 'Camerún',
    nameEN: 'Cameroon',
    iso2: 'CM',
    iso3: 'CMR',
    phoneCode: '237'
  },
  {
    nameES: 'Canadá',
    nameEN: 'Canada',
    iso2: 'CA',
    iso3: 'CAN',
    phoneCode: '1'
  },
  {
    nameES: 'Chad',
    nameEN: 'Chad',
    iso2: 'TD',
    iso3: 'TCD',
    phoneCode: '235'
  },
  {
    nameES: 'Chile',
    nameEN: 'Chile',
    iso2: 'CL',
    iso3: 'CHL',
    phoneCode: '56'
  },
  {
    nameES: 'China',
    nameEN: 'China',
    iso2: 'CN',
    iso3: 'CHN',
    phoneCode: '86'
  },
  {
    nameES: 'Chipre',
    nameEN: 'Cyprus',
    iso2: 'CY',
    iso3: 'CYP',
    phoneCode: '357'
  },
  {
    nameES: 'Ciudad del Vaticano',
    nameEN: 'Vatican City State',
    iso2: 'VA',
    iso3: 'VAT',
    phoneCode: '39'
  },
  {
    nameES: 'Colombia',
    nameEN: 'Colombia',
    iso2: 'CO',
    iso3: 'COL',
    phoneCode: '57'
  },
  {
    nameES: 'Comoras',
    nameEN: 'Comoros',
    iso2: 'KM',
    iso3: 'COM',
    phoneCode: '269'
  },
  {
    nameES: 'República del Congo',
    nameEN: 'Republic of the Congo',
    iso2: 'CG',
    iso3: 'COG',
    phoneCode: '242'
  },
  {
    nameES: 'República Democrática del Congo',
    nameEN: 'Democratic Republic of the Congo',
    iso2: 'CD',
    iso3: 'COD',
    phoneCode: '243'
  },
  {
    nameES: 'Corea del Norte',
    nameEN: 'North Korea',
    iso2: 'KP',
    iso3: 'PRK',
    phoneCode: '850'
  },
  {
    nameES: 'Corea del Sur',
    nameEN: 'South Korea',
    iso2: 'KR',
    iso3: 'KOR',
    phoneCode: '82'
  },
  {
    nameES: 'Costa de Marfil',
    nameEN: 'Ivory Coast',
    iso2: 'CI',
    iso3: 'CIV',
    phoneCode: '225'
  },
  {
    nameES: 'Costa Rica',
    nameEN: 'Costa Rica',
    iso2: 'CR',
    iso3: 'CRI',
    phoneCode: '506'
  },
  {
    nameES: 'Croacia',
    nameEN: 'Croatia',
    iso2: 'HR',
    iso3: 'HRV',
    phoneCode: '385'
  },
  {
    nameES: 'Cuba',
    nameEN: 'Cuba',
    iso2: 'CU',
    iso3: 'CUB',
    phoneCode: '53'
  },
  {
    nameES: 'Curazao',
    nameEN: 'Curaçao',
    iso2: 'CW',
    iso3: 'CWU',
    phoneCode: '5999'
  },
  {
    nameES: 'Dinamarca',
    nameEN: 'Denmark',
    iso2: 'DK',
    iso3: 'DNK',
    phoneCode: '45'
  },
  {
    nameES: 'Dominica',
    nameEN: 'Dominica',
    iso2: 'DM',
    iso3: 'DMA',
    phoneCode: '1 767'
  },
  {
    nameES: 'Ecuador',
    nameEN: 'Ecuador',
    iso2: 'EC',
    iso3: 'ECU',
    phoneCode: '593'
  },
  {
    nameES: 'Egipto',
    nameEN: 'Egypt',
    iso2: 'EG',
    iso3: 'EGY',
    phoneCode: '20'
  },
  {
    nameES: 'El Salvador',
    nameEN: 'El Salvador',
    iso2: 'SV',
    iso3: 'SLV',
    phoneCode: '503'
  },
  {
    nameES: 'Emiratos Árabes Unidos',
    nameEN: 'United Arab Emirates',
    iso2: 'AE',
    iso3: 'ARE',
    phoneCode: '971'
  },
  {
    nameES: 'Eritrea',
    nameEN: 'Eritrea',
    iso2: 'ER',
    iso3: 'ERI',
    phoneCode: '291'
  },
  {
    nameES: 'Eslovaquia',
    nameEN: 'Slovakia',
    iso2: 'SK',
    iso3: 'SVK',
    phoneCode: '421'
  },
  {
    nameES: 'Eslovenia',
    nameEN: 'Slovenia',
    iso2: 'SI',
    iso3: 'SVN',
    phoneCode: '386'
  },
  {
    nameES: 'España',
    nameEN: 'Spain',
    iso2: 'ES',
    iso3: 'ESP',
    phoneCode: '34'
  },
  {
    nameES: 'Estados Unidos de América',
    nameEN: 'United States of America',
    iso2: 'US',
    iso3: 'USA',
    phoneCode: '1'
  },
  {
    nameES: 'Estonia',
    nameEN: 'Estonia',
    iso2: 'EE',
    iso3: 'EST',
    phoneCode: '372'
  },
  {
    nameES: 'Etiopía',
    nameEN: 'Ethiopia',
    iso2: 'ET',
    iso3: 'ETH',
    phoneCode: '251'
  },
  {
    nameES: 'Filipinas',
    nameEN: 'Philippines',
    iso2: 'PH',
    iso3: 'PHL',
    phoneCode: '63'
  },
  {
    nameES: 'Finlandia',
    nameEN: 'Finland',
    iso2: 'FI',
    iso3: 'FIN',
    phoneCode: '358'
  },
  {
    nameES: 'Fiyi',
    nameEN: 'Fiji',
    iso2: 'FJ',
    iso3: 'FJI',
    phoneCode: '679'
  },
  {
    nameES: 'Francia',
    nameEN: 'France',
    iso2: 'FR',
    iso3: 'FRA',
    phoneCode: '33'
  },
  {
    nameES: 'Gabón',
    nameEN: 'Gabon',
    iso2: 'GA',
    iso3: 'GAB',
    phoneCode: '241'
  },
  {
    nameES: 'Gambia',
    nameEN: 'Gambia',
    iso2: 'GM',
    iso3: 'GMB',
    phoneCode: '220'
  },
  {
    nameES: 'Georgia',
    nameEN: 'Georgia',
    iso2: 'GE',
    iso3: 'GEO',
    phoneCode: '995'
  },
  {
    nameES: 'Ghana',
    nameEN: 'Ghana',
    iso2: 'GH',
    iso3: 'GHA',
    phoneCode: '233'
  },
  {
    nameES: 'Gibraltar',
    nameEN: 'Gibraltar',
    iso2: 'GI',
    iso3: 'GIB',
    phoneCode: '350'
  },
  {
    nameES: 'Granada',
    nameEN: 'Grenada',
    iso2: 'GD',
    iso3: 'GRD',
    phoneCode: '1 473'
  },
  {
    nameES: 'Grecia',
    nameEN: 'Greece',
    iso2: 'GR',
    iso3: 'GRC',
    phoneCode: '30'
  },
  {
    nameES: 'Groenlandia',
    nameEN: 'Greenland',
    iso2: 'GL',
    iso3: 'GRL',
    phoneCode: '299'
  },
  {
    nameES: 'Guadalupe',
    nameEN: 'Guadeloupe',
    iso2: 'GP',
    iso3: 'GLP',
    phoneCode: '590'
  },
  {
    nameES: 'Guam',
    nameEN: 'Guam',
    iso2: 'GU',
    iso3: 'GUM',
    phoneCode: '1 671'
  },
  {
    nameES: 'Guatemala',
    nameEN: 'Guatemala',
    iso2: 'GT',
    iso3: 'GTM',
    phoneCode: '502'
  },
  {
    nameES: 'Guayana Francesa',
    nameEN: 'French Guiana',
    iso2: 'GF',
    iso3: 'GUF',
    phoneCode: '594'
  },
  {
    nameES: 'Guernsey',
    nameEN: 'Guernsey',
    iso2: 'GG',
    iso3: 'GGY',
    phoneCode: '44'
  },
  {
    nameES: 'Guinea',
    nameEN: 'Guinea',
    iso2: 'GN',
    iso3: 'GIN',
    phoneCode: '224'
  },
  {
    nameES: 'Guinea Ecuatorial',
    nameEN: 'Equatorial Guinea',
    iso2: 'GQ',
    iso3: 'GNQ',
    phoneCode: '240'
  },
  {
    nameES: 'Guinea-Bissau',
    nameEN: 'Guinea-Bissau',
    iso2: 'GW',
    iso3: 'GNB',
    phoneCode: '245'
  },
  {
    nameES: 'Guyana',
    nameEN: 'Guyana',
    iso2: 'GY',
    iso3: 'GUY',
    phoneCode: '592'
  },
  {
    nameES: 'Haití',
    nameEN: 'Haiti',
    iso2: 'HT',
    iso3: 'HTI',
    phoneCode: '509'
  },
  {
    nameES: 'Honduras',
    nameEN: 'Honduras',
    iso2: 'HN',
    iso3: 'HND',
    phoneCode: '504'
  },
  {
    nameES: 'Hong kong',
    nameEN: 'Hong Kong',
    iso2: 'HK',
    iso3: 'HKG',
    phoneCode: '852'
  },
  {
    nameES: 'Hungría',
    nameEN: 'Hungary',
    iso2: 'HU',
    iso3: 'HUN',
    phoneCode: '36'
  },
  {
    nameES: 'India',
    nameEN: 'India',
    iso2: 'IN',
    iso3: 'IND',
    phoneCode: '91'
  },
  {
    nameES: 'Indonesia',
    nameEN: 'Indonesia',
    iso2: 'ID',
    iso3: 'IDN',
    phoneCode: '62'
  },
  {
    nameES: 'Irán',
    nameEN: 'Iran',
    iso2: 'IR',
    iso3: 'IRN',
    phoneCode: '98'
  },
  {
    nameES: 'Irak',
    nameEN: 'Iraq',
    iso2: 'IQ',
    iso3: 'IRQ',
    phoneCode: '964'
  },
  {
    nameES: 'Irlanda',
    nameEN: 'Ireland',
    iso2: 'IE',
    iso3: 'IRL',
    phoneCode: '353'
  },
  {
    nameES: 'Isla Bouvet',
    nameEN: 'Bouvet Island',
    iso2: 'BV',
    iso3: 'BVT',
    phoneCode: ''
  },
  {
    nameES: 'Isla de Man',
    nameEN: 'Isle of Man',
    iso2: 'IM',
    iso3: 'IMN',
    phoneCode: '44'
  },
  {
    nameES: 'Isla de Navidad',
    nameEN: 'Christmas Island',
    iso2: 'CX',
    iso3: 'CXR',
    phoneCode: '61'
  },
  {
    nameES: 'Isla Norfolk',
    nameEN: 'Norfolk Island',
    iso2: 'NF',
    iso3: 'NFK',
    phoneCode: '672'
  },
  {
    nameES: 'Islandia',
    nameEN: 'Iceland',
    iso2: 'IS',
    iso3: 'ISL',
    phoneCode: '354'
  },
  {
    nameES: 'Islas Bermudas',
    nameEN: 'Bermuda Islands',
    iso2: 'BM',
    iso3: 'BMU',
    phoneCode: '1 441'
  },
  {
    nameES: 'Islas Caimán',
    nameEN: 'Cayman Islands',
    iso2: 'KY',
    iso3: 'CYM',
    phoneCode: '1 345'
  },
  {
    nameES: 'Islas Cocos (Keeling)',
    nameEN: 'Cocos (Keeling) Islands',
    iso2: 'CC',
    iso3: 'CCK',
    phoneCode: '61'
  },
  {
    nameES: 'Islas Cook',
    nameEN: 'Cook Islands',
    iso2: 'CK',
    iso3: 'COK',
    phoneCode: '682'
  },
  {
    nameES: 'Islas de Åland',
    nameEN: 'Åland Islands',
    iso2: 'AX',
    iso3: 'ALA',
    phoneCode: '358'
  },
  {
    nameES: 'Islas Feroe',
    nameEN: 'Faroe Islands',
    iso2: 'FO',
    iso3: 'FRO',
    phoneCode: '298'
  },
  {
    nameES: 'Islas Georgias del Sur y Sandwich del Sur',
    nameEN: 'South Georgia and the South Sandwich Islands',
    iso2: 'GS',
    iso3: 'SGS',
    phoneCode: '500'
  },
  {
    nameES: 'Islas Heard y McDonald',
    nameEN: 'Heard Island and McDonald Islands',
    iso2: 'HM',
    iso3: 'HMD',
    phoneCode: ''
  },
  {
    nameES: 'Islas Maldivas',
    nameEN: 'Maldives',
    iso2: 'MV',
    iso3: 'MDV',
    phoneCode: '960'
  },
  {
    nameES: 'Islas Malvinas',
    nameEN: 'Falkland Islands (Malvinas)',
    iso2: 'FK',
    iso3: 'FLK',
    phoneCode: '500'
  },
  {
    nameES: 'Islas Marianas del Norte',
    nameEN: 'Northern Mariana Islands',
    iso2: 'MP',
    iso3: 'MNP',
    phoneCode: '1 670'
  },
  {
    nameES: 'Islas Marshall',
    nameEN: 'Marshall Islands',
    iso2: 'MH',
    iso3: 'MHL',
    phoneCode: '692'
  },
  {
    nameES: 'Islas Pitcairn',
    nameEN: 'Pitcairn Islands',
    iso2: 'PN',
    iso3: 'PCN',
    phoneCode: '870'
  },
  {
    nameES: 'Islas Salomón',
    nameEN: 'Solomon Islands',
    iso2: 'SB',
    iso3: 'SLB',
    phoneCode: '677'
  },
  {
    nameES: 'Islas Turcas y Caicos',
    nameEN: 'Turks and Caicos Islands',
    iso2: 'TC',
    iso3: 'TCA',
    phoneCode: '1 649'
  },
  {
    nameES: 'Islas Ultramarinas Menores de Estados Unidos',
    nameEN: 'United States Minor Outlying Islands',
    iso2: 'UM',
    iso3: 'UMI',
    phoneCode: '246'
  },
  {
    nameES: 'Islas Vírgenes Británicas',
    nameEN: 'Virgin Islands',
    iso2: 'VG',
    iso3: 'VGB',
    phoneCode: '1 284'
  },
  {
    nameES: 'Islas Vírgenes de los Estados Unidos',
    nameEN: 'United States Virgin Islands',
    iso2: 'VI',
    iso3: 'VIR',
    phoneCode: '1 340'
  },
  {
    nameES: 'Israel',
    nameEN: 'Israel',
    iso2: 'IL',
    iso3: 'ISR',
    phoneCode: '972'
  },
  {
    nameES: 'Italia',
    nameEN: 'Italy',
    iso2: 'IT',
    iso3: 'ITA',
    phoneCode: '39'
  },
  {
    nameES: 'Jamaica',
    nameEN: 'Jamaica',
    iso2: 'JM',
    iso3: 'JAM',
    phoneCode: '1 876'
  },
  {
    nameES: 'Japón',
    nameEN: 'Japan',
    iso2: 'JP',
    iso3: 'JPN',
    phoneCode: '81'
  },
  {
    nameES: 'Jersey',
    nameEN: 'Jersey',
    iso2: 'JE',
    iso3: 'JEY',
    phoneCode: '44'
  },
  {
    nameES: 'Jordania',
    nameEN: 'Jordan',
    iso2: 'JO',
    iso3: 'JOR',
    phoneCode: '962'
  },
  {
    nameES: 'Kazajistán',
    nameEN: 'Kazakhstan',
    iso2: 'KZ',
    iso3: 'KAZ',
    phoneCode: '7'
  },
  {
    nameES: 'Kenia',
    nameEN: 'Kenya',
    iso2: 'KE',
    iso3: 'KEN',
    phoneCode: '254'
  },
  {
    nameES: 'Kirguistán',
    nameEN: 'Kyrgyzstan',
    iso2: 'KG',
    iso3: 'KGZ',
    phoneCode: '996'
  },
  {
    nameES: 'Kiribati',
    nameEN: 'Kiribati',
    iso2: 'KI',
    iso3: 'KIR',
    phoneCode: '686'
  },
  {
    nameES: 'Kuwait',
    nameEN: 'Kuwait',
    iso2: 'KW',
    iso3: 'KWT',
    phoneCode: '965'
  },
  {
    nameES: 'Líbano',
    nameEN: 'Lebanon',
    iso2: 'LB',
    iso3: 'LBN',
    phoneCode: '961'
  },
  {
    nameES: 'Laos',
    nameEN: 'Laos',
    iso2: 'LA',
    iso3: 'LAO',
    phoneCode: '856'
  },
  {
    nameES: 'Lesoto',
    nameEN: 'Lesotho',
    iso2: 'LS',
    iso3: 'LSO',
    phoneCode: '266'
  },
  {
    nameES: 'Letonia',
    nameEN: 'Latvia',
    iso2: 'LV',
    iso3: 'LVA',
    phoneCode: '371'
  },
  {
    nameES: 'Liberia',
    nameEN: 'Liberia',
    iso2: 'LR',
    iso3: 'LBR',
    phoneCode: '231'
  },
  {
    nameES: 'Libia',
    nameEN: 'Libya',
    iso2: 'LY',
    iso3: 'LBY',
    phoneCode: '218'
  },
  {
    nameES: 'Liechtenstein',
    nameEN: 'Liechtenstein',
    iso2: 'LI',
    iso3: 'LIE',
    phoneCode: '423'
  },
  {
    nameES: 'Lituania',
    nameEN: 'Lithuania',
    iso2: 'LT',
    iso3: 'LTU',
    phoneCode: '370'
  },
  {
    nameES: 'Luxemburgo',
    nameEN: 'Luxembourg',
    iso2: 'LU',
    iso3: 'LUX',
    phoneCode: '352'
  },
  {
    nameES: 'México',
    nameEN: 'Mexico',
    iso2: 'MX',
    iso3: 'MEX',
    phoneCode: '52'
  },
  {
    nameES: 'Mónaco',
    nameEN: 'Monaco',
    iso2: 'MC',
    iso3: 'MCO',
    phoneCode: '377'
  },
  {
    nameES: 'Macao',
    nameEN: 'Macao',
    iso2: 'MO',
    iso3: 'MAC',
    phoneCode: '853'
  },
  {
    nameES: 'Macedônia',
    nameEN: 'Macedonia',
    iso2: 'MK',
    iso3: 'MKD',
    phoneCode: '389'
  },
  {
    nameES: 'Madagascar',
    nameEN: 'Madagascar',
    iso2: 'MG',
    iso3: 'MDG',
    phoneCode: '261'
  },
  {
    nameES: 'Malasia',
    nameEN: 'Malaysia',
    iso2: 'MY',
    iso3: 'MYS',
    phoneCode: '60'
  },
  {
    nameES: 'Malawi',
    nameEN: 'Malawi',
    iso2: 'MW',
    iso3: 'MWI',
    phoneCode: '265'
  },
  {
    nameES: 'Mali',
    nameEN: 'Mali',
    iso2: 'ML',
    iso3: 'MLI',
    phoneCode: '223'
  },
  {
    nameES: 'Malta',
    nameEN: 'Malta',
    iso2: 'MT',
    iso3: 'MLT',
    phoneCode: '356'
  },
  {
    nameES: 'Marruecos',
    nameEN: 'Morocco',
    iso2: 'MA',
    iso3: 'MAR',
    phoneCode: '212'
  },
  {
    nameES: 'Martinica',
    nameEN: 'Martinique',
    iso2: 'MQ',
    iso3: 'MTQ',
    phoneCode: '596'
  },
  {
    nameES: 'Mauricio',
    nameEN: 'Mauritius',
    iso2: 'MU',
    iso3: 'MUS',
    phoneCode: '230'
  },
  {
    nameES: 'Mauritania',
    nameEN: 'Mauritania',
    iso2: 'MR',
    iso3: 'MRT',
    phoneCode: '222'
  },
  {
    nameES: 'Mayotte',
    nameEN: 'Mayotte',
    iso2: 'YT',
    iso3: 'MYT',
    phoneCode: '262'
  },
  {
    nameES: 'Micronesia',
    nameEN: 'Estados Federados de',
    iso2: 'FM',
    iso3: 'FSM',
    phoneCode: '691'
  },
  {
    nameES: 'Moldavia',
    nameEN: 'Moldova',
    iso2: 'MD',
    iso3: 'MDA',
    phoneCode: '373'
  },
  {
    nameES: 'Mongolia',
    nameEN: 'Mongolia',
    iso2: 'MN',
    iso3: 'MNG',
    phoneCode: '976'
  },
  {
    nameES: 'Montenegro',
    nameEN: 'Montenegro',
    iso2: 'ME',
    iso3: 'MNE',
    phoneCode: '382'
  },
  {
    nameES: 'Montserrat',
    nameEN: 'Montserrat',
    iso2: 'MS',
    iso3: 'MSR',
    phoneCode: '1 664'
  },
  {
    nameES: 'Mozambique',
    nameEN: 'Mozambique',
    iso2: 'MZ',
    iso3: 'MOZ',
    phoneCode: '258'
  },
  {
    nameES: 'Namibia',
    nameEN: 'Namibia',
    iso2: 'NA',
    iso3: 'NAM',
    phoneCode: '264'
  },
  {
    nameES: 'Nauru',
    nameEN: 'Nauru',
    iso2: 'NR',
    iso3: 'NRU',
    phoneCode: '674'
  },
  {
    nameES: 'Nepal',
    nameEN: 'Nepal',
    iso2: 'NP',
    iso3: 'NPL',
    phoneCode: '977'
  },
  {
    nameES: 'Nicaragua',
    nameEN: 'Nicaragua',
    iso2: 'NI',
    iso3: 'NIC',
    phoneCode: '505'
  },
  {
    nameES: 'Niger',
    nameEN: 'Niger',
    iso2: 'NE',
    iso3: 'NER',
    phoneCode: '227'
  },
  {
    nameES: 'Nigeria',
    nameEN: 'Nigeria',
    iso2: 'NG',
    iso3: 'NGA',
    phoneCode: '234'
  },
  {
    nameES: 'Niue',
    nameEN: 'Niue',
    iso2: 'NU',
    iso3: 'NIU',
    phoneCode: '683'
  },
  {
    nameES: 'Noruega',
    nameEN: 'Norway',
    iso2: 'NO',
    iso3: 'NOR',
    phoneCode: '47'
  },
  {
    nameES: 'Nueva Caledonia',
    nameEN: 'New Caledonia',
    iso2: 'NC',
    iso3: 'NCL',
    phoneCode: '687'
  },
  {
    nameES: 'Nueva Zelanda',
    nameEN: 'New Zealand',
    iso2: 'NZ',
    iso3: 'NZL',
    phoneCode: '64'
  },
  {
    nameES: 'Omán',
    nameEN: 'Oman',
    iso2: 'OM',
    iso3: 'OMN',
    phoneCode: '968'
  },
  {
    nameES: 'Países Bajos',
    nameEN: 'Netherlands',
    iso2: 'NL',
    iso3: 'NLD',
    phoneCode: '31'
  },
  {
    nameES: 'Pakistán',
    nameEN: 'Pakistan',
    iso2: 'PK',
    iso3: 'PAK',
    phoneCode: '92'
  },
  {
    nameES: 'Palau',
    nameEN: 'Palau',
    iso2: 'PW',
    iso3: 'PLW',
    phoneCode: '680'
  },
  {
    nameES: 'Palestina',
    nameEN: 'Palestine',
    iso2: 'PS',
    iso3: 'PSE',
    phoneCode: '970'
  },
  {
    nameES: 'Panamá',
    nameEN: 'Panama',
    iso2: 'PA',
    iso3: 'PAN',
    phoneCode: '507'
  },
  {
    nameES: 'Papúa Nueva Guinea',
    nameEN: 'Papua New Guinea',
    iso2: 'PG',
    iso3: 'PNG',
    phoneCode: '675'
  },
  {
    nameES: 'Paraguay',
    nameEN: 'Paraguay',
    iso2: 'PY',
    iso3: 'PRY',
    phoneCode: '595'
  },
  {
    nameES: 'Perú',
    nameEN: 'Peru',
    iso2: 'PE',
    iso3: 'PER',
    phoneCode: '51'
  },
  {
    nameES: 'Polinesia Francesa',
    nameEN: 'French Polynesia',
    iso2: 'PF',
    iso3: 'PYF',
    phoneCode: '689'
  },
  {
    nameES: 'Polonia',
    nameEN: 'Poland',
    iso2: 'PL',
    iso3: 'POL',
    phoneCode: '48'
  },
  {
    nameES: 'Portugal',
    nameEN: 'Portugal',
    iso2: 'PT',
    iso3: 'PRT',
    phoneCode: '351'
  },
  {
    nameES: 'Puerto Rico',
    nameEN: 'Puerto Rico',
    iso2: 'PR',
    iso3: 'PRI',
    phoneCode: '1'
  },
  {
    nameES: 'Qatar',
    nameEN: 'Qatar',
    iso2: 'QA',
    iso3: 'QAT',
    phoneCode: '974'
  },
  {
    nameES: 'Reino Unido',
    nameEN: 'United Kingdom',
    iso2: 'GB',
    iso3: 'GBR',
    phoneCode: '44'
  },
  {
    nameES: 'República Centroafricana',
    nameEN: 'Central African Republic',
    iso2: 'CF',
    iso3: 'CAF',
    phoneCode: '236'
  },
  {
    nameES: 'República Checa',
    nameEN: 'Czech Republic',
    iso2: 'CZ',
    iso3: 'CZE',
    phoneCode: '420'
  },
  {
    nameES: 'República Dominicana',
    nameEN: 'Dominican Republic',
    iso2: 'DO',
    iso3: 'DOM',
    phoneCode: '1 809'
  },
  {
    nameES: 'República de Sudán del Sur',
    nameEN: 'South Sudan',
    iso2: 'SS',
    iso3: 'SSD',
    phoneCode: '211'
  },
  {
    nameES: 'Reunión',
    nameEN: 'Réunion',
    iso2: 'RE',
    iso3: 'REU',
    phoneCode: '262'
  },
  {
    nameES: 'Ruanda',
    nameEN: 'Rwanda',
    iso2: 'RW',
    iso3: 'RWA',
    phoneCode: '250'
  },
  {
    nameES: 'Rumanía',
    nameEN: 'Romania',
    iso2: 'RO',
    iso3: 'ROU',
    phoneCode: '40'
  },
  {
    nameES: 'Rusia',
    nameEN: 'Russia',
    iso2: 'RU',
    iso3: 'RUS',
    phoneCode: '7'
  },
  {
    nameES: 'Sahara Occidental',
    nameEN: 'Western Sahara',
    iso2: 'EH',
    iso3: 'ESH',
    phoneCode: '212'
  },
  {
    nameES: 'Samoa',
    nameEN: 'Samoa',
    iso2: 'WS',
    iso3: 'WSM',
    phoneCode: '685'
  },
  {
    nameES: 'Samoa Americana',
    nameEN: 'American Samoa',
    iso2: 'AS',
    iso3: 'ASM',
    phoneCode: '1 684'
  },
  {
    nameES: 'San Bartolomé',
    nameEN: 'Saint Barthélemy',
    iso2: 'BL',
    iso3: 'BLM',
    phoneCode: '590'
  },
  {
    nameES: 'San Cristóbal y Nieves',
    nameEN: 'Saint Kitts and Nevis',
    iso2: 'KN',
    iso3: 'KNA',
    phoneCode: '1 869'
  },
  {
    nameES: 'San Marino',
    nameEN: 'San Marino',
    iso2: 'SM',
    iso3: 'SMR',
    phoneCode: '378'
  },
  {
    nameES: 'San Martín (Francia)',
    nameEN: 'Saint Martin (French part)',
    iso2: 'MF',
    iso3: 'MAF',
    phoneCode: '1 599'
  },
  {
    nameES: 'San Pedro y Miquelón',
    nameEN: 'Saint Pierre and Miquelon',
    iso2: 'PM',
    iso3: 'SPM',
    phoneCode: '508'
  },
  {
    nameES: 'San Vicente y las Granadinas',
    nameEN: 'Saint Vincent and the Grenadines',
    iso2: 'VC',
    iso3: 'VCT',
    phoneCode: '1 784'
  },
  {
    nameES: 'Santa Elena',
    nameEN: 'Ascensión y Tristán de Acuña',
    iso2: 'SH',
    iso3: 'SHN',
    phoneCode: '290'
  },
  {
    nameES: 'Santa Lucía',
    nameEN: 'Saint Lucia',
    iso2: 'LC',
    iso3: 'LCA',
    phoneCode: '1 758'
  },
  {
    nameES: 'Santo Tomé y Príncipe',
    nameEN: 'Sao Tome and Principe',
    iso2: 'ST',
    iso3: 'STP',
    phoneCode: '239'
  },
  {
    nameES: 'Senegal',
    nameEN: 'Senegal',
    iso2: 'SN',
    iso3: 'SEN',
    phoneCode: '221'
  },
  {
    nameES: 'Serbia',
    nameEN: 'Serbia',
    iso2: 'RS',
    iso3: 'SRB',
    phoneCode: '381'
  },
  {
    nameES: 'Seychelles',
    nameEN: 'Seychelles',
    iso2: 'SC',
    iso3: 'SYC',
    phoneCode: '248'
  },
  {
    nameES: 'Sierra Leona',
    nameEN: 'Sierra Leone',
    iso2: 'SL',
    iso3: 'SLE',
    phoneCode: '232'
  },
  {
    nameES: 'Singapur',
    nameEN: 'Singapore',
    iso2: 'SG',
    iso3: 'SGP',
    phoneCode: '65'
  },
  {
    nameES: 'Sint Maarten',
    nameEN: 'Sint Maarten',
    iso2: 'SX',
    iso3: 'SMX',
    phoneCode: '1 721'
  },
  {
    nameES: 'Siria',
    nameEN: 'Syria',
    iso2: 'SY',
    iso3: 'SYR',
    phoneCode: '963'
  },
  {
    nameES: 'Somalia',
    nameEN: 'Somalia',
    iso2: 'SO',
    iso3: 'SOM',
    phoneCode: '252'
  },
  {
    nameES: 'Sri lanka',
    nameEN: 'Sri Lanka',
    iso2: 'LK',
    iso3: 'LKA',
    phoneCode: '94'
  },
  {
    nameES: 'Sudáfrica',
    nameEN: 'South Africa',
    iso2: 'ZA',
    iso3: 'ZAF',
    phoneCode: '27'
  },
  {
    nameES: 'Sudán',
    nameEN: 'Sudan',
    iso2: 'SD',
    iso3: 'SDN',
    phoneCode: '249'
  },
  {
    nameES: 'Suecia',
    nameEN: 'Sweden',
    iso2: 'SE',
    iso3: 'SWE',
    phoneCode: '46'
  },
  {
    nameES: 'Suiza',
    nameEN: 'Switzerland',
    iso2: 'CH',
    iso3: 'CHE',
    phoneCode: '41'
  },
  {
    nameES: 'Surinám',
    nameEN: 'Suriname',
    iso2: 'SR',
    iso3: 'SUR',
    phoneCode: '597'
  },
  {
    nameES: 'Svalbard y Jan Mayen',
    nameEN: 'Svalbard and Jan Mayen',
    iso2: 'SJ',
    iso3: 'SJM',
    phoneCode: '47'
  },
  {
    nameES: 'Swazilandia',
    nameEN: 'Swaziland',
    iso2: 'SZ',
    iso3: 'SWZ',
    phoneCode: '268'
  },
  {
    nameES: 'Tayikistán',
    nameEN: 'Tajikistan',
    iso2: 'TJ',
    iso3: 'TJK',
    phoneCode: '992'
  },
  {
    nameES: 'Tailandia',
    nameEN: 'Thailand',
    iso2: 'TH',
    iso3: 'THA',
    phoneCode: '66'
  },
  {
    nameES: 'Taiwán',
    nameEN: 'Taiwan',
    iso2: 'TW',
    iso3: 'TWN',
    phoneCode: '886'
  },
  {
    nameES: 'Tanzania',
    nameEN: 'Tanzania',
    iso2: 'TZ',
    iso3: 'TZA',
    phoneCode: '255'
  },
  {
    nameES: 'Territorio Británico del Océano Índico',
    nameEN: 'British Indian Ocean Territory',
    iso2: 'IO',
    iso3: 'IOT',
    phoneCode: '246'
  },
  {
    nameES: 'Territorios Australes y Antárticas Franceses',
    nameEN: 'French Southern Territories',
    iso2: 'TF',
    iso3: 'ATF',
    phoneCode: ''
  },
  {
    nameES: 'Timor Oriental',
    nameEN: 'East Timor',
    iso2: 'TL',
    iso3: 'TLS',
    phoneCode: '670'
  },
  {
    nameES: 'Togo',
    nameEN: 'Togo',
    iso2: 'TG',
    iso3: 'TGO',
    phoneCode: '228'
  },
  {
    nameES: 'Tokelau',
    nameEN: 'Tokelau',
    iso2: 'TK',
    iso3: 'TKL',
    phoneCode: '690'
  },
  {
    nameES: 'Tonga',
    nameEN: 'Tonga',
    iso2: 'TO',
    iso3: 'TON',
    phoneCode: '676'
  },
  {
    nameES: 'Trinidad y Tobago',
    nameEN: 'Trinidad and Tobago',
    iso2: 'TT',
    iso3: 'TTO',
    phoneCode: '1 868'
  },
  {
    nameES: 'Tunez',
    nameEN: 'Tunisia',
    iso2: 'TN',
    iso3: 'TUN',
    phoneCode: '216'
  },
  {
    nameES: 'Turkmenistán',
    nameEN: 'Turkmenistan',
    iso2: 'TM',
    iso3: 'TKM',
    phoneCode: '993'
  },
  {
    nameES: 'Turquía',
    nameEN: 'Turkey',
    iso2: 'TR',
    iso3: 'TUR',
    phoneCode: '90'
  },
  {
    nameES: 'Tuvalu',
    nameEN: 'Tuvalu',
    iso2: 'TV',
    iso3: 'TUV',
    phoneCode: '688'
  },
  {
    nameES: 'Ucrania',
    nameEN: 'Ukraine',
    iso2: 'UA',
    iso3: 'UKR',
    phoneCode: '380'
  },
  {
    nameES: 'Uganda',
    nameEN: 'Uganda',
    iso2: 'UG',
    iso3: 'UGA',
    phoneCode: '256'
  },
  {
    nameES: 'Uruguay',
    nameEN: 'Uruguay',
    iso2: 'UY',
    iso3: 'URY',
    phoneCode: '598'
  },
  {
    nameES: 'Uzbekistán',
    nameEN: 'Uzbekistan',
    iso2: 'UZ',
    iso3: 'UZB',
    phoneCode: '998'
  },
  {
    nameES: 'Vanuatu',
    nameEN: 'Vanuatu',
    iso2: 'VU',
    iso3: 'VUT',
    phoneCode: '678'
  },
  {
    nameES: 'Venezuela',
    nameEN: 'Venezuela',
    iso2: 'VE',
    iso3: 'VEN',
    phoneCode: '58'
  },
  {
    nameES: 'Vietnam',
    nameEN: 'Vietnam',
    iso2: 'VN',
    iso3: 'VNM',
    phoneCode: '84'
  },
  {
    nameES: 'Wallis y Futuna',
    nameEN: 'Wallis and Futuna',
    iso2: 'WF',
    iso3: 'WLF',
    phoneCode: '681'
  },
  {
    nameES: 'Yemen',
    nameEN: 'Yemen',
    iso2: 'YE',
    iso3: 'YEM',
    phoneCode: '967'
  },
  {
    nameES: 'Yibuti',
    nameEN: 'Djibouti',
    iso2: 'DJ',
    iso3: 'DJI',
    phoneCode: '253'
  },
  {
    nameES: 'Zambia',
    nameEN: 'Zambia',
    iso2: 'ZM',
    iso3: 'ZMB',
    phoneCode: '260'
  },
  {
    nameES: 'Zimbabue',
    nameEN: 'Zimbabwe',
    iso2: 'ZW',
    iso3: 'ZWE',
    phoneCode: '263'
  }
];
export const IDENTIFIED_SKILLS = [
  {
    id: 140,
    name: 'Negociación'
  },
  {
    id: 864,
    name: 'Creatividad'
  },
  {
    id: 5348,
    name: 'Control del estrés'
  },
  {
    id: 6762,
    name: 'Autoestima'
  },
  {
    id: 5354,
    name: 'Toma de decisiones'
  },
  {
    id: 243,
    name: 'Liderazgo'
  }
];

// filter constants
export const HOUR_FILTERS = {
  LESS_THAN_50: 1,
  BETWEEN_51_AND_200: 2,
  BETWEEN_201_AND_400: 3,
  OVER_THAN_401: 4
};

export const PRICES_FILTERS = {
  FREE: 1,
  LESS_THAN_49: 2,
  BETWEEN_50_AND_200: 3,
  BETWEEN_201_AND_500: 4,
  OVER_THAN_501: 5
};
