
export default {
  hardSkills: (state) => {
    return state.hardSkills;
  },
  profilesValidated: (state) => {
    return state.profilesValidated;
  },
  getTotalValidatedProfiles: (state) => {
    return state.totalValidatedProfiles;
  },
  profilesUnvalidated: (state) => {
    return state.profilesUnvalidated;
  },
  getTotalUnvalidatedProfiles: (state) => {
    return state.totalUnvalidatedProfiles;
  },
  profilesResults: (state) => {
    return state.profiles.results;
  },
  newSkillsResults: (state) => {
    return state.newSkills.results;
  },
  newSkillsCount: (state) => {
    return state.newSkills.count;
  },
  stopWordsResults: (state) => {
    return state.stopWords.results;
  },
  stopWordsCount: (state) => {
    return state.stopWords.count;
  },
  ranking: (state) => {
    return state.ranking;
  },
  listStopWords: (state) => {
    return state.listStopWords;
  },
  totalWords: (state) => {
    return state.totalWords;
  },
  skills: (state) => {
    return state.skills;
  },
  totalSkills: (state) => {
    return state.totalSkills;
  },
  validatedSkills: (state) => {
    return state.validatedSkills;
  },
  totalValidatedSkills: (state) => {
    return state.totalValidatedSkills;
  },
  mostDemandProfiles: (state) => {
    return state.mostDemandProfiles;
  },
  totalMostDemandProfiles: (state) => {
    return state.totalMostDemandProfiles;
  },
  validatedSkillsFilters: (state) => {
    return state.validatedSkillsFilters;
  },
  getJobProfileSkills (state) {
    return state.jobProfileSkills;
  },
  getJobProfileKnowledge (state) {
    return state.jobProfileKnowledge;
  },
  getTotalJobProfilesSkills (state) {
    return state.totalJobProfilesSkills;
  },
  getTotalJobProfilesKnowledge (state) {
    return state.totalJobProfilesKnowledge;
  },
  getProposedJobProfiles (state) {
    return state.fetchProposedProfiles;
  },
  getProposedJobProfile (state) {
    return state.proposedJobProfile;
  },
  getTotalProposedJobProfiles (state) {
    return state.totalProposedJobProfile;
  },
  getNotValidatedJobProfiles (state) {
    return state.notValidatedJobProfiles;
  },
  getTotalNotValidatedJobProfiles (state) {
    return state.totalNotValidatedJobProfiles;
  }
};
