<template>
  <v-row>
    <v-col cols="12">
      <v-card class="cookies-banner-container">
        <v-card-text>
          {{ $t('cookies.description') }}
        </v-card-text>
        <v-card-actions class="d-flex justify-center flex-wrap">
          <v-btn
            color="primary"
            @click="acceptAllCookies"
          >
            {{ $t('cookies.accept_all') }}
          </v-btn>

          <v-btn
            color="primary"
            @click="cancelAllCookies"
          >{{ $t('cookies.refuse_all') }}</v-btn>

          <v-btn
            color="primary"
            @click="$refs.cookiesManageDialog.open()"
            outlined
          >{{ $t('cookies.manage_cookies') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <cookies-manage-dialog
      :cookies="cookies"
      ref="cookiesManageDialog"
    />
  </v-row>
</template>

<script>
import CookiesManageDialog from '@/pages/application/CookiesManageDialog.vue';
export default {
  name: 'CookiesBanner',
  components: {
    CookiesManageDialog
  },
  props: {
    cookies: {
      type: Array,
      default: () => ([])
    }
  },
  methods: {
    acceptAllCookies () {
      this.$emit('acceptCookies');
    },
    cancelAllCookies () {
      this.$emit('confirmCookies');
    }
  }
};
</script>

<style scoped lang="scss">
.cookies-banner-container {
  position: fixed;
  max-width: 700px;
  width: 100%;
  right: 20px;
  bottom: 20px;
  border-radius: 21px;
  padding: 0 20px 20px;

  .v-card__text {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px;
  }

  :deep(.v-card__actions) {
    gap: 20px;

    span.v-btn__content {
      text-transform: initial;
    }
  }

  @media screen and (max-width: 735px) {
    bottom: 0;
    right: 0;
    max-width: 100%;
  }
}
</style>
