import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import user from '@/store/user';
import language from '@/store/language';
import application from '@/store/application';
import knowledge from '@/store/knowledge';
import education from '@/store/education';
import individual from '@/store/individual';
import manager from '@/store/manager';
import employee from '@/store/employee';
import validator from '@/store/validator';
import prices from '@/store/prices';
// init modules states
import initApplicationState from '@/store/application/state';
import initEducationState from '@/store/education/state';
import initEmployeeState from '@/store/employee/state';
import initIndividualState from '@/store/individual/state';
import initKnowledgeState from '@/store/knowledge/state';
import initLanguageState from '@/store/language/state';
import initManagerState from '@/store/manager/state';
import initPriceState from '@/store/prices/state';
import initUserState from '@/store/user/state';
import initValidatorState from '@/store/validator/state';

Vue.use(Vuex);

// init states
// Vuex Persistence
const vuexPersist = new VuexPersistence({
  storage: localStorage,
  modules: [
    'user',
    'language'
  ],
  strictMode: process.env.NODE_ENV !== 'production'
});

export default new Vuex.Store({
  plugins: [vuexPersist.plugin],
  modules: {
    user,
    language,
    application,
    knowledge,
    manager,
    education,
    individual,
    validator,
    employee,
    prices
  },
  mutations: {
    RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION, // this mutation **MUST** be named "RESTORE_MUTATION"
    RESET_STORE (state) {
      Object.assign(state.application, initApplicationState);
      Object.assign(state.education, initEducationState);
      Object.assign(state.employee, initEmployeeState);
      Object.assign(state.individual, initIndividualState);
      Object.assign(state.knowledge, initKnowledgeState);
      Object.assign(state.language, initLanguageState);
      Object.assign(state.prices, initPriceState);
      Object.assign(state.manager, initManagerState);
      Object.assign(state.user, initUserState);
      Object.assign(state.validator, initValidatorState);
    }
  },
  strict: process.env.NODE_ENV !== 'production'
});
