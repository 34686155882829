// Mutation Types
import * as types from './types';

export default {
  [types.REGISTER_INDIVIDUAL] (state, payload) {
    state.individual = payload.data;
  },
  [types.SET_INDIVIDUAL_BY_ID] (state, payload) {
    state.individual = payload;
  },
  [types.UPDATE_INDIVIDUAL] (state, payload) {
    state.individual = payload.data;
  },
  [types.UPDATE_AVATAR] (state, payload) {
    state.individual.avatar = payload;
  },
  [types.SET_RECOMMENDED_JOB_PROFILES] (state, payload) {
    state.recommendedJobProfiles = payload;
  },
  [types.SET_RECOMMENDED_COURSES] (state, payload) {
    state.recommendedCourses = payload;
  },
  [types.SET_TOTAL_RECOMMENDED_COURSES] (state, payload) {
    state.totalRecommendedCourses = payload;
  },
  [types.SET_TOTAL_RECOMMENDED_JOB_PROFILES] (state, payload) {
    state.totalRecommendedJobProfiles = payload;
  },
  [types.SET_COURSE_DETAIL] (state, payload) {
    state.courseDetail = payload;
  },
  [types.SET_JOB_PROFILE_DETAIL] (state, payload) {
    state.jobProfileDetail = payload;
  },
  [types.SET_MOST_DEMAND_COURSES] (state, payload) {
    state.mostDemandCourses = payload;
  },
  [types.SET_MOST_DEMAND_JOB_PROFILE] (state, payload) {
    state.mostDemandJobProfiles = payload;
  },
  [types.SET_PROFESSIONAL_CERTIFICATE] (state, payload) {
    state.certificates = payload;
  },
  [types.SET_FAVORITE_COURSES] (state, payload) {
    state.favoriteCourses = payload;
  },
  [types.SET_TOTAL_FAVORITE_COURSES] (state, payload) {
    state.totalFavoritesCourses = payload;
  },
  [types.SET_MATCH_HARD_SKILLS] (state, payload) {
    state.matchHardSkillsWithJobProfile = payload;
  },
  [types.SET_SOFT_SKILLS_REPORT] (state, payload) {
    state.softSkillReport = payload;
  },
  [types.SET_HARD_SKILLS] (state, payload) {
    state.hardSkills = payload;
  },
  [types.SET_SOFT_SKILLS] (state, payload) {
    state.softSkills = payload;
  },
  [types.SET_JOB_PROFILES] (state, payload) {
    state.jobProfiles = payload;
  },
  [types.SET_WORK_FIELDS] (state, payload) {
    state.workFields = payload;
  }
};
