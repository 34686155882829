import config from '@/config/config.js';
import ApiService from '@/services';
import i18n from '@/i18n';

export const KnowledgeService = {
  getHardSkills (payload) {
    return ApiService.query(
      `${config.DATA_PROXY_ENDPOINT}${config.MATCHING_ENDPOINT}validated_skill`,
      {
        validated_skill_contains: payload.search,
        limit: payload?.params?.limit ?? 10,
        offset: 0,
        language_id: i18n.locale
      }
    );
  },
  fetchSkills (payload) {
    return ApiService.query(
      `${config.CORE_ENDPOINT}skill`,
      {
        type_skill: payload.type_skill !== -1 ? payload.type_skill : null,
        match_enable: payload.match_enable,
        status: payload?.status !== -1 ? payload.status : null,
        name: payload.name || null,
        is_job_profile: payload.is_job_profile || null,
        limit: payload.limit || 10,
        offset: payload.offset || 0
      }
    );
  },
  fetchSkill (skillId) {
    return ApiService.query(`${config.CORE_ENDPOINT}skill/${skillId}`);
  },
  getSoftSkills (payload) {
    return ApiService.query(
      `${config.DATA_PROXY_ENDPOINT}${config.MATCHING_ENDPOINT}soft_skills`,
      {
        name: payload.search,
        limit: payload?.params?.limit ?? 10,
        offset: 0,
        language_id: i18n.locale
      }
    );
  },
  getLevelOfStudy () {
    return ApiService.get(`${config.CORE_ENDPOINT}levels-study`);
  },
  fetchWorkField (workFieldId) {
    return ApiService.query(`${config.CORE_ENDPOINT}workfield`,
      {
        id: workFieldId ?? null
      });
  },
  fetchWorkfields (payload) {
    return ApiService.query(
      `${config.CORE_ENDPOINT}workfield`,
      {
        name: payload.name ?? null,
        limit: payload.limit ?? -1,
        offset: payload.page ?? 0
      }
    );
  },
  getYearsExperience () {
    return ApiService.get(`${config.CORE_ENDPOINT}range-year-experience`);
  },
  getSkills (payload) {
    return ApiService.query(`${config.KNOWLEDGE_ENDPOINT}skills`, {
      offset: payload.offset,
      name__icontains: payload.search
    });
  },

  getWorkfieldsComputed (payload) {
    // return ApiService.query(`${config.DATA_ENDPOINT}related-workfields/?workfields=${payload.join(',')}&`);
    return ApiService.query(`${config.DATA_ENDPOINT}related-workfields`, {
      workfields: payload
    });
  },
  fetchJobProfiles (payload) {
    return ApiService.query(`${config.CORE_ENDPOINT}job-profile`, {
      name: payload.name ?? null,
      status: payload.status ?? null,
      work_field: payload.work_field ?? null,
      ordering: payload.ordering ?? null,
      limit: payload.limit ?? -1,
      offset: payload.page ?? 0
    });
  },
  fetchRankingJobProfiles (payload) {
    return ApiService.query(`${config.CORE_ENDPOINT}ranking-job-profiles`, {
      name: payload.name ?? null,
      limit: payload.limit ?? -1,
      offset: payload.page ?? 0
    });
  },
  updateSkill (skillId, payload) {
    return ApiService.put(`${config.CORE_ENDPOINT}skill/${skillId}`, payload);
  }
};
export default KnowledgeService;
