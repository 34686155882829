// index.js
import en from './en.json';
import es from './es.json';
import ca from './ca.json';
import pt from './pt.json';

export const defaultLocale = 'es';
export const languages = {
  en: en,
  es: es,
  ca: ca,
  pt: pt
};
